import dashboards from "redux/reducers/Dashboards.js";
import {
    FETCH_DASHBOARDS_FAILURE,
    FETCH_DASHBOARDS_REQUEST,
    FETCH_DASHBOARDS_SUCCESS,
    DELETE_DASHBOARDS_FAILURE,
    DELETE_DASHBOARDS_REQUEST,
    DELETE_DASHBOARDS_SUCCESS,
    CREATE_DASHBOARDS_FAILURE,
    CREATE_DASHBOARDS_REQUEST,
    CREATE_DASHBOARDS_SUCCESS,
    SHOW_DASHBOARDS_EDIT_FORM,
    SHOW_DASHBOARDS_NEW_FORM,
    UPDATE_DASHBOARDS_FAILURE,
    UPDATE_DASHBOARDS_REQUEST,
    UPDATE_DASHBOARDS_SUCCESS,
    SET_DASHBOARDS_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchDashboardsRequest = (dashboardtypes) => ({
    type: FETCH_DASHBOARDS_REQUEST,
    payload:dashboardtypes
  });
  
  export const fetchDashboardsSuccess = (dashboards) => ({
    type: FETCH_DASHBOARDS_SUCCESS,
    payload: dashboards,
  });
  
  export const fetchDashboardsFailure = (error) => ({
    type: FETCH_DASHBOARDS_FAILURE,
    payload: error,
  });

  export const setDashboardsValidationErrors = (validationErrors) => ({
    type: SET_DASHBOARDS_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleDashboardsEditForm = () => ({
    type: SHOW_DASHBOARDS_EDIT_FORM,
  });
  
  export const toggleDashboardsNewForm = () => ({
    type: SHOW_DASHBOARDS_NEW_FORM,
  });
  
  export const updateDashboardsRequest = (dashboards) => ({
    type: UPDATE_DASHBOARDS_REQUEST,
    payload: dashboards,
  });
  
  export const updateDashboardsSuccess = (dashboards) => ({
    type: UPDATE_DASHBOARDS_SUCCESS,
    payload: dashboards,
  });
  
  export const updateDashboardsFailure = (error) => ({
    type: UPDATE_DASHBOARDS_FAILURE,
    payload: error,
  });
  
  export const deleteDashboardsRequest = (id) => ({
    type: DELETE_DASHBOARDS_REQUEST,
    payload: id,
  });
  
  export const deleteDashboardsSuccess = (dashboards) => ({
    type: DELETE_DASHBOARDS_SUCCESS,
    payload: dashboards,
  });
  
  export const deleteDashboardsFailure = (error) => ({
    type: DELETE_DASHBOARDS_FAILURE,
    payload: error,
  });
  
  export const createDashboardsRequest = (dashboards) => ({
    type: CREATE_DASHBOARDS_REQUEST,
    payload: dashboards,
  });
  
  export const createDashboardsSuccess = (dashboards) => ({
    type: CREATE_DASHBOARDS_SUCCESS,
    payload: dashboards,
  });
  
  export const createDashboardsFailure = (error) => ({
    type: CREATE_DASHBOARDS_FAILURE,
    payload: error,
  });

  

