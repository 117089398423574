import {
    CREATE_BeginningBalance_FAILURE,
    CREATE_BeginningBalance_REQUEST,
    CREATE_BeginningBalance_SUCCESS,
    DELETE_BeginningBalance_FAILURE,
    DELETE_BeginningBalance_REQUEST,
    DELETE_BeginningBalance_SUCCESS,
    FETCH_BeginningBalance_FAILURE,
    FETCH_BeginningBalance_REQUEST,
    FETCH_BeginningBalance_SUCCESS,
    FETCH_StokeBalance_REQUEST,
    FETCH_StokeBalance_SUCCESS,
    SET_BeginningBalance_VALIDATION_ERRORS,
    SHOW_BeginningBalance_EDIT_FORM,
    SHOW_BeginningBalance_NEW_FORM,
    UPDATE_BeginningBalance_FAILURE,
    UPDATE_BeginningBalance_REQUEST,
    UPDATE_BeginningBalance_SUCCESS
  } from "../constants/actions.js";
  
  export const fetchBeginningBalanceRequest = (  
  ) => ({
    type: FETCH_BeginningBalance_REQUEST
  });
  
  export const fetcStokeBalanceRequest = (type) => ({
      type: FETCH_StokeBalance_REQUEST,
      payload:type,
    });
    export const fetchStokeBalanceSuccess = (beginningbalance) => ({
      type: FETCH_StokeBalance_SUCCESS,
      payload: beginningbalance,
    });
  export const fetchBeginningBalanceSuccess = (beginningbalance) => ({
    type: FETCH_BeginningBalance_SUCCESS,
    payload: beginningbalance,
  });
  
  export const fetchBeginningBalanceFailure = (error) => ({
    type: FETCH_BeginningBalance_FAILURE,
    payload: error,
  });

  export const setBeginningBalanceValidationErrors = (validationErrors) => ({
    type: SET_BeginningBalance_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleBeginningBalanceEditForm = () => ({
    type: SHOW_BeginningBalance_EDIT_FORM,
  });
  
  export const toggleBeginningBalanceNewForm = () => ({
    type: SHOW_BeginningBalance_NEW_FORM,
  });
  
  export const updateBeginningBalanceRequest = (beginningbalance) => ({
    type: UPDATE_BeginningBalance_REQUEST,
    payload: beginningbalance,
  });
  
  export const updateBeginningBalanceSuccess = (beginningbalance) => ({
    type: UPDATE_BeginningBalance_SUCCESS,
    payload: beginningbalance,
  });
  
  export const updateBeginningBalanceFailure = (error) => ({
    type: UPDATE_BeginningBalance_FAILURE,
    payload: error,
  });
  
  export const deleteBeginningBalanceRequest = (id) => ({
    type: DELETE_BeginningBalance_REQUEST,
    payload: id,
  });
  
  export const deleteBeginningBalanceSuccess = (beginningbalance) => ({
    type: DELETE_BeginningBalance_SUCCESS,
    payload: beginningbalance,
  });
  
  export const deleteBeginningBalanceFailure = (error) => ({
    type: DELETE_BeginningBalance_FAILURE,
    payload: error,
  });
  
  export const createBeginningBalanceRequest = (beginningbalance) => ({
    type: CREATE_BeginningBalance_REQUEST,
    payload: beginningbalance,
  });
  
  export const createBeginningBalanceSuccess = (beginningbalance) => ({
    type: CREATE_BeginningBalance_SUCCESS,
    payload: beginningbalance,
  });
  
  export const createBeginningBalanceFailure = (error) => ({
    type: CREATE_BeginningBalance_FAILURE,
    payload: error,
  });

  

