import {
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_REQUEST,
    FETCH_ITEMS_SUCCESS,
    DELETE_ITEMS_FAILURE,
    DELETE_ITEMS_REQUEST,
    DELETE_ITEMS_SUCCESS,
    CREATE_ITEMS_FAILURE,
    CREATE_ITEMS_REQUEST,
    CREATE_ITEMS_SUCCESS,
    SHOW_ITEMS_EDIT_FORM,
    SHOW_ITEMS_NEW_FORM,
    UPDATE_ITEMS_FAILURE,
    UPDATE_ITEMS_REQUEST,
    UPDATE_ITEMS_SUCCESS,
    SET_ITEMS_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchItemsRequest = (  
  ) => ({
    type: FETCH_ITEMS_REQUEST
  });
  
  export const fetchItemsSuccess = (items) => ({
    type: FETCH_ITEMS_SUCCESS,
    payload: items,
  });
  
  export const fetchItemsFailure = (error) => ({
    type: FETCH_ITEMS_FAILURE,
    payload: error,
  });

  export const setItemsValidationErrors = (validationErrors) => ({
    type: SET_ITEMS_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleItemsEditForm = () => ({
    type: SHOW_ITEMS_EDIT_FORM,
  });
  
  export const toggleItemsNewForm = () => ({
    type: SHOW_ITEMS_NEW_FORM,
  });
  
  export const updateItemsRequest = (items) => ({
    type: UPDATE_ITEMS_REQUEST,
    payload: items,
  });
  
  export const updateItemsSuccess = (items) => ({
    type: UPDATE_ITEMS_SUCCESS,
    payload: items,
  });
  
  export const updateItemsFailure = (error) => ({
    type: UPDATE_ITEMS_FAILURE,
    payload: error,
  });
  
  export const deleteItemsRequest = (id) => ({
    type: DELETE_ITEMS_REQUEST,
    payload: id,
  });
  
  export const deleteItemsSuccess = (items) => ({
    type: DELETE_ITEMS_SUCCESS,
    payload: items,
  });
  
  export const deleteItemsFailure = (error) => ({
    type: DELETE_ITEMS_FAILURE,
    payload: error,
  });
  
  export const createItemsRequest = (items) => ({
    type: CREATE_ITEMS_REQUEST,
    payload: items,
  });
  
  export const createItemsSuccess = (items) => ({
    type: CREATE_ITEMS_SUCCESS,
    payload: items,
  });
  
  export const createItemsFailure = (error) => ({
    type: CREATE_ITEMS_FAILURE,
    payload: error,
  });

  

