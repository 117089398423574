import { CREATE_Goodsreceivingvoucher_FAILURE, CREATE_Goodsreceivingvoucher_REQUEST, CREATE_Goodsreceivingvoucher_SUCCESS, DELETE_Goodsreceivingvoucher_FAILURE, SET_Goodsreceivingvoucher_VALIDATION_ERRORS } from "redux/constants/actions";

export const setGoodsreceivingvoucherValidationErrors = (validationErrors) => ({
    type: SET_Goodsreceivingvoucher_VALIDATION_ERRORS,
    payload: validationErrors,
  });
export const createGoodsreceivingvoucherRequest = (Voucher) => ({
    type: CREATE_Goodsreceivingvoucher_REQUEST,
    payload: Voucher,
  });
  
  export const createGoodsreceivingvoucherSuccess = (Voucher) => ({
    type: CREATE_Goodsreceivingvoucher_SUCCESS,
    payload: Voucher,
  });
  
  export const createGoodsreceivingvoucherFailure = (error) => ({
    type: CREATE_Goodsreceivingvoucher_FAILURE,
    payload: error,
  });

  export const deleteGoodsreceivingvoucherFailure = (error) => ({
    type: DELETE_Goodsreceivingvoucher_FAILURE,
    payload: error,
  });