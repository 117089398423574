import {
    FETCH_COSTBUILDUPS_FAILURE,
    FETCH_COSTBUILDUPS_REQUEST,
    FETCH_COSTBUILDUPS_SUCCESS,
    DELETE_COSTBUILDUPS_FAILURE,
    DELETE_COSTBUILDUPS_REQUEST,
    DELETE_COSTBUILDUPS_SUCCESS,
    CREATE_COSTBUILDUPS_FAILURE,
    CREATE_COSTBUILDUPS_REQUEST,
    CREATE_COSTBUILDUPS_SUCCESS,
    SHOW_COSTBUILDUPS_EDIT_FORM,
    SHOW_COSTBUILDUPS_NEW_FORM,
    UPDATE_COSTBUILDUPS_FAILURE,
    UPDATE_COSTBUILDUPS_REQUEST,
    UPDATE_COSTBUILDUPS_SUCCESS,
    SET_COSTBUILDUPS_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchCostbuildupsRequest = (  
  ) => ({
    type: FETCH_COSTBUILDUPS_REQUEST
  });
  
  export const fetchCostbuildupsSuccess = (costbuildups) => ({
    type: FETCH_COSTBUILDUPS_SUCCESS,
    payload: costbuildups,
  });
  
  export const fetchCostbuildupsFailure = (error) => ({
    type: FETCH_COSTBUILDUPS_FAILURE,
    payload: error,
  });

  export const setCostbuildupsValidationErrors = (validationErrors) => ({
    type: SET_COSTBUILDUPS_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleCostbuildupsEditForm = () => ({
    type: SHOW_COSTBUILDUPS_EDIT_FORM,
  });
  
  export const toggleCostbuildupsNewForm = () => ({
    type: SHOW_COSTBUILDUPS_NEW_FORM,
  });
  
  export const updateCostbuildupsRequest = (costbuildups) => ({
    type: UPDATE_COSTBUILDUPS_REQUEST,
    payload: costbuildups,
  });
  
  export const updateCostbuildupsSuccess = (costbuildups) => ({
    type: UPDATE_COSTBUILDUPS_SUCCESS,
    payload: costbuildups,
  });
  
  export const updateCostbuildupsFailure = (error) => ({
    type: UPDATE_COSTBUILDUPS_FAILURE,
    payload: error,
  });
  
  export const deleteCostbuildupsRequest = (id) => ({
    type: DELETE_COSTBUILDUPS_REQUEST,
    payload: id,
  });
  
  export const deleteCostbuildupsSuccess = (costbuildups) => ({
    type: DELETE_COSTBUILDUPS_SUCCESS,
    payload: costbuildups,
  });
  
  export const deleteCostbuildupsFailure = (error) => ({
    type: DELETE_COSTBUILDUPS_FAILURE,
    payload: error,
  });
  
  export const createCostbuildupsRequest = (costbuildups) => ({
    type: CREATE_COSTBUILDUPS_REQUEST,
    payload: costbuildups,
  });
  
  export const createCostbuildupsSuccess = (costbuildups) => ({
    type: CREATE_COSTBUILDUPS_SUCCESS,
    payload: costbuildups,
  });
  
  export const createCostbuildupsFailure = (error) => ({
    type: CREATE_COSTBUILDUPS_FAILURE,
    payload: error,
  });

  

