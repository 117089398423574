import {
  FETCH_LOOKUPTYPES_FAILURE,
  FETCH_LOOKUPTYPES_REQUEST,
  FETCH_LOOKUPTYPES_SUCCESS,
  DELETE_LOOKUPTYPES_FAILURE,
  DELETE_LOOKUPTYPES_REQUEST,
  DELETE_LOOKUPTYPES_SUCCESS,
  CREATE_LOOKUPTYPES_FAILURE,
  CREATE_LOOKUPTYPES_REQUEST,
  CREATE_LOOKUPTYPES_SUCCESS,
  SHOW_LOOKUPTYPES_EDIT_FORM,
  SHOW_LOOKUPTYPES_NEW_FORM,
  UPDATE_LOOKUPTYPES_FAILURE,
  UPDATE_LOOKUPTYPES_REQUEST,
  UPDATE_LOOKUPTYPES_SUCCESS,
  SET_LOOKUPTYPES_VALIDATION_ERRORS
} from "../constants/actions.js";
import {
  addLookuptypes,
  formtValidationErrors,
  removeLookuptypes,
  updateLookuptypes,
} from "utils/Lookuptypes";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditFormLookuptype: false,
  showNewFormLookuptype: false,
  deleting: false,
};

const lookuptypes = (state = initState, action) => {
  switch (action.type) {
    case FETCH_LOOKUPTYPES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOOKUPTYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_LOOKUPTYPES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_LOOKUPTYPES_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_LOOKUPTYPES_EDIT_FORM:
      return {
        ...state,
        showEditFormLookuptype: !state.showEditFormLookuptype,
      };
    case SHOW_LOOKUPTYPES_NEW_FORM:
      return {
        ...state,
        showNewFormLookuptype: !state.showNewFormLookuptype,
      };
    case UPDATE_LOOKUPTYPES_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_LOOKUPTYPES_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateLookuptypes(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditFormLookuptype: false,
      };
    case UPDATE_LOOKUPTYPES_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_LOOKUPTYPES_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_LOOKUPTYPES_SUCCESS:
      return {
        ...state,
        deleting: false,
        
        list: removeLookuptypes(state, action),
      };
    case DELETE_LOOKUPTYPES_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_LOOKUPTYPES_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_LOOKUPTYPES_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewFormLookuptype: !state.showNewFormLookuptype,
        list: addLookuptypes(state, action),
      };
    case CREATE_LOOKUPTYPES_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default lookuptypes;