import {
  FETCH_EMPLOYEES_FAILURE,
  FETCH_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_SUCCESS,
  FETCH_EMPLOYEES_ATTENDANCE_FAILURE,
  FETCH_EMPLOYEES_ATTENDANCE_REQUEST,
  FETCH_EMPLOYEES_ATTENDANCE_SUCCESS,
  DELETE_EMPLOYEES_FAILURE,
  DELETE_EMPLOYEES_REQUEST,
  DELETE_EMPLOYEES_SUCCESS,
  CREATE_EMPLOYEES_FAILURE,
  CREATE_EMPLOYEES_REQUEST,
  CREATE_EMPLOYEES_SUCCESS,
  SHOW_EMPLOYEES_EDIT_FORM,
  SHOW_EMPLOYEES_NEW_FORM,
  UPDATE_EMPLOYEES_FAILURE,
  UPDATE_EMPLOYEES_REQUEST,
  UPDATE_EMPLOYEES_SUCCESS,
  SET_EMPLOYEES_VALIDATION_ERRORS,
  FETCH_EMP_SUCCESS,
  FETCH_EMP_FAILURE,
  FETCH_EMP_REQUEST,
  CREATE_EMPATT_SUCCESS,
  CREATE_EMPATT_FAILURE,
  CREATE_EMPATT_REQUEST
} from "../constants/actions.js";
import {
  addEmployees,
  formtValidationErrors,
  removeEmployees,
  updateEmployees,
} from "utils/Employees";
const initState = {
  loading: false,
  list: [],
  empattendancelist: [],
  userattendancelist:[],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
  customLoading:"Loading"
};

const employees = (state = initState, action) => {
  switch (action.type) {
    case FETCH_EMPLOYEES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_EMPLOYEES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_EMPLOYEES_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_EMPLOYEES_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_EMPLOYEES_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_EMPLOYEES_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateEmployees(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_EMPLOYEES_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_EMPLOYEES_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeEmployees(state, action),
      };
    case DELETE_EMPLOYEES_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_EMPLOYEES_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addEmployees(state, action),
      };
    case CREATE_EMPLOYEES_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

      case FETCH_EMPLOYEES_ATTENDANCE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_EMPLOYEES_ATTENDANCE_SUCCESS:
        return {
          ...state,
          loading: false,
          empattendancelist: action.payload,
        };
      case FETCH_EMPLOYEES_ATTENDANCE_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };

        case FETCH_EMP_REQUEST:
          return {
            ...state,
            loading: true,
            customLoading:"Loading"
          };
        case FETCH_EMP_SUCCESS:
          return {
            ...state,
            loading: false,
            customLoading:"Done",
            userattendancelist: action.payload,
          };
        case FETCH_EMP_FAILURE:
          return {
            ...state,
            loading: false,
            errorMessage: action.payload,
            customLoading:"Done",
          };


          case CREATE_EMPATT_REQUEST:
            return {
              ...state,
              creating: true,
            };
          case CREATE_EMPATT_SUCCESS:
            return {
              ...state,
              creating: false,
              };
          case CREATE_EMPATT_FAILURE:
            return {
              ...state,
              creating: false,
              errorMessage: action.payload,
            };
    default:
      return state;
  }
};

export default employees;