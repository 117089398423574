import fetch from "auth/FetchInterceptor";

const MachinesService = {};

MachinesService.index = function () {
  return fetch({
    url:
     "v1/Machine?PageNumber=1&PageSize=200" ,
    method: "get",
  });
};



MachinesService.history = function (id) {
  return fetch({
    url:
    `v1/ServiceRequest/${id}`,
    method: "get",
  });
};

MachinesService.create = function (data) {
  return fetch({
    url: "v1/Machine",
    method: "post",
    data,
  });
};


MachinesService.update = function (id, data) {
  return fetch({
    url: `v1/Machine/${id}`,
    method: "put",
    data,
  });
};

MachinesService.delete = function (id) {
  return fetch({
    url: `v1/Machine/${id}`,
    method: "delete",
  });
};

export default MachinesService;