import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import logger from 'redux-logger'
import { persistStore } from 'redux-persist'

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, logger];

function configureStore(preloadedState) {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducers, preloadedState, composeEnhancers(
    applyMiddleware(...middlewares)
  ));
  sagaMiddleware.run(rootSaga);
  if (module.hot) {
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export const store = configureStore();

export const persistor = persistStore(store);

// export default { store, persistor };

