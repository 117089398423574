import fetch from "auth/FetchInterceptor";

const LookuptypesService = {};

LookuptypesService.index = function () {
  return fetch({
    url:
     "v1/Lookup/GetLookuptype" ,
    method: "get",
  });
};


LookuptypesService.create = function (data) {
  return fetch({
    url: "v1/Lookup/PostLookuptype",
    method: "post",
    data,
  });
};


LookuptypesService.update = function (id, data) {
  return fetch({
    url: `v1/Lookup/PutLookuptype${id}`,
    method: "put",
    data,
  });
};

LookuptypesService.delete = function (id) {
  return fetch({
    url: `v1/Lookup/DeleteLookuptype?id=${id}`,
    method: "delete",
  });
};

export default LookuptypesService;