import {
  FETCH_DASHBOARDS_FAILURE,
  FETCH_DASHBOARDS_REQUEST,
  FETCH_DASHBOARDS_SUCCESS,
  DELETE_DASHBOARDS_FAILURE,
  DELETE_DASHBOARDS_REQUEST,
  DELETE_DASHBOARDS_SUCCESS,
  CREATE_DASHBOARDS_FAILURE,
  CREATE_DASHBOARDS_REQUEST,
  CREATE_DASHBOARDS_SUCCESS,
  SHOW_DASHBOARDS_EDIT_FORM,
  SHOW_DASHBOARDS_NEW_FORM,
  UPDATE_DASHBOARDS_FAILURE,
  UPDATE_DASHBOARDS_REQUEST,
  UPDATE_DASHBOARDS_SUCCESS,
  SET_DASHBOARDS_VALIDATION_ERRORS
} from "../constants/actions.js";

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const dashboards = (state = initState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARDS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_DASHBOARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_DASHBOARDS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

 
    case SHOW_DASHBOARDS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_DASHBOARDS_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_DASHBOARDS_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        updating: false,
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_DASHBOARDS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_DASHBOARDS_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        deleting: false,
        
      };
    case DELETE_DASHBOARDS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_DASHBOARDS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_DASHBOARDS_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
      };
    case CREATE_DASHBOARDS_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default dashboards;