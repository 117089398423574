import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { CREATE_BeginningBalance_REQUEST, DELETE_BeginningBalance_REQUEST, FETCH_BeginningBalance_REQUEST, FETCH_StokeBalance_REQUEST, UPDATE_BeginningBalance_REQUEST } from "../constants/actions.js";

import { fetchBeginningBalanceFailure, fetchBeginningBalanceSuccess,createBeginningBalanceFailure,
createBeginningBalanceSuccess,deleteBeginningBalanceFailure,deleteBeginningBalanceSuccess,setBeginningBalanceValidationErrors,updateBeginningBalanceRequest
,updateBeginningBalanceFailure,updateBeginningBalanceSuccess, fetchBeginningBalanceRequest, fetchStokeBalanceSuccess } from "redux/actions/BeginningBalance";
import BeginningBalanceService from "services/BeginningBalanceService";

export function* onFetchBeginningBalance() {
  yield takeLatest(FETCH_BeginningBalance_REQUEST, function* () {
    try {
      const result = yield call(BeginningBalanceService.index);
      if (result.succeeded) {
        yield put(fetchBeginningBalanceSuccess(result.data));
      } else {
        yield put(fetchBeginningBalanceFailure(result.message));
      }
    } catch (error) {
      yield put(fetchBeginningBalanceFailure(error.message));
    }
  });
}
export function* onFetchStokeBalance() {
  yield takeLatest(FETCH_StokeBalance_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(BeginningBalanceService.BinCard,payload);
      if (result.succeeded) {
        yield put(fetchStokeBalanceSuccess(result.data));
      } else {
        yield put(fetchBeginningBalanceFailure(result.message));
      }
    } catch (error) {
      yield put(fetchBeginningBalanceFailure(error.message));
    }
  });
}

export function* onUpdateBeginningBalance() {
  yield takeLatest(UPDATE_BeginningBalance_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        BeginningBalanceService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateBeginningBalanceSuccess(result.data));
        yield put(fetchBeginningBalanceRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setBeginningBalanceValidationErrors(result.data.errors));
        yield put(updateBeginningBalanceFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateBeginningBalanceFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateBeginningBalanceFailure(error.message));
    }
  });
}

export function* onDeleteBeginningBalance() {
  yield takeLatest(DELETE_BeginningBalance_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(BeginningBalanceService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteBeginningBalanceSuccess(result.data));
        yield put(fetchBeginningBalanceRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteBeginningBalanceFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteBeginningBalanceFailure(error.message));
    }
  });
}

export function* onCreateBeginningBalance() {
  yield takeLatest(CREATE_BeginningBalance_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(BeginningBalanceService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createBeginningBalanceSuccess(result.data));
        yield put(fetchBeginningBalanceRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setBeginningBalanceValidationErrors(result.data.errors));
        yield put(createBeginningBalanceFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createBeginningBalanceFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteBeginningBalanceFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchBeginningBalance),
    fork(onUpdateBeginningBalance),
    fork(onDeleteBeginningBalance),
    fork(onCreateBeginningBalance),
    fork(onFetchStokeBalance),
    
  ]);
}