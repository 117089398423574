import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_DASHBOARDS_REQUEST
} from "../constants/actions.js";

import { fetchDashboardsFailure, fetchDashboardsSuccess} from "redux/actions/Dashboard";
import DashboardsService from "services/Dashboards";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchDashboards() {
  yield takeLatest(FETCH_DASHBOARDS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(DashboardsService.serviceRequestchart,payload);
      if (result.succeeded) {
        yield put(fetchDashboardsSuccess(result.data));
      } else {
        yield put(fetchDashboardsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchDashboardsFailure(error.message));
    }
  });
}


export default function* rootSaga() {
  yield all([
    fork(onFetchDashboards)
  ]);
}