import fetch from "auth/FetchInterceptor";

const OrdersService = {};

OrdersService.index = function (payload) {
  var filter="";
  
  if(payload.PageNumber!=null  && payload.PageSize!=null)
  {
    if(filter!="")
    {
      filter=filter+"&"
    }
    filter=filter+`PageNumber=${payload.PageNumber}&PageSize=${payload.PageSize}`;
  }
  if(payload.startDate!=null  && payload.endDate!=null)
  {
    if(filter!="")
    {
      filter=filter+"&"
    }
    filter=filter+`startDate=${payload.startDate}&endDate=${payload.endDate}`;
  }
  if(payload.caseCategory!=null )
  {
    if(filter!="")
    {
      filter=filter+"&"
    }
    filter=filter+`caseCategory=${payload.caseCategory}`;
  }
  if(payload.CompanyId!=null )
  {
    if(filter!="")
    {
      filter=filter+"&"
    }
    filter=filter+`CompanyId=${payload.CompanyId}`;
  }
  if(payload.EmployeeIdentityId!=null )
  {
    if(filter!="")
    {
      filter=filter+"&"
    }
    filter=filter+`EmployeeIdentityId=${payload.EmployeeIdentityId}`;
  }
  return fetch({
    url:
     `v1/ServiceRequest/GetRequest?${filter}`,
    method: "get",
  });
};


OrdersService.create = function (data) {
  return fetch({
    url: "v1/ServiceRequest",
    method: "post",
    data,
  });
};


OrdersService.update = function (id, data) {
  return fetch({
    url: `v1/ServiceRequest/${id}`,
    method: "put",
    data,
  });
};

OrdersService.updateTask = function (id, data) {
  return fetch({
    url: `v1/AssignedTask/${id}`,
    method: "put",
    data,
  });
};

OrdersService.delete = function (id) {
  return fetch({
    url: `v1/ServiceRequest/${id}`,
    method: "delete",
  });
};

OrdersService.assign = function (data) {
  return fetch({
    url: "v1/AssignedTask",
    method: "post",
    data,
  });
};

export default OrdersService;