import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_MACHINES_REQUEST,
  DELETE_MACHINES_REQUEST,
  CREATE_MACHINES_REQUEST,
  UPDATE_MACHINES_REQUEST,
  FETCH_MACHINESHISTORY_REQUEST,
} from "../constants/actions.js";

import { fetchMachinesFailure, fetchMachinesSuccess,createMachinesFailure,
createMachinesSuccess,deleteMachinesFailure,deleteMachinesSuccess,setMachinesValidationErrors,updateMachinesRequest
,updateMachinesFailure,updateMachinesSuccess, fetchMachinesRequest, fetchMachinesHistorySuccess ,fetchMachinesHistoryFailure} from "redux/actions/Machines";
import MachinesService from "services/MachinesService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchMachines() {
  yield takeLatest(FETCH_MACHINES_REQUEST, function* () {
    try {
      const result = yield call(MachinesService.index);
      if (result.succeeded) {
        yield put(fetchMachinesSuccess(result.data));
      } else {
        yield put(fetchMachinesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchMachinesFailure(error.message));
    }
  });
}


export function* onFetchMachinesHistory() {
  yield takeLatest(FETCH_MACHINESHISTORY_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MachinesService.history,payload);
      if (result.succeeded) {
        yield put(fetchMachinesHistorySuccess(result.data));
      } else {
        yield put(fetchMachinesHistoryFailure(result.message));
      }
    } catch (error) {
      yield put(fetchMachinesHistoryFailure(error.message));
    }
  });
}




export function* onUpdateMachines() {
  yield takeLatest(UPDATE_MACHINES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        MachinesService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateMachinesSuccess(result.data));
        yield put(fetchMachinesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setMachinesValidationErrors(result.data.errors));
        yield put(updateMachinesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateMachinesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateMachinesFailure(error.message));
    }
  });
}

export function* onDeleteMachines() {
  yield takeLatest(DELETE_MACHINES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MachinesService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteMachinesSuccess(result.data));
        yield put(fetchMachinesRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteMachinesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteMachinesFailure(error.message));
    }
  });
}

export function* onCreateMachines() {
  yield takeLatest(CREATE_MACHINES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MachinesService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createMachinesSuccess(result.data));
        yield put(fetchMachinesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setMachinesValidationErrors(result.data.errors));
        yield put(createMachinesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createMachinesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteMachinesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchMachines),
    fork(onUpdateMachines),
    fork(onDeleteMachines),
    fork(onCreateMachines),
    fork(onFetchMachinesHistory)
  ]);
}