import {
  FETCH_GAUGES_FAILURE,
  FETCH_GAUGES_REQUEST,
  FETCH_GAUGES_SUCCESS,
  DELETE_GAUGES_FAILURE,
  DELETE_GAUGES_REQUEST,
  DELETE_GAUGES_SUCCESS,
  CREATE_GAUGES_FAILURE,
  CREATE_GAUGES_REQUEST,
  CREATE_GAUGES_SUCCESS,
  SHOW_GAUGES_EDIT_FORM,
  SHOW_GAUGES_NEW_FORM,
  UPDATE_GAUGES_FAILURE,
  UPDATE_GAUGES_REQUEST,
  UPDATE_GAUGES_SUCCESS,
  SET_GAUGES_VALIDATION_ERRORS,
  GET_GAGE_REQUEST,
  GET_GAGE_SUCCESS,
  GET_GAGE_FAILURE,
  Upload_GAUGES_REQUEST,
  Upload_GAUGES_SUCCESS,
  Upload_GAUGES_FAILURE
} from "../constants/actions.js";
import {
  addGauges,
  formtValidationErrors,
  removeGauges,
  updateGauges,
} from "utils/Gauges";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
  gage:{},
  status:""
};

const gauges = (state = initState, action) => {
  switch (action.type) {
    case FETCH_GAUGES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_GAUGES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_GAUGES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_GAUGES_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_GAUGES_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_GAUGES_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_GAUGES_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_GAUGES_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateGauges(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_GAUGES_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_GAUGES_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_GAUGES_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeGauges(state, action),
      };
    case DELETE_GAUGES_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_GAUGES_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_GAUGES_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addGauges(state, action),
      };
    case CREATE_GAUGES_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
      case Upload_GAUGES_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case Upload_GAUGES_SUCCESS:
        return {
          ...state,
          creating: false,
          //showNewForm: !state.showNewForm,
          list: addGauges(state, action),
        };
      case Upload_GAUGES_FAILURE:
        return {
          ...state,
          creating: false,
          errorMessage: action.payload,
        };
      case GET_GAGE_REQUEST:
        return {
          ...state,
          loading: true,
   
        };
      case GET_GAGE_SUCCESS:
        return {
          ...state,
          loading: action.loading,
          gage: action.payload,
          status:"success"
        };
      case GET_GAGE_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };

    default:
      return state;
  }
};

export default gauges;