import _ from "lodash";

export const addMachinesLocation = (state, action) => {
  let list = state.list;
  const savedMachinesLocation = action.payload;

  return [savedMachinesLocation, ...list];
};

export const updateMachinesLocation = (state, action) => {
  let list = state.list;
  const updatedMachinesLocation = action.payload;

  const index = _.findIndex(list, ["id", updatedMachinesLocation.id]);
  list[index] = updatedMachinesLocation;

  return [...list];
};

export const removeMachinesLocation = (state, action) => {
  let list = state.list;
  const deletedMachinesLocation = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedMachinesLocation.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};