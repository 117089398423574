import fetch from "auth/FetchInterceptor";

const DashboardsService = {};

DashboardsService.serviceRequestchart = function (range) {
  let data="";
  if(range.startDate!=null || range.endDate!=null){
    data= `?startDate=${range.startDate}&endDate=${range.endDate}` 
  }
  return fetch({
    url:
    `v1/Dashboard/GetDashboardServiceRequest${data}` ,
    method: "get",
  });
};



export default DashboardsService;