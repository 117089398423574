import fetch from "auth/FetchInterceptor";

const EmployeesService = {};

EmployeesService.index = function () {
  return fetch({
    url:
     "v1/Employee?PageNumber=1&PageSize=200" ,
    method: "get",
  });
};
EmployeesService.getattendance = function (range) {
  let MonthYear="";

  if(range.Year!=null && range.Month!==null){
    MonthYear= `?Year=${range.Year}&Month=${range.Month}` 
  }
  
  
  return fetch({
    url:
     `v1/Attendance${MonthYear}` ,
    method: "get",
  });
};


EmployeesService.index = function () {
  return fetch({
    url:
     "v1/Employee?PageNumber=1&PageSize=200" ,
    method: "get",
  });
};
EmployeesService.Empattendance = function (userId) {
 
 return fetch({
    url:
     `v1/Attendance/GetUSerAttendance?Userid=${userId.userid}` ,
    method: "get",
  });
};

EmployeesService.create = function (data) {
  return fetch({
    url: "v1/Employee",
    method: "post",
    data,
  });
};

EmployeesService.attendance = function (data) {
  return fetch({
    url: "v1/Attendance",
    method: "post",
    data,
  });
};

EmployeesService.update = function (id, data) {
  return fetch({
    url: `v1/Employee/${id}`,
    method: "put",
    data,
  });
};

EmployeesService.delete = function (id) {
  return fetch({
    url: `v1/Employee/${id}`,
    method: "delete",
  });
};

export default EmployeesService;