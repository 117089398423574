import _ from "lodash";

export const addLookups = (state, action) => {
  let list = state.list;
  const savedLookups = action.payload;

  return [savedLookups, ...list];
};

export const updateLookups = (state, action) => {
  let list = state.list;
  const updatedLookups = action.payload;

  const index = _.findIndex(list, ["id", updatedLookups.id]);
  list[index] = updatedLookups;

  return [...list];
};

export const removeLookups = (state, action) => {
  let list = state.list;
  const deletedLookups = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedLookups.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};