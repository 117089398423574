import {
  FETCH_MACHINES_FAILURE,
  FETCH_MACHINES_REQUEST,
  FETCH_MACHINES_SUCCESS,
  DELETE_MACHINES_FAILURE,
  DELETE_MACHINES_REQUEST,
  DELETE_MACHINES_SUCCESS,
  CREATE_MACHINES_FAILURE,
  CREATE_MACHINES_REQUEST,
  CREATE_MACHINES_SUCCESS,
  SHOW_MACHINES_EDIT_FORM,
  SHOW_MACHINES_NEW_FORM,
  UPDATE_MACHINES_FAILURE,
  UPDATE_MACHINES_REQUEST,
  UPDATE_MACHINES_SUCCESS,
  SET_MACHINES_VALIDATION_ERRORS,
  FETCH_MACHINESHISTORY_REQUEST,
  FETCH_MACHINESHISTORY_SUCCESS ,
  FETCH_MACHINESHISTORY_FAILURE,
  RESET_INITIAL_STATE
} from "../constants/actions.js";
import {
  addMachines,
  formtValidationErrors,
  removeMachines,
  updateMachines,
} from "utils/Machines";
const initState = {
  loading: false,
  list: [],
  machinehistorylist: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
  machineshistoryloading:false
};

const machines = (state = initState, action) => {
  switch (action.type) {
    case FETCH_MACHINES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MACHINES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_MACHINES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_MACHINES_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_MACHINES_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
        
      };
    case SHOW_MACHINES_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
       
      };
    case UPDATE_MACHINES_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_MACHINES_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateMachines(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_MACHINES_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_MACHINES_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_MACHINES_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeMachines(state, action),
      };
    case DELETE_MACHINES_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_MACHINES_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_MACHINES_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addMachines(state, action),
      };
    case CREATE_MACHINES_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };



      case FETCH_MACHINESHISTORY_REQUEST:
        return {
          ...state,
          machineshistoryloading: true,
        };
      case FETCH_MACHINESHISTORY_SUCCESS:
        return {
          ...state,
          machineshistoryloading: false,
          machinehistorylist: action.payload,
        };
      case FETCH_MACHINES_FAILURE:
        return {
          ...state,
          machineshistoryloading: false,
          errorMessage: action.payload,
        };


        case RESET_INITIAL_STATE:
          return {
            ...initState,
          }; 

    default:
      return state;
  }
};

export default machines;