import {
    FETCH_ADDRESSES_FAILURE,
    FETCH_ADDRESSES_REQUEST,
    FETCH_ADDRESSES_SUCCESS,
    DELETE_ADDRESSES_FAILURE,
    DELETE_ADDRESSES_REQUEST,
    DELETE_ADDRESSES_SUCCESS,
    CREATE_ADDRESSES_FAILURE,
    CREATE_ADDRESSES_REQUEST,
    CREATE_ADDRESSES_SUCCESS,
    SHOW_ADDRESSES_EDIT_FORM,
    SHOW_ADDRESSES_NEW_FORM,
    UPDATE_ADDRESSES_FAILURE,
    UPDATE_ADDRESSES_REQUEST,
    UPDATE_ADDRESSES_SUCCESS,
    SET_ADDRESSES_VALIDATION_ERRORS,
    FETCH_ADDRESSESLIST_REQUEST,
    FETCH_ADDRESSESLIST_SUCCESS,
    FETCH_ADDRESSESLIST_FAILURE
  } from "../constants/actions.js";
  
  export const fetchAddressesRequest = (  
  ) => ({
    type: FETCH_ADDRESSES_REQUEST
  });
  
  export const fetchAddressesSuccess = (addresses) => ({
    type: FETCH_ADDRESSES_SUCCESS,
    payload: addresses,
  });
  
  export const fetchAddressesFailure = (error) => ({
    type: FETCH_ADDRESSESLIST_FAILURE,
    payload: error,
  });
  export const fetchAddressesListRequest = (  
    ) => ({
      type: FETCH_ADDRESSESLIST_REQUEST
    });
    
    export const fetchAddressesListSuccess = (addresseslist) => ({
      type: FETCH_ADDRESSESLIST_SUCCESS,
      payload: addresseslist,
    });
    
    export const fetchAddressesListFailure = (error) => ({
      type: FETCH_ADDRESSESLIST_FAILURE,
      payload: error,
    });
  export const setAddressesValidationErrors = (validationErrors) => ({
    type: SET_ADDRESSES_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleAddressesEditForm = () => ({
    type: SHOW_ADDRESSES_EDIT_FORM,
  });
  
  export const toggleAddressesNewForm = () => ({
    type: SHOW_ADDRESSES_NEW_FORM,
  });
  
  export const updateAddressesRequest = (addresses) => ({
    type: UPDATE_ADDRESSES_REQUEST,
    payload: addresses,
  });
  
  export const updateAddressesSuccess = (addresses) => ({
    type: UPDATE_ADDRESSES_SUCCESS,
    payload: addresses,
  });
  
  export const updateAddressesFailure = (error) => ({
    type: UPDATE_ADDRESSES_FAILURE,
    payload: error,
  });
  
  export const deleteAddressesRequest = (id) => ({
    type: DELETE_ADDRESSES_REQUEST,
    payload: id,
  });
  
  export const deleteAddressesSuccess = (addresses) => ({
    type: DELETE_ADDRESSES_SUCCESS,
    payload: addresses,
  });
  
  export const deleteAddressesFailure = (error) => ({
    type: DELETE_ADDRESSES_FAILURE,
    payload: error,
  });
  
  export const createAddressesRequest = (addresses) => ({
    type: CREATE_ADDRESSES_REQUEST,
    payload: addresses,
  });
  
  export const createAddressesSuccess = (addresses) => ({
    type: CREATE_ADDRESSES_SUCCESS,
    payload: addresses,
  });
  
  export const createAddressesFailure = (error) => ({
    type: CREATE_ADDRESSES_FAILURE,
    payload: error,
  });

  

