import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

import { DoesTDNEpUTHQoQUJMHLrErGJyHg89uy71MyuHent, DoesThisUserHasARoleToThisComponent } from "utils/PermissioncheCheking";
import { CURRENT_USER } from "redux/constants/Auth";
import {ADMIN,CUSTOMER,Default_NO_ROLE,INVENTORY,STOREkEEPER,TECHNICHIAN} from "../../configs/UserRoles";
import UnAuthorizedPage from "views/unauthorised";
import ErrorTwo from "views/auth-views/errors/error-page-2";
import PageNotFoundPage from "./pagenotfound";
import GuardedRoute from "./RouteGuard";
const AppViews = () => {
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.roles;

  return (
    <div>
    <Suspense fallback={<Loading cover="content"/>}>{
      // role == ADMIN && role == TECHNICHIAN ?
      // (
      <Switch>
  {
          (
            DoesThisUserHasARoleToThisComponent(ADMIN) 
           ) ?
    <GuardedRoute   path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home/index`))} role={[ADMIN]}/>
          :null}

{
          (
            DoesThisUserHasARoleToThisComponent(TECHNICHIAN) ||
            DoesThisUserHasARoleToThisComponent(Default_NO_ROLE) || DoesThisUserHasARoleToThisComponent(CUSTOMER) ||
            DoesThisUserHasARoleToThisComponent(CUSTOMER) 
           ) ? <GuardedRoute   path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home/home2`))} role={[CUSTOMER,STOREkEEPER,Default_NO_ROLE,TECHNICHIAN]}/>
       :null}
       

     



      {<GuardedRoute path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users/list`))} role={[ADMIN]} />}
     
     {/* {  
      <GuardedRoute    path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users/list`))} />
     } */}
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/servicerequest`} component={lazy(() => import(`./servicerequests/list`))} role={[ADMIN,CUSTOMER,TECHNICHIAN]}  />
    }
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/lookups`} component={lazy(() => import(`./lookups/list`))}  role={[ADMIN]} />
      }
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/company`} component={lazy(() => import(`./company/list`))} role={[ADMIN]}  />
      }
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/items`} component={lazy(() => import(`./items/list`))}  role={[ADMIN]} />
      }
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/machines`} component={lazy(() => import(`./machines/list`))}  role={[ADMIN]} />
      }
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/attendance`} component={lazy(() => import(`./attendance/list`))} role={[ADMIN]}  />
      }
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/costbuildups`} component={lazy(() => import(`./costbuildups/list`))} role={[ADMIN]}  />
      } {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/employeelocation`} component={lazy(() => import(`./employeelocation`))}  role={[ADMIN]} />
      }
       {<GuardedRoute path={`${APP_PREFIX_PATH}/goodsreceivingvoucher`} component={lazy(() => import(`./goodsreceivingvoucher/list`))} role={[ADMIN,STOREkEEPER]}/>}
      {<GuardedRoute path={`${APP_PREFIX_PATH}/beginningbalance`} component={lazy(() => import(`./beginningbalance/list`))} role={[ADMIN,STOREkEEPER]}/>}
      {<GuardedRoute path={`${APP_PREFIX_PATH}/stokebalance`} component={lazy(() => import(`./stokebalance/list`))} role={[ADMIN,STOREkEEPER]}/>}
     {<GuardedRoute path={`${APP_PREFIX_PATH}/viewdocument`} component={lazy(() => import(`./viewdocument/list`))} role={[ADMIN,STOREkEEPER]}/>}    
    {/* <GuardedRoute    path={`${APP_PREFIX_PATH}/employees`} component={lazy(() => import(`./employees/list`))} /> */}
      
      {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/gauges`} component={lazy(() => import(`./gauges/list`))}  role={[ADMIN]} />
      }
         {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/feedbacks`} component={lazy(() => import(`./feedbacks/list`))}  role={[ADMIN]} />
      }
        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} /> */}
        { <GuardedRoute path={`${APP_PREFIX_PATH}/storeissuevoucher`} component={lazy(() => import(`./storeissuevoucher/list`))} role={[ADMIN,STOREkEEPER]} />}
       
        {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/clockin`} component={lazy(() => import(`./attendance/attend`))} role={[ADMIN,CUSTOMER,TECHNICHIAN]}  />
    }
       {
      <GuardedRoute    path={`${APP_PREFIX_PATH}/address`} component={lazy(() => import(`./address/list`))} role={[ADMIN]}  />
    }

{<GuardedRoute path={`${APP_PREFIX_PATH}/change-password`} component={lazy(() => import(`../auth-views/authentication/change-password/index`))}  role={[ADMIN,CUSTOMER,TECHNICHIAN,STOREkEEPER,Default_NO_ROLE]} />}

        <Route path="*">
      <PageNotFoundPage />
    </Route>
  </Switch>
// ) : (
//   <UnAuthorizedPage />
// )
}

      </Suspense>
    </div>
  );
};

export default React.memo(AppViews);