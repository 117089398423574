import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Employees  from './Employees';
import Companies from './Companies';
import Items from './Items';
import Machines from './Machines';
import Users from './Users';
import Orders from './Orders';
import Costbuildups from './Costbuildups';
import Gauges from './Gauges';
import Lookups from './Lookups';
import Lookuptypes from './Lookuptypes';
import Machineslocation from './MachinesLocation';
import Voucher from './Voucher';
import Goodsreceivingvoucher from './Goodsreceivingvoucher';
import BeginningBalance from './BeginningBalance';
import Addresses from './Addresses';
import Feedbacks from './Feedbacks';
import Dashboards from './Dashboards';

export default function* rootSaga(getState) {
  yield all([
    Auth(),
    Employees(),
    Companies(),
    Machines(),
    Items(),
    Users(),
    Orders(),
    Costbuildups(),
    Gauges(),
    Lookups(),
    Lookuptypes(),
    Machineslocation(),
    Voucher(),
    Goodsreceivingvoucher(),
    BeginningBalance(),
    Addresses(),
    Feedbacks(),
    Dashboards()
  ]);
}
