import _ from "lodash";
export const addGoodsreceivingvoucher = (state, action) => {
  let list = state.list;
  const savedOrders = action.payload;
  return [savedOrders, ...list];
};
export const removeGoodsreceivingvoucher = (state, action) => {
  let list = state.list;
  const deletedVoucher = action.payload;
  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedVoucher.id;
  });
  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];
  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });
  return errors;
};