import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_USERS_REQUEST,
  DELETE_USERS_REQUEST,
  CREATE_USERS_REQUEST,
  UPDATE_USERS_REQUEST,
  CREATE_USERROLE_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  SEND_RESET_EMAIL_REQUEST,
  RESET_PASSWORD_REQUEST,
} from "../constants/actions.js";
import { useHistory } from "react-router-dom";
import { fetchUsersFailure, fetchUsersSuccess,createUsersFailure,
createUsersSuccess,deleteUsersFailure,deleteUsersSuccess,setUsersValidationErrors,updateUsersRequest
,updateUsersFailure,updateUsersSuccess, fetchUsersRequest, createUserRoleFailure, createUserRoleSuccess, ChangePasswordSuccess, ChangePasswordFailure, SendresetemailSuccess, SendresetemailFailure, ResetPasswordRequest, ResetPasswordFailure, ResetPasswordSuccess } from "redux/actions/Users";
import UsersService from "services/UsersService";
import Loading from "components/shared-components/Loading/index.js";
import { signOut } from "../actions/Auth";
import { AUTH_TOKEN, CURRENT_USER } from "redux/constants/Auth.js";


export function* onFetchUsers() {
  yield takeLatest(FETCH_USERS_REQUEST, function* () {
    try {
      const result = yield call(UsersService.index);
      if (result.succeeded) {
        yield put(fetchUsersSuccess(result.data));
      } else {
        yield put(fetchUsersFailure(result.message));
      }
    } catch (error) {
      yield put(fetchUsersFailure(error.message));
    }
  });
}



export function* onUpdateUsers() {
  yield takeLatest(UPDATE_USERS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        UsersService.updateuser,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateUsersSuccess(result.data));
        yield put(fetchUsersRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setUsersValidationErrors(result.data.errors));
        yield put(updateUsersFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateUsersFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateUsersFailure(error.message));
    }
  });
}

export function* onDeleteUsers() {
  yield takeLatest(DELETE_USERS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(UsersService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        
        yield put(deleteUsersSuccess(result.data));
        yield put(fetchUsersRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteUsersFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteUsersFailure(error.message));
    }
  });
}

export function* onCreateUsers() {
  yield takeLatest(CREATE_USERS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(UsersService.create, payload);
      if (result.succeeded) {
        message.success("User Registered Successfully");
        
        yield put(createUsersSuccess(result.data));
        yield put(fetchUsersRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setUsersValidationErrors(result.data.errors));
        yield put(createUsersFailure(result.message));
      } else {
        message.error(result.message);
        yield put(createUsersFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(createUsersFailure(error.message));
    }
  });
}

export function* onCreateUserRole() {
  yield takeLatest(CREATE_USERROLE_REQUEST, function* ({payload }) {
    try {
      const result = yield call(UsersService.assignRole,payload);
      if (result.succeeded) {
        message.success(result.message);
        yield put(createUserRoleSuccess(result.data));
        yield put(fetchUsersRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setUsersValidationErrors(result.data.errors));
        yield put(createUserRoleFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createUserRoleFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(createUserRoleFailure(error.message));
    }
  });
}




export function* onChangePassword() {
  yield takeLatest(CHANGE_PASSWORD_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        UsersService.changepassword,
        payload
      );
      if (result.succeeded) {
       
        // message.success("Password changed successfully");
        yield put(signOut());
        message.success({duration:4,
          content:"Your password has been changed please Sign in again with your new password."});
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setUsersValidationErrors(result.data.errors));
        yield put(ChangePasswordFailure(result.message));
      } else {
        message.error(result.message);
        yield put(ChangePasswordFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(ChangePasswordFailure(error.message));
    }
  });
}


export function* onSendResetEmailUsers() {
  
  yield takeLatest(SEND_RESET_EMAIL_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(UsersService.sendresetemail, payload);
      if (result.succeeded) {
        message.success("A password reset link has been sent to youremail address"); 
        localStorage.removeItem(AUTH_TOKEN);
		    localStorage.removeItem(CURRENT_USER);
        yield put(SendresetemailSuccess(result.message));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(SendresetemailFailure(result.message));
      } else {
        message.error(result.message);
        yield put(SendresetemailFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(SendresetemailFailure(error.message));
    }
  });
}

export function* onResetPasswordUsers() {
  yield takeLatest(RESET_PASSWORD_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(UsersService.resetpassword, payload);
      if (result.succeeded) {
        message.success("Your password has been reseted successfully");
        localStorage.removeItem(AUTH_TOKEN);
		    localStorage.removeItem(CURRENT_USER);
        yield put(ResetPasswordSuccess(result.message));
     
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(ResetPasswordFailure(result.message));
      } else {
        message.error(result.message);
        yield put(ResetPasswordFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(ResetPasswordFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchUsers),
    fork(onUpdateUsers),
    fork(onDeleteUsers),
    fork(onCreateUsers),fork(onCreateUserRole),
    fork(onChangePassword),
    fork(onSendResetEmailUsers),
    fork(onResetPasswordUsers)
  ]);
}