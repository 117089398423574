import {
    FETCH_COMPANIES_FAILURE,
    FETCH_COMPANIES_REQUEST,
    FETCH_COMPANIES_SUCCESS,
    DELETE_COMPANIES_FAILURE,
    DELETE_COMPANIES_REQUEST,
    DELETE_COMPANIES_SUCCESS,
    CREATE_COMPANIES_FAILURE,
    CREATE_COMPANIES_REQUEST,
    CREATE_COMPANIES_SUCCESS,
    SHOW_COMPANIES_EDIT_FORM,
    SHOW_COMPANIES_NEW_FORM,
    UPDATE_COMPANIES_FAILURE,
    UPDATE_COMPANIES_REQUEST,
    UPDATE_COMPANIES_SUCCESS,
    SET_COMPANIES_VALIDATION_ERRORS,
    FETCH_ALL_COMPANIES_REQUEST,
    FETCH_ALL_COMPANIES_SUCCESS,
    FETCH_ALL_COMPANIES_FAILURE,
    RESET_COMPANY_INITIAL_STATE
    // RESET_INITIAL_STATE,
    // RESET_COMPANY_INITIAL_STATE
  } from "../constants/actions.js";
  
  export const fetchCompaniesRequest = (req) => ({
    type: FETCH_COMPANIES_REQUEST,
    payload:req,
  });
  
  export const fetchCompaniesSuccess = (companies) => ({
    type: FETCH_COMPANIES_SUCCESS,
    payload: companies,
  });

  export const fetchCompaniesFailure = (error) => ({
    type: FETCH_COMPANIES_FAILURE,
    payload: error,
  });
  export const fetchAllCompaniesRequest = (req) => ({
    type: FETCH_ALL_COMPANIES_REQUEST,
    payload:req,
  });
  export const fetchAllCompaniesSuccess = (companies) => ({
    type: FETCH_ALL_COMPANIES_SUCCESS,
    payload: companies,
  });
  export const fetchAllCompaniesFailure = (error) => ({
    type: FETCH_ALL_COMPANIES_FAILURE,
    payload: error,
  });
  export const setCompaniesValidationErrors = (validationErrors) => ({
    type: SET_COMPANIES_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleCompaniesEditForm = () => ({
    type: SHOW_COMPANIES_EDIT_FORM,
  });
  
  export const toggleCompaniesNewForm = () => ({
    type: SHOW_COMPANIES_NEW_FORM,
  });
  
  export const updateCompaniesRequest = (companies) => ({
    type: UPDATE_COMPANIES_REQUEST,
    payload: companies,
  });
  
  export const updateCompaniesSuccess = (companies) => ({
    type: UPDATE_COMPANIES_SUCCESS,
    payload: companies,
  });
  
  export const updateCompaniesFailure = (error) => ({
    type: UPDATE_COMPANIES_FAILURE,
    payload: error,
  });
  
  export const deleteCompaniesRequest = (id) => ({
    type: DELETE_COMPANIES_REQUEST,
    payload: id,
  });
  
  export const deleteCompaniesSuccess = (companies) => ({
    type: DELETE_COMPANIES_SUCCESS,
    payload: companies,
  });
  
  export const deleteCompaniesFailure = (error) => ({
    type: DELETE_COMPANIES_FAILURE,
    payload: error,
  });
  
  export const createCompaniesRequest = (companies) => ({
    type: CREATE_COMPANIES_REQUEST,
    payload: companies,
  });
  
  export const createCompaniesSuccess = (companies) => ({
    type: CREATE_COMPANIES_SUCCESS,
    payload: companies,
  });
  
  export const createCompaniesFailure = (error) => ({
    type: CREATE_COMPANIES_FAILURE,
    payload: error,
  });

  
  export const resetcompanyState = () => ({
    type:RESET_COMPANY_INITIAL_STATE
  });


