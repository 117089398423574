import {
    FETCH_LOOKUPS_FAILURE,
    FETCH_LOOKUPS_REQUEST,
    FETCH_LOOKUPS_SUCCESS,
    SET_Voucher_VALIDATION_ERRORS,
    CREATE_Voucher_REQUEST,
    CREATE_Voucher_SUCCESS,
    CREATE_Voucher_FAILURE,
    FETCH_Voucher_ByType_REQUEST,
    FETCH_Voucher_ByType_SUCCESS,
    FETCH_Voucher_ByType_FAILURE
  } from "../constants/actions.js";
  import {
    addVoucher,
    formtValidationErrors,
  } from "utils/Voucher";
  const initState = {
    loading: false,
    list: [],
    errorMessage: "",
    updating: false,
    creating: false,
    validationErrors: [],
    showEditForm: false,
    showNewForm: false,
    deleting: false,
  };
  const Voucher = (state = initState, action) => {
    switch (action.type) {
      case FETCH_Voucher_ByType_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_Voucher_ByType_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload,
        };
      case FETCH_Voucher_ByType_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      case SET_Voucher_VALIDATION_ERRORS:
        return {
          ...state,
          validationErrors: formtValidationErrors(action.payload),
        };
      case CREATE_Voucher_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case CREATE_Voucher_SUCCESS:
        return {
          ...state,
          creating: false,
          showNewForm: !state.showNewForm,
          list: addVoucher(state, action),
        };
      case CREATE_Voucher_FAILURE:
        return {
          ...state,
          creating: false,
          errorMessage: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default Voucher;