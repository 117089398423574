import {
    FETCH_ORDERS_FAILURE,
    FETCH_ORDERS_REQUEST,
    FETCH_ORDERS_SUCCESS,
    DELETE_ORDERS_FAILURE,
    DELETE_ORDERS_REQUEST,
    DELETE_ORDERS_SUCCESS,
    
    CREATE_ORDERS_FAILURE,
    CREATE_ORDERS_REQUEST,
    CREATE_ORDERS_SUCCESS,

    CREATE_ASSIGNMENT_FAILURE,
    CREATE_ASSIGNMENT_REQUEST,
    CREATE_ASSIGNMENT_SUCCESS,

    SHOW_ASSIGNMENT_NEW_FORM,
    SET_ASSIGNMENT_VALIDATION_ERRORS,

    SHOW_ORDERS_EDIT_FORM,
    SHOW_ORDERS_NEW_FORM,
    UPDATE_ORDERS_FAILURE,
    UPDATE_ORDERS_REQUEST,
    UPDATE_ORDERS_SUCCESS,
    SET_ORDERS_VALIDATION_ERRORS,
    CREATE_CLOSETASK_REQUEST,
    CREATE_CLOSETASK_SUCCESS,
    CREATE_CLOSETASK_FAILURE,
    SHOW_CLOSETASK_NEW_FORM,
    SET_CLOSETASK_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchOrdersRequest = (types 
  ) => ({
    type: FETCH_ORDERS_REQUEST,
    payload: types,
  });
  
  export const fetchOrdersSuccess = (orders) => ({
    type: FETCH_ORDERS_SUCCESS,
    payload: orders,
  });
  
  export const fetchOrdersFailure = (error) => ({
    type: FETCH_ORDERS_FAILURE,
    payload: error,
  });

  export const setOrdersValidationErrors = (validationErrors) => ({
    type: SET_ORDERS_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleOrdersEditForm = () => ({
    type: SHOW_ORDERS_EDIT_FORM,
  });
  
  export const toggleOrdersNewForm = () => ({
    type: SHOW_ORDERS_NEW_FORM,
  });

  
  export const updateOrdersRequest = (orders) => ({
    type: UPDATE_ORDERS_REQUEST,
    payload: orders,
  });
  
  export const updateOrdersSuccess = (orders) => ({
    type: UPDATE_ORDERS_SUCCESS,
    payload: orders,
  });
  
  export const updateOrdersFailure = (error) => ({
    type: UPDATE_ORDERS_FAILURE,
    payload: error,
  });
  
  export const deleteOrdersRequest = (id) => ({
    type: DELETE_ORDERS_REQUEST,
    payload: id,
  });
  
  export const deleteOrdersSuccess = (orders) => ({
    type: DELETE_ORDERS_SUCCESS,
    payload: orders,
  });
  
  export const deleteOrdersFailure = (error) => ({
    type: DELETE_ORDERS_FAILURE,
    payload: error,
  });
  
  export const createOrdersRequest = (orders) => ({
    type: CREATE_ORDERS_REQUEST,
    payload: orders,
  });
  
  export const createOrdersSuccess = (orders) => ({
    type: CREATE_ORDERS_SUCCESS,
    payload: orders,
  });
  
  export const createOrdersFailure = (error) => ({
    type: CREATE_ORDERS_FAILURE,
    payload: error,
  });


  export const createAssignmentRequest = (assignment) => ({
    type: CREATE_ASSIGNMENT_REQUEST,
    payload: assignment,
  });
  
  export const createAssignmentSuccess = (assignment) => ({
    type: CREATE_ASSIGNMENT_SUCCESS,
    payload: assignment,
  });
  
  export const createAssignmentFailure = (error) => ({
    type: CREATE_ASSIGNMENT_FAILURE,
    payload: error,
  });


  export const toggleNewAssginmentForm = () => ({
    type: SHOW_ASSIGNMENT_NEW_FORM,
  });

  export const setAssignmentValidationErrors = (validationErrors) => ({
    type: SET_ASSIGNMENT_VALIDATION_ERRORS,
    payload: validationErrors,
  });  



  /////
  // close task

  export const createCloseTaskRequest = (closetask) => ({
    type: CREATE_CLOSETASK_REQUEST,
    payload: closetask,
  });
  
  export const createCloseTaskSuccess = (closetask) => ({
    type: CREATE_CLOSETASK_SUCCESS,
    payload: closetask,
  });
  
  export const createCloseTaskFailure = (error) => ({
    type: CREATE_CLOSETASK_FAILURE,
    payload: error,
  });



  export const toggleNewCloseTaskForm = () => ({
    type: SHOW_CLOSETASK_NEW_FORM,
  });

  export const setCloseTaskValidationErrors = (validationErrors) => ({
    type: SET_CLOSETASK_VALIDATION_ERRORS,
    payload: validationErrors,
  });  



  
