import fetch from "auth/FetchInterceptor";

const BeginningBalanceService = {};
BeginningBalanceService.index = function () {
  return fetch({
    url:
     "v1/BeginningValue?PageNumber=1&PageSize=200" ,
    method: "get",
  });
};

BeginningBalanceService.BinCard = function (type) {
  return fetch({
    url:
     `v1/BeginningValue/BinCard/${type}?PageNumber=1&PageSize=200` ,
    method: "get",
  });
};

BeginningBalanceService.create = function (data) {
  return fetch({
    url: "v1/BeginningValue",
    method: "post",
    data,
  });
};


BeginningBalanceService.update = function (id, data) {
  return fetch({
    url: `v1/BeginningValue/${id}`,
    method: "put",
    data,
  });
};

BeginningBalanceService.delete = function (id) {
  return fetch({
    url: `v1/BeginningValue/${id}`,
    method: "delete",
  });
};
export default BeginningBalanceService;