import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import Employees  from './Employees';
import Companies from './Companies';
import Machines from './Machines';
import Items from './Items';
import Users from './Users';
import Orders from './Orders';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Costbuildups from './Costbuildups';
import Gauges from './Gauges';
import Lookups from './Lookups';
import Lookuptypes from './Lookuptypes';
import Machineslocation from './MachinesLocation';
import Voucher from './Voucher';
import Goodsreceivingvoucher from './Goodsreceivingvoucher';
import BeginningBalance from './BeginningBalance'
import Addresses from './Addresses';
import Feedbacks from './Feedbacks';
import Dashboards from './Dashboards';
const persistConfig = {
    key: "root",
    storage,
    whitelist: [],
  };
const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    employees:Employees,
    machines:Machines,
    companies:Companies,
    items:Items,
    users:Users,
    orders:Orders,
    costbuildups:Costbuildups,
    gauges:Gauges,
    lookups:Lookups,
    lookuptypes:Lookuptypes,
    machineslocation:Machineslocation,
    voucher:Voucher,
    goodsreceivingvoucher:Goodsreceivingvoucher,
    beginningbalance:BeginningBalance,
    addresses:Addresses,
    feedbacks:Feedbacks,
    dashboards:Dashboards
});

export default persistReducer(persistConfig, reducers);
