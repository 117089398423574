import fetch from "auth/FetchInterceptor";

const CostbuildupService = {};

CostbuildupService.index = function () {
  return fetch({
    url:
     "v1/ServiceCost?PageNumber=1&PageSize=200" ,
    method: "get",
  });
};


CostbuildupService.create = function (data) {
  return fetch({
    url: "v1/ServiceCost",
    method: "post",
    data,
  });
};


CostbuildupService.update = function (id, data) {
  return fetch({
    url: `v1/ServiceCost/${id}`,
    method: "put",
    data,
  });
};

CostbuildupService.delete = function (id) {
  return fetch({
    url: `v1/ServiceCost/${id}`,
    method: "delete",
  });
};

export default CostbuildupService;