import {
    FETCH_EMPLOYEES_FAILURE,
    FETCH_EMPLOYEES_REQUEST,
    FETCH_EMPLOYEES_SUCCESS,
    FETCH_EMPLOYEES_ATTENDANCE_FAILURE,
    FETCH_EMPLOYEES_ATTENDANCE_REQUEST,
    FETCH_EMPLOYEES_ATTENDANCE_SUCCESS,

    DELETE_EMPLOYEES_FAILURE,
    DELETE_EMPLOYEES_REQUEST,
    DELETE_EMPLOYEES_SUCCESS,
    CREATE_EMPLOYEES_FAILURE,
    CREATE_EMPLOYEES_REQUEST,
    CREATE_EMPLOYEES_SUCCESS,
    SHOW_EMPLOYEES_EDIT_FORM,
    SHOW_EMPLOYEES_NEW_FORM,
    UPDATE_EMPLOYEES_FAILURE,
    UPDATE_EMPLOYEES_REQUEST,
    UPDATE_EMPLOYEES_SUCCESS,
    SET_EMPLOYEES_VALIDATION_ERRORS,
    FETCH_EMP_REQUEST,
    FETCH_EMP_SUCCESS,
    FETCH_EMP_FAILURE,
    CREATE_EMPATT_REQUEST,
    CREATE_EMPATT_SUCCESS,
    CREATE_EMPATT_FAILURE
  } from "../constants/actions.js";
  
  export const fetchEmployeesRequest = (  
  ) => ({
    type: FETCH_EMPLOYEES_REQUEST
  });
  
  export const fetchEmployeesSuccess = (employees) => ({
    type: FETCH_EMPLOYEES_SUCCESS,
    payload: employees,
  });
  
  export const fetchEmployeesFailure = (error) => ({
    type: FETCH_EMPLOYEES_FAILURE,
    payload: error,
  });

  export const setEmployeesValidationErrors = (validationErrors) => ({
    type: SET_EMPLOYEES_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleEmployeesEditForm = () => ({
    type: SHOW_EMPLOYEES_EDIT_FORM,
  });
  
  export const toggleEmployeesNewForm = () => ({
    type: SHOW_EMPLOYEES_NEW_FORM,
  });
  
  export const updateEmployeesRequest = (employees) => ({
    type: UPDATE_EMPLOYEES_REQUEST,
    payload: employees,
  });
  
  export const updateEmployeesSuccess = (employees) => ({
    type: UPDATE_EMPLOYEES_SUCCESS,
    payload: employees,
  });
  
  export const updateEmployeesFailure = (error) => ({
    type: UPDATE_EMPLOYEES_FAILURE,
    payload: error,
  });
  
  export const deleteEmployeesRequest = (id) => ({
    type: DELETE_EMPLOYEES_REQUEST,
    payload: id,
  });
  
  export const deleteEmployeesSuccess = (employees) => ({
    type: DELETE_EMPLOYEES_SUCCESS,
    payload: employees,
  });
  
  export const deleteEmployeesFailure = (error) => ({
    type: DELETE_EMPLOYEES_FAILURE,
    payload: error,
  });
  
  export const createEmployeesRequest = (employees) => ({
    type: CREATE_EMPLOYEES_REQUEST,
    payload: employees,
  });
  
  export const createEmployeesSuccess = (employees) => ({
    type: CREATE_EMPLOYEES_SUCCESS,
    payload: employees,
  });
  
  export const createEmployeesFailure = (error) => ({
    type: CREATE_EMPLOYEES_FAILURE,
    payload: error,
  });

  

  export const fetchEmployeesAttendanceRequest = (req) => ({
      type: FETCH_EMPLOYEES_ATTENDANCE_REQUEST,
      payload:req
    });
    
    export const fetchEmployeesAttendanceSuccess = (attendances) => ({
      type: FETCH_EMPLOYEES_ATTENDANCE_SUCCESS,
      payload: attendances,
    });
    
    export const fetchEmployeesAttendanceFailure = (error) => ({
      type: FETCH_EMPLOYEES_ATTENDANCE_FAILURE,
      payload: error,
    });
  
    
  export const fetchEmpAttendanceRequest = (req) => ({
    type: FETCH_EMP_REQUEST,
    payload:req
  });
  
  export const fetchEmpAttendanceSuccess = (attendances) => ({
    type: FETCH_EMP_SUCCESS,
    payload: attendances,
  });
  
  export const fetchEmpAttendanceFailure = (error) => ({
    type: FETCH_EMP_FAILURE,
    payload: error,
  });


  export const createEmpAttRequest = (empatt) => ({
    type: CREATE_EMPATT_REQUEST,
    payload: empatt,
  });
  
  export const createEmpAttSuccess = (empatt) => ({
    type: CREATE_EMPATT_SUCCESS,
    payload: empatt,
  });
  
  export const createEmpAttFailure = (error) => ({
    type: CREATE_EMPATT_FAILURE,
    payload: error,
  });
