import { CREATE_Voucher_FAILURE, CREATE_Voucher_REQUEST, CREATE_Voucher_SUCCESS, DELETE_Voucher_FAILURE, FETCH_Voucher_ByType_FAILURE, FETCH_Voucher_ByType_REQUEST, FETCH_Voucher_ByType_SUCCESS, SET_Voucher_VALIDATION_ERRORS } from "redux/constants/actions";

export const setVoucherValidationErrors = (validationErrors) => ({
    type: SET_Voucher_VALIDATION_ERRORS,
    payload: validationErrors,
  });
export const createVoucherRequest = (Voucher) => ({
    type: CREATE_Voucher_REQUEST,
    payload: Voucher,
  });
  
  export const createVoucherSuccess = (Voucher) => ({
    type: CREATE_Voucher_SUCCESS,
    payload: Voucher,
  });
  
  export const createVoucherFailure = (error) => ({
    type: CREATE_Voucher_FAILURE,
    payload: error,
  });

  export const deleteVoucherFailure = (error) => ({
    type: DELETE_Voucher_FAILURE,
    payload: error,
  });

  export const fetchVoucherByTypeRequest = (Filter) => ({
    type:  FETCH_Voucher_ByType_REQUEST,
    payload: Filter,
  });

  export const fetchVoucherByTypeSuccess = (Voucher) => ({
    type:  FETCH_Voucher_ByType_SUCCESS,
    payload: Voucher,
  });

  export const fetchVoucherByTypeFailure = (error) => ({
    type:FETCH_Voucher_ByType_FAILURE,
    payload: error,
  });