import {
  FETCH_LOOKUPS_FAILURE,
  FETCH_LOOKUPS_REQUEST,
  FETCH_LOOKUPS_SUCCESS,
  DELETE_LOOKUPS_FAILURE,
  DELETE_LOOKUPS_REQUEST,
  DELETE_LOOKUPS_SUCCESS,
  CREATE_LOOKUPS_FAILURE,
  CREATE_LOOKUPS_REQUEST,
  CREATE_LOOKUPS_SUCCESS,
  SHOW_LOOKUPS_EDIT_FORM,
  SHOW_LOOKUPS_NEW_FORM,
  UPDATE_LOOKUPS_FAILURE,
  UPDATE_LOOKUPS_REQUEST,
  UPDATE_LOOKUPS_SUCCESS,
  SET_LOOKUPS_VALIDATION_ERRORS
} from "../constants/actions.js";
import {
  addLookups,
  formtValidationErrors,
  removeLookups,
  updateLookups,
} from "utils/Lookups";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const lookups = (state = initState, action) => {
  switch (action.type) {
    case FETCH_LOOKUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOOKUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_LOOKUPS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_LOOKUPS_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_LOOKUPS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_LOOKUPS_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_LOOKUPS_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_LOOKUPS_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateLookups(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_LOOKUPS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_LOOKUPS_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_LOOKUPS_SUCCESS:
      return {
        ...state,
        deleting: false,
        
        list: removeLookups(state, action),
      };
    case DELETE_LOOKUPS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_LOOKUPS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_LOOKUPS_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addLookups(state, action),
      };
    case CREATE_LOOKUPS_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default lookups;