export const FETCH_EMPLOYEES_REQUEST = "FETCH_EMPLOYEES_REQUEST";
export const FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS";
export const FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_FAILURE";

export const FETCH_EMPLOYEES_ATTENDANCE_REQUEST = "FETCH_EMPLOYEES_ATTENDANCE_REQUEST";
export const FETCH_EMPLOYEES_ATTENDANCE_SUCCESS = "FETCH_EMPLOYEES_ATTENDANCE_SUCCESS";
export const FETCH_EMPLOYEES_ATTENDANCE_FAILURE = "FETCH_EMPLOYEES_ATTENDANCE_FAILURE";


export const DELETE_EMPLOYEES_FAILURE="DELETE_EMPLOYEES_FAILURE";
export const DELETE_EMPLOYEES_REQUEST="DELETE_EMPLOYEES_REQUEST";
export const DELETE_EMPLOYEES_SUCCESS="DELETE_EMPLOYEES_SUCCESS";

export const CREATE_EMPLOYEES_FAILURE="CREATE_EMPLOYEES_FAILURE";
export const CREATE_EMPLOYEES_REQUEST="CREATE_EMPLOYEES_REQUEST";
export const CREATE_EMPLOYEES_SUCCESS="CREATE_EMPLOYEES_SUCCESS";

export const SHOW_EMPLOYEES_EDIT_FORM="SHOW_EMPLOYEES_EDIT_FORM";
export const SHOW_EMPLOYEES_NEW_FORM="SHOW_EMPLOYEES_NEW_FORM";
export const UPDATE_EMPLOYEES_FAILURE="UPDATE_EMPLOYEES_FAILURE";
export const UPDATE_EMPLOYEES_REQUEST="UPDATE_EMPLOYEES_REQUEST";
export const UPDATE_EMPLOYEES_SUCCESS="UPDATE_EMPLOYEES_SUCCESS";
export const SET_EMPLOYEES_VALIDATION_ERRORS="SET_EMPLOYEES_VALIDATION_ERRORS"


export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";



export const DELETE_USERS_FAILURE="DELETE_USERS_FAILURE";
export const DELETE_USERS_REQUEST="DELETE_USERS_REQUEST";
export const DELETE_USERS_SUCCESS="DELETE_USERS_SUCCESS";

export const CREATE_USERS_FAILURE="CREATE_USERS_FAILURE";
export const CREATE_USERS_REQUEST="CREATE_USERS_REQUEST";
export const CREATE_USERS_SUCCESS="CREATE_USERS_SUCCESS";

export const SHOW_USERS_EDIT_FORM="SHOW_USERS_EDIT_FORM";
export const SHOW_USERS_NEW_FORM="SHOW_USERS_NEW_FORM";
export const UPDATE_USERS_FAILURE="UPDATE_USERS_FAILURE";
export const UPDATE_USERS_REQUEST="UPDATE_USERS_REQUEST";
export const UPDATE_USERS_SUCCESS="UPDATE_USERS_SUCCESS";
export const SET_USERROLE_VALIDATION_ERRORS="SET_USERROLE_VALIDATION_ERRORS"
export const SHOW_USERROLE_NEW_FORM="SHOW_USERROLE_NEW_FORM";

export const CREATE_USERROLE_FAILURE="CREATE_USERROLE_FAILURE";
export const CREATE_USERROLE_REQUEST="CREATE_USERROLE_REQUEST";
export const CREATE_USERROLE_SUCCESS="CREATE_USERROLE_SUCCESS";
export const SET_USERS_VALIDATION_ERRORS="SET_USERS_VALIDATION_ERRORS"


export const FETCH_MACHINES_REQUEST = "FETCH_MACHINES_REQUEST";
export const FETCH_MACHINES_SUCCESS = "FETCH_MACHINES_SUCCESS";
export const FETCH_MACHINES_FAILURE = "FETCH_MACHINES_FAILURE";

export const DELETE_MACHINES_FAILURE="DELETE_MACHINES_FAILURE";
export const DELETE_MACHINES_REQUEST="DELETE_MACHINES_REQUEST";
export const DELETE_MACHINES_SUCCESS="DELETE_MACHINES_SUCCESS";

export const CREATE_MACHINES_FAILURE="CREATE_MACHINES_FAILURE";
export const CREATE_MACHINES_REQUEST="CREATE_MACHINES_REQUEST";
export const CREATE_MACHINES_SUCCESS="CREATE_MACHINES_SUCCESS";

export const SHOW_MACHINES_EDIT_FORM="SHOW_MACHINES_EDIT_FORM";
export const SHOW_MACHINES_NEW_FORM="SHOW_MACHINES_NEW_FORM";
export const UPDATE_MACHINES_FAILURE="UPDATE_MACHINES_FAILURE";
export const UPDATE_MACHINES_REQUEST="UPDATE_MACHINES_REQUEST";
export const UPDATE_MACHINES_SUCCESS="UPDATE_MACHINES_SUCCESS";
export const SET_MACHINES_VALIDATION_ERRORS="SET_MACHINES_VALIDATION_ERRORS"


export const FETCH_ITEMS_REQUEST = "FETCH_ITEMS_REQUEST";
export const FETCH_ITEMS_SUCCESS = "FETCH_ITEMS_SUCCESS";
export const FETCH_ITEMS_FAILURE = "FETCH_ITEMS_FAILURE";

export const DELETE_ITEMS_FAILURE="DELETE_ITEMS_FAILURE";
export const DELETE_ITEMS_REQUEST="DELETE_ITEMS_REQUEST";
export const DELETE_ITEMS_SUCCESS="DELETE_ITEMS_SUCCESS";

export const CREATE_ITEMS_FAILURE="CREATE_ITEMS_FAILURE";
export const CREATE_ITEMS_REQUEST="CREATE_ITEMS_REQUEST";
export const CREATE_ITEMS_SUCCESS="CREATE_ITEMS_SUCCESS";

export const SHOW_ITEMS_EDIT_FORM="SHOW_ITEMS_EDIT_FORM";
export const SHOW_ITEMS_NEW_FORM="SHOW_ITEMS_NEW_FORM";
export const UPDATE_ITEMS_FAILURE="UPDATE_ITEMS_FAILURE";
export const UPDATE_ITEMS_REQUEST="UPDATE_ITEMS_REQUEST";
export const UPDATE_ITEMS_SUCCESS="UPDATE_ITEMS_SUCCESS";
export const SET_ITEMS_VALIDATION_ERRORS="SET_ITEMS_VALIDATION_ERRORS"



export const FETCH_COMPANIES_REQUEST = "FETCH_COMPANIES_REQUEST";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";


export const FETCH_ALL_COMPANIES_REQUEST = "FETCH_ALL_COMPANIES_REQUEST";
export const FETCH_ALL_COMPANIES_SUCCESS = "FETCH_ALL_COMPANIES_SUCCESS";
export const FETCH_ALL_COMPANIES_FAILURE = "FETCH_ALL_COMPANIES_FAILURE";

export const DELETE_COMPANIES_FAILURE="DELETE_COMPANIES_FAILURE";
export const DELETE_COMPANIES_REQUEST="DELETE_COMPANIES_REQUEST";
export const DELETE_COMPANIES_SUCCESS="DELETE_COMPANIES_SUCCESS";

export const CREATE_COMPANIES_FAILURE="CREATE_COMPANIES_FAILURE";
export const CREATE_COMPANIES_REQUEST="CREATE_COMPANIES_REQUEST";
export const CREATE_COMPANIES_SUCCESS="CREATE_COMPANIES_SUCCESS";

export const SHOW_COMPANIES_EDIT_FORM="SHOW_COMPANIES_EDIT_FORM";
export const SHOW_COMPANIES_NEW_FORM="SHOW_COMPANIES_NEW_FORM";
export const UPDATE_COMPANIES_FAILURE="UPDATE_COMPANIES_FAILURE";
export const UPDATE_COMPANIES_REQUEST="UPDATE_COMPANIES_REQUEST";
export const UPDATE_COMPANIES_SUCCESS="UPDATE_COMPANIES_SUCCESS";
export const SET_COMPANIES_VALIDATION_ERRORS="SET_COMPANIES_VALIDATION_ERRORS"



export const FETCH_ORDERS_REQUEST = "FETCH_ORDERS_REQUEST";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_FAILURE = "FETCH_ORDERS_FAILURE";

export const DELETE_ORDERS_FAILURE="DELETE_ORDERS_FAILURE";
export const DELETE_ORDERS_REQUEST="DELETE_ORDERS_REQUEST";
export const DELETE_ORDERS_SUCCESS="DELETE_ORDERS_SUCCESS";

export const CREATE_ORDERS_FAILURE="CREATE_ORDERS_FAILURE";
export const CREATE_ORDERS_REQUEST="CREATE_ORDERS_REQUEST";
export const CREATE_ORDERS_SUCCESS="CREATE_ORDERS_SUCCESS";

export const SHOW_ORDERS_EDIT_FORM="SHOW_ORDERS_EDIT_FORM";
export const SHOW_ORDERS_NEW_FORM="SHOW_ORDERS_NEW_FORM";
export const UPDATE_ORDERS_FAILURE="UPDATE_ORDERS_FAILURE";
export const UPDATE_ORDERS_REQUEST="UPDATE_ORDERS_REQUEST";
export const UPDATE_ORDERS_SUCCESS="UPDATE_ORDERS_SUCCESS";
export const SET_ORDERS_VALIDATION_ERRORS="SET_ORDERS_VALIDATION_ERRORS"

export const CREATE_ASSIGNMENT_FAILURE="CREATE_ASSIGNMENT_FAILURE";
export const CREATE_ASSIGNMENT_REQUEST="CREATE_ASSIGNMENT_REQUEST";
export const CREATE_ASSIGNMENT_SUCCESS="CREATE_ASSIGNMENT_SUCCESS";

export const SHOW_ASSIGNMENT_EDIT_FORM="SHOW_ASSIGNMENT_EDIT_FORM";
export const SHOW_ASSIGNMENT_NEW_FORM="SHOW_ASSIGNMENT_NEW_FORM";
export const SET_ASSIGNMENT_VALIDATION_ERRORS="SET_ASSIGNMENT_VALIDATION_ERRORS"



export const FETCH_COSTBUILDUPS_REQUEST = "FETCH_COSTBUILDUPS_REQUEST";
export const FETCH_COSTBUILDUPS_SUCCESS = "FETCH_COSTBUILDUPS_SUCCESS";
export const FETCH_COSTBUILDUPS_FAILURE = "FETCH_COSTBUILDUPS_FAILURE";

export const DELETE_COSTBUILDUPS_FAILURE="DELETE_COSTBUILDUPS_FAILURE";
export const DELETE_COSTBUILDUPS_REQUEST="DELETE_COSTBUILDUPS_REQUEST";
export const DELETE_COSTBUILDUPS_SUCCESS="DELETE_COSTBUILDUPS_SUCCESS";

export const CREATE_COSTBUILDUPS_FAILURE="CREATE_COSTBUILDUPS_FAILURE";
export const CREATE_COSTBUILDUPS_REQUEST="CREATE_COSTBUILDUPS_REQUEST";
export const CREATE_COSTBUILDUPS_SUCCESS="CREATE_COSTBUILDUPS_SUCCESS";

export const SHOW_COSTBUILDUPS_EDIT_FORM="SHOW_COSTBUILDUPS_EDIT_FORM";
export const SHOW_COSTBUILDUPS_NEW_FORM="SHOW_COSTBUILDUPS_NEW_FORM";
export const UPDATE_COSTBUILDUPS_FAILURE="UPDATE_COSTBUILDUPS_FAILURE";
export const UPDATE_COSTBUILDUPS_REQUEST="UPDATE_COSTBUILDUPS_REQUEST";
export const UPDATE_COSTBUILDUPS_SUCCESS="UPDATE_COSTBUILDUPS_SUCCESS";
export const SET_COSTBUILDUPS_VALIDATION_ERRORS="SET_COSTBUILDUPS_VALIDATION_ERRORS"



export const FETCH_GAUGES_REQUEST = "FETCH_GAUGES_REQUEST";
export const FETCH_GAUGES_SUCCESS = "FETCH_GAUGES_SUCCESS";
export const FETCH_GAUGES_FAILURE = "FETCH_GAUGES_FAILURE";

export const DELETE_GAUGES_FAILURE="DELETE_GAUGES_FAILURE";
export const DELETE_GAUGES_REQUEST="DELETE_GAUGES_REQUEST";
export const DELETE_GAUGES_SUCCESS="DELETE_GAUGES_SUCCESS";

export const CREATE_GAUGES_FAILURE="CREATE_GAUGES_FAILURE";
export const CREATE_GAUGES_REQUEST="CREATE_GAUGES_REQUEST";
export const CREATE_GAUGES_SUCCESS="CREATE_GAUGES_SUCCESS";

export const Upload_GAUGES_FAILURE="Upload_GAUGES_FAILURE";
export const Upload_GAUGES_REQUEST="Upload_GAUGES_REQUEST";
export const Upload_GAUGES_SUCCESS="Upload_GAUGES_SUCCESS";

export const SHOW_GAUGES_EDIT_FORM="SHOW_GAUGES_EDIT_FORM";
export const SHOW_GAUGES_NEW_FORM="SHOW_GAUGES_NEW_FORM";
export const UPDATE_GAUGES_FAILURE="UPDATE_GAUGES_FAILURE";
export const UPDATE_GAUGES_REQUEST="UPDATE_GAUGES_REQUEST";
export const UPDATE_GAUGES_SUCCESS="UPDATE_GAUGES_SUCCESS";
export const SET_GAUGES_VALIDATION_ERRORS="SET_GAUGES_VALIDATION_ERRORS"


export const FETCH_LOOKUPS_REQUEST = "FETCH_LOOKUPS_REQUEST";
export const FETCH_LOOKUPS_SUCCESS = "FETCH_LOOKUPS_SUCCESS";
export const FETCH_LOOKUPS_FAILURE = "FETCH_LOOKUPS_FAILURE";

export const DELETE_LOOKUPS_FAILURE="DELETE_LOOKUPS_FAILURE";
export const DELETE_LOOKUPS_REQUEST="DELETE_LOOKUPS_REQUEST";
export const DELETE_LOOKUPS_SUCCESS="DELETE_LOOKUPS_SUCCESS";

export const CREATE_LOOKUPS_FAILURE="CREATE_LOOKUPS_FAILURE";
export const CREATE_LOOKUPS_REQUEST="CREATE_LOOKUPS_REQUEST";
export const CREATE_LOOKUPS_SUCCESS="CREATE_LOOKUPS_SUCCESS";

export const SHOW_LOOKUPS_EDIT_FORM="SHOW_LOOKUPS_EDIT_FORM";
export const SHOW_LOOKUPS_NEW_FORM="SHOW_LOOKUPS_NEW_FORM";
export const UPDATE_LOOKUPS_FAILURE="UPDATE_LOOKUPS_FAILURE";
export const UPDATE_LOOKUPS_REQUEST="UPDATE_LOOKUPS_REQUEST";
export const UPDATE_LOOKUPS_SUCCESS="UPDATE_LOOKUPS_SUCCESS";
export const SET_LOOKUPS_VALIDATION_ERRORS="SET_LOOKUPS_VALIDATION_ERRORS"


export const FETCH_LOOKUPTYPES_REQUEST = "FETCH_LOOKUPTYPES_REQUEST";
export const FETCH_LOOKUPTYPES_SUCCESS = "FETCH_LOOKUPTYPES_SUCCESS";
export const FETCH_LOOKUPTYPES_FAILURE = "FETCH_LOOKUPTYPES_FAILURE";

export const DELETE_LOOKUPTYPES_FAILURE="DELETE_LOOKUPTYPES_FAILURE";
export const DELETE_LOOKUPTYPES_REQUEST="DELETE_LOOKUPTYPES_REQUEST";
export const DELETE_LOOKUPTYPES_SUCCESS="DELETE_LOOKUPTYPES_SUCCESS";

export const CREATE_LOOKUPTYPES_FAILURE="CREATE_LOOKUPTYPES_FAILURE";
export const CREATE_LOOKUPTYPES_REQUEST="CREATE_LOOKUPTYPES_REQUEST";
export const CREATE_LOOKUPTYPES_SUCCESS="CREATE_LOOKUPTYPES_SUCCESS";

export const SHOW_LOOKUPTYPES_EDIT_FORM="SHOW_LOOKUPTYPES_EDIT_FORM";
export const SHOW_LOOKUPTYPES_NEW_FORM="SHOW_LOOKUPTYPES_NEW_FORM";
export const UPDATE_LOOKUPTYPES_FAILURE="UPDATE_LOOKUPTYPES_FAILURE";
export const UPDATE_LOOKUPTYPES_REQUEST="UPDATE_LOOKUPTYPES_REQUEST";
export const UPDATE_LOOKUPTYPES_SUCCESS="UPDATE_LOOKUPTYPES_SUCCESS";
export const SET_LOOKUPTYPES_VALIDATION_ERRORS="SET_LOOKUPTYPES_VALIDATION_ERRORS"


export const FETCH_MACHINESLOCATION_REQUEST = "FETCH_MACHINESLOCATION_REQUEST";
export const FETCH_MACHINESLOCATION_SUCCESS = "FETCH_MACHINESLOCATION_SUCCESS";
export const FETCH_MACHINESLOCATION_FAILURE = "FETCH_MACHINESLOCATION_FAILURE";

export const DELETE_MACHINESLOCATION_FAILURE="DELETE_MACHINESLOCATION_FAILURE";
export const DELETE_MACHINESLOCATION_REQUEST="DELETE_MACHINESLOCATION_REQUEST";
export const DELETE_MACHINESLOCATION_SUCCESS="DELETE_MACHINESLOCATION_SUCCESS";

export const CREATE_MACHINESLOCATION_FAILURE="CREATE_MACHINESLOCATION_FAILURE";
export const CREATE_MACHINESLOCATION_REQUEST="CREATE_MACHINESLOCATION_REQUEST";
export const CREATE_MACHINESLOCATION_SUCCESS="CREATE_MACHINESLOCATION_SUCCESS";

export const SHOW_MACHINESLOCATION_EDIT_FORM="SHOW_MACHINESLOCATION_EDIT_FORM";
export const SHOW_MACHINESLOCATION_NEW_FORM="SHOW_MACHINESLOCATION_NEW_FORM";
export const UPDATE_MACHINESLOCATION_FAILURE="UPDATE_MACHINESLOCATION_FAILURE";
export const UPDATE_MACHINESLOCATION_REQUEST="UPDATE_MACHINESLOCATION_REQUEST";
export const UPDATE_MACHINESLOCATION_SUCCESS="UPDATE_MACHINESLOCATION_SUCCESS";
export const SET_MACHINESLOCATION_VALIDATION_ERRORS="SET_MACHINESLOCATION_VALIDATION_ERRORS"


export const FETCH_Voucher_ByType_REQUEST="CREATE_Voucher_ByType_REQUEST"
export const FETCH_Voucher_ByType_SUCCESS="CREATE_Voucher_ByType_SUCCESS"
export const FETCH_Voucher_ByType_FAILURE="CREATE_Voucher_ByType_FAILURE"

export const CREATE_Voucher_REQUEST="CREATE_Voucher_REQUEST";
export const CREATE_Voucher_FAILURE="CREATE_Voucher_FAILURE";
export const CREATE_Voucher_SUCCESS="CREATE_Voucher_SUCCESS";
export const SET_Voucher_VALIDATION_ERRORS="SET_Voucher_VALIDATION_ERRORS"
export const DELETE_Voucher_FAILURE="DELETE_Voucher_FAILURE"

export const CREATE_Goodsreceivingvoucher_REQUEST="CREATE_Goodsreceivingvoucher_REQUEST";
export const CREATE_Goodsreceivingvoucher_FAILURE="CREATE_Goodsreceivingvoucher_FAILURE";
export const CREATE_Goodsreceivingvoucher_SUCCESS="CREATE_Goodsreceivingvoucher_SUCCESS";
export const SET_Goodsreceivingvoucher_VALIDATION_ERRORS="SET_Goodsreceivingvoucher_VALIDATION_ERRORS"
export const DELETE_Goodsreceivingvoucher_FAILURE="DELETE_Goodsreceivingvoucher_FAILURE"


export const FETCH_BeginningBalance_REQUEST = "FETCH_BeginningBalance_REQUEST";
export const FETCH_StokeBalance_REQUEST = "FETCH_StokeBalance_REQUEST";
export const FETCH_StokeBalance_SUCCESS = "FETCH_StokeBalance_SUCCESS";
export const FETCH_BeginningBalance_SUCCESS = "FETCH_BeginningBalance_SUCCESS";

export const FETCH_BeginningBalance_FAILURE = "FETCH_BeginningBalance_FAILURE";

 

export const DELETE_BeginningBalance_FAILURE="DELETE_BeginningBalance_FAILURE";
export const DELETE_BeginningBalance_REQUEST="DELETE_BeginningBalance_REQUEST";
export const DELETE_BeginningBalance_SUCCESS="DELETE_BeginningBalance_SUCCESS";

export const CREATE_BeginningBalance_FAILURE="CREATE_BeginningBalance_FAILURE";
export const CREATE_BeginningBalance_REQUEST="CREATE_BeginningBalance_REQUEST";
export const CREATE_BeginningBalance_SUCCESS="CREATE_BeginningBalance_SUCCESS";

export const SHOW_BeginningBalance_EDIT_FORM="SHOW_BeginningBalance_EDIT_FORM";
export const SHOW_BeginningBalance_NEW_FORM="SHOW_BeginningBalance_NEW_FORM";
export const UPDATE_BeginningBalance_FAILURE="UPDATE_BeginningBalance_FAILURE";
export const UPDATE_BeginningBalance_REQUEST="UPDATE_BeginningBalance_REQUEST";
export const UPDATE_BeginningBalance_SUCCESS="UPDATE_BeginningBalance_SUCCESS";
export const SET_BeginningBalance_VALIDATION_ERRORS="SET_BeginningBalance_VALIDATION_ERRORS"
 


export const FETCH_MACHINESHISTORY_REQUEST = "FETCH_MACHINESHISTORY_REQUEST";
export const FETCH_MACHINESHISTORY_SUCCESS = "FETCH_MACHINESHISTORY_SUCCESS";
export const FETCH_MACHINESHISTORY_FAILURE = "FETCH_MACHINESHISTORY_FAILURE";

export const GET_GAGE_REQUEST = "GET_GAGE_REQUEST";
export const GET_GAGE_SUCCESS = "GET_GAGE_SUCCESS";
export const GET_GAGE_FAILURE = "GET_GAGE_FAILURE";


export const FETCH_ADDRESSES_REQUEST = "FETCH_ADDRESSES_REQUEST";
export const FETCH_ADDRESSES_SUCCESS = "FETCH_ADDRESSES_SUCCESS";
export const FETCH_ADDRESSES_FAILURE = "FETCH_ADDRESSES_FAILURE";

export const FETCH_ADDRESSESLIST_REQUEST = "FETCH_ADDRESSESLIST_REQUEST";
export const FETCH_ADDRESSESLIST_SUCCESS = "FETCH_ADDRESSESLIST_SUCCESS";
export const FETCH_ADDRESSESLIST_FAILURE = "FETCH_ADDRESSESLIST_FAILURE";


export const DELETE_ADDRESSES_FAILURE="DELETE_ADDRESSES_FAILURE";
export const DELETE_ADDRESSES_REQUEST="DELETE_ADDRESSES_REQUEST";
export const DELETE_ADDRESSES_SUCCESS="DELETE_ADDRESSES_SUCCESS";

export const CREATE_ADDRESSES_FAILURE="CREATE_ADDRESSES_FAILURE";
export const CREATE_ADDRESSES_REQUEST="CREATE_ADDRESSES_REQUEST";
export const CREATE_ADDRESSES_SUCCESS="CREATE_ADDRESSES_SUCCESS";

export const SHOW_ADDRESSES_EDIT_FORM="SHOW_ADDRESSES_EDIT_FORM";
export const SHOW_ADDRESSES_NEW_FORM="SHOW_ADDRESSES_NEW_FORM";
export const UPDATE_ADDRESSES_FAILURE="UPDATE_ADDRESSES_FAILURE";
export const UPDATE_ADDRESSES_REQUEST="UPDATE_ADDRESSES_REQUEST";
export const UPDATE_ADDRESSES_SUCCESS="UPDATE_ADDRESSES_SUCCESS";
export const SET_ADDRESSES_VALIDATION_ERRORS="SET_ADDRESSES_VALIDATION_ERRORS"



export const FETCH_FEEDBACKS_REQUEST = "FETCH_FEEDBACKS_REQUEST";
export const FETCH_FEEDBACKS_SUCCESS = "FETCH_FEEDBACKS_SUCCESS";
export const FETCH_FEEDBACKS_FAILURE = "FETCH_FEEDBACKS_FAILURE";



export const DELETE_FEEDBACKS_FAILURE="DELETE_FEEDBACKS_FAILURE";
export const DELETE_FEEDBACKS_REQUEST="DELETE_FEEDBACKS_REQUEST";
export const DELETE_FEEDBACKS_SUCCESS="DELETE_FEEDBACKS_SUCCESS";

export const CREATE_FEEDBACKS_FAILURE="CREATE_FEEDBACKS_FAILURE";
export const CREATE_FEEDBACKS_REQUEST="CREATE_FEEDBACKS_REQUEST";
export const CREATE_FEEDBACKS_SUCCESS="CREATE_FEEDBACKS_SUCCESS";

export const SHOW_FEEDBACKS_EDIT_FORM="SHOW_FEEDBACKS_EDIT_FORM";
export const SHOW_FEEDBACKS_NEW_FORM="SHOW_FEEDBACKS_NEW_FORM";
export const UPDATE_FEEDBACKS_FAILURE="UPDATE_FEEDBACKS_FAILURE";
export const UPDATE_FEEDBACKS_REQUEST="UPDATE_FEEDBACKS_REQUEST";
export const UPDATE_FEEDBACKS_SUCCESS="UPDATE_FEEDBACKS_SUCCESS";
export const SET_FEEDBACKS_VALIDATION_ERRORS="SET_FEEDBACKS_VALIDATION_ERRORS"
export const SHOW_FEEDBACKROLE_NEW_FORM="SHOW_FEEDBACK_NEW_FORM";



export const FETCH_DASHBOARDS_REQUEST = "FETCH_DASHBOARDS_REQUEST";
export const FETCH_DASHBOARDS_SUCCESS = "FETCH_DASHBOARDS_SUCCESS";
export const FETCH_DASHBOARDS_FAILURE = "FETCH_DASHBOARDS_FAILURE";

export const DELETE_DASHBOARDS_FAILURE="DELETE_DASHBOARDS_FAILURE";
export const DELETE_DASHBOARDS_REQUEST="DELETE_DASHBOARDS_REQUEST";
export const DELETE_DASHBOARDS_SUCCESS="DELETE_DASHBOARDS_SUCCESS";

export const CREATE_DASHBOARDS_FAILURE="CREATE_DASHBOARDS_FAILURE";
export const CREATE_DASHBOARDS_REQUEST="CREATE_DASHBOARDS_REQUEST";
export const CREATE_DASHBOARDS_SUCCESS="CREATE_DASHBOARDS_SUCCESS";

export const SHOW_DASHBOARDS_EDIT_FORM="SHOW_DASHBOARDS_EDIT_FORM";
export const SHOW_DASHBOARDS_NEW_FORM="SHOW_DASHBOARDS_NEW_FORM";
export const UPDATE_DASHBOARDS_FAILURE="UPDATE_DASHBOARDS_FAILURE";
export const UPDATE_DASHBOARDS_REQUEST="UPDATE_DASHBOARDS_REQUEST";
export const UPDATE_DASHBOARDS_SUCCESS="UPDATE_DASHBOARDS_SUCCESS";
export const SET_DASHBOARDS_VALIDATION_ERRORS="SET_DASHBOARDS_VALIDATION_ERRORS"


export const FETCH_DASHBOARDTYPES_REQUEST = "FETCH_DASHBOARDTYPES_REQUEST";
export const FETCH_DASHBOARDTYPES_SUCCESS = "FETCH_DASHBOARDTYPES_SUCCESS";
export const FETCH_DASHBOARDTYPES_FAILURE = "FETCH_DASHBOARDTYPES_FAILURE";

export const DELETE_DASHBOARDTYPES_FAILURE="DELETE_DASHBOARDTYPES_FAILURE";
export const DELETE_DASHBOARDTYPES_REQUEST="DELETE_DASHBOARDTYPES_REQUEST";
export const DELETE_DASHBOARDTYPES_SUCCESS="DELETE_DASHBOARDTYPES_SUCCESS";

export const CREATE_DASHBOARDTYPES_FAILURE="CREATE_DASHBOARDTYPES_FAILURE";
export const CREATE_DASHBOARDTYPES_REQUEST="CREATE_DASHBOARDTYPES_REQUEST";
export const CREATE_DASHBOARDTYPES_SUCCESS="CREATE_DASHBOARDTYPES_SUCCESS";

export const SHOW_DASHBOARDTYPES_EDIT_FORM="SHOW_DASHBOARDTYPES_EDIT_FORM";
export const SHOW_DASHBOARDTYPES_NEW_FORM="SHOW_DASHBOARDTYPES_NEW_FORM";
export const UPDATE_DASHBOARDTYPES_FAILURE="UPDATE_DASHBOARDTYPES_FAILURE";
export const UPDATE_DASHBOARDTYPES_REQUEST="UPDATE_DASHBOARDTYPES_REQUEST";
export const UPDATE_DASHBOARDTYPES_SUCCESS="UPDATE_DASHBOARDTYPES_SUCCESS";
export const SET_DASHBOARDTYPES_VALIDATION_ERRORS="SET_DASHBOARDTYPES_VALIDATION_ERRORS"

export const FETCH_EMP_REQUEST = "FETCH_EMP_REQUEST";
export const FETCH_EMP_SUCCESS = "FETCH_EMP_SUCCESS";
export const FETCH_EMP_FAILURE = "FETCH_EMP_FAILURE";


export const CREATE_EMPATT_FAILURE="CREATE_EMPATT_FAILURE";
export const CREATE_EMPATT_REQUEST="CREATE_EMPATT_REQUEST";
export const CREATE_EMPATT_SUCCESS="CREATE_EMPATT_SUCCESS";


export const CREATE_CLOSETASK_FAILURE="CREATE_CLOSETASK_FAILURE";
export const CREATE_CLOSETASK_REQUEST="CREATE_CLOSETASK_REQUEST";
export const CREATE_CLOSETASK_SUCCESS="CREATE_CLOSETASK_SUCCESS";

export const SHOW_CLOSETASK_EDIT_FORM="SHOW_CLOSETASK_EDIT_FORM";
export const SHOW_CLOSETASK_NEW_FORM="SHOW_CLOSETASK_NEW_FORM";
export const UPDATE_CLOSETASK_FAILURE="UPDATE_CLOSETASK_FAILURE";
export const UPDATE_CLOSETASK_REQUEST="UPDATE_CLOSETASK_REQUEST";
export const UPDATE_CLOSETASK_SUCCESS="UPDATE_CLOSETASK_SUCCESS";
export const SET_CLOSETASK_VALIDATION_ERRORS="SET_CLOSETASK_VALIDATION_ERRORS"

export const RESET_INITIAL_STATE="RESET_INITIAL_STATE";
export const RESET_COMPANY_INITIAL_STATE="RESET_INITIAL_STATE";


export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const SEND_RESET_EMAIL_REQUEST = "SEND_RESET_EMAIL_REQUEST";
export const SEND_RESET_EMAIL_SUCCESS = "SEND_RESET_EMAIL_SUCCESS";
export const SEND_RESET_EMAIL_FAILURE = "SEND_RESET_EMAIL_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";