import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_ITEMS_REQUEST,
  DELETE_ITEMS_REQUEST,
  CREATE_ITEMS_REQUEST,
  UPDATE_ITEMS_REQUEST,
} from "../constants/actions.js";

import { fetchItemsFailure, fetchItemsSuccess,createItemsFailure,
createItemsSuccess,deleteItemsFailure,deleteItemsSuccess,setItemsValidationErrors,updateItemsRequest
,updateItemsFailure,updateItemsSuccess, fetchItemsRequest } from "redux/actions/Items";
import ItemsService from "services/ItemsService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchItems() {
  yield takeLatest(FETCH_ITEMS_REQUEST, function* () {
    try {
      const result = yield call(ItemsService.index);
      if (result.succeeded) {
        yield put(fetchItemsSuccess(result.data));
      } else {
        yield put(fetchItemsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchItemsFailure(error.message));
    }
  });
}



export function* onUpdateItems() {
  yield takeLatest(UPDATE_ITEMS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        ItemsService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
        message.success("Updated");
        yield put(updateItemsSuccess(result.data));
        yield put(fetchItemsRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setItemsValidationErrors(result.data.errors));
        yield put(updateItemsFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateItemsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateItemsFailure(error.message));
    }
  });
}

export function* onDeleteItems() {
  yield takeLatest(DELETE_ITEMS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(ItemsService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteItemsSuccess(result.data));
        yield put(fetchItemsRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteItemsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteItemsFailure(error.message));
    }
  });
}

export function* onCreateItems() {
  yield takeLatest(CREATE_ITEMS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(ItemsService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createItemsSuccess(result.data));
        yield put(fetchItemsRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setItemsValidationErrors(result.data.errors));
        yield put(createItemsFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createItemsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteItemsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchItems),
    fork(onUpdateItems),
    fork(onDeleteItems),
    fork(onCreateItems),
  ]);
}