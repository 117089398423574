import fetch from "auth/FetchInterceptor";

const LookupsService = {};

LookupsService.index = function (lookuptypes) {
  let lookuptype="";
  if(lookuptypes!=null){
    lookuptype= `?lookuptype=${lookuptypes}` 
  }
  return fetch({
    url:
    `v1/Lookup/GetLookup${lookuptype}` ,
    method: "get",
  });
};




LookupsService.create = function (data) {
  return fetch({
    url: "v1/Lookup/PostLookup",
    method: "post",
    data,
  });
};


LookupsService.update = function (id, data) {
  return fetch({
    url: `v1/Lookup/PutLookup${id}`,
    method: "put",
    data,
  });
};

LookupsService.delete = function (id) {
  return fetch({
    url: `v1/Lookup/DeleteLookup?id=${id}`,
    method: "delete",
  });
};

export default LookupsService;