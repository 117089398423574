import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR, SIDE_NAV_DARK, SIDE_NAV_COLLAPSED_WIDTH } from 'constants/ThemeConstant';
import { SIDE_NAV_STYLE_CHANGE } from 'redux/constants/Theme';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'GETNET';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const UPDATE_ACTION = "update";
export const NEW_ACTION = "new";

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#f88280',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};

//the below expiration time is in hour
export const TOKEN_EXPIRATION_TIME = 1;
export const UNPROCESSABLE_ENTITY_CODE = 422;
export const PERMISSION_DENIED_CODE = 403;