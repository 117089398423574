import fetch from "auth/FetchInterceptor";

const AdressesService = {};

AdressesService.index = function () {
  return fetch({
    url:
     "v1/Address" ,
    method: "get",
  });
};

AdressesService.addresslist = function () {
  return fetch({
    url:
     "v1/Address/GetAddressList" ,
    method: "get",
  });
};

AdressesService.create = function (data) {
  return fetch({
    url: "v1/Address",
    method: "post",
    data,
  });
};


AdressesService.update = function (id, data) {
  return fetch({
    url: `v1/Address/${id}`,
    method: "put",
    data,
  });
};

AdressesService.delete = function (id) {
  return fetch({
    url: `v1/Address/${id}`,
    method: "delete",
  });
};

export default AdressesService;