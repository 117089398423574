import fetch from "auth/FetchInterceptor";

const ItemsService = {};

ItemsService.index = function () {
  return fetch({
    url:
     "v1/Item?PageNumber=1&PageSize=200" ,
    method: "get",
  });
};


ItemsService.create = function (data) {
  return fetch({
    url: "v1/Item",
    method: "post",
    data,
  });
};


ItemsService.update = function (id, data) {
  return fetch({
    url: `v1/Item/${id}`,
    method: "put",
    data,
  });
};

ItemsService.delete = function (id) {
  return fetch({
    url: `v1/Item/${id}`,
    method: "delete",
  });
};

export default ItemsService;