import fetch from "auth/FetchInterceptor";

const GaugesService = {};

GaugesService.index = function (payload) {

  var filter="";
  if(payload.SnapshotStartDate!=null  && payload.SnapshotEndDate!=null)
  {
    if(filter!="")
    {
      filter=filter+"&"
    }
    filter=filter+`SnapshotStartDate=${payload.SnapshotStartDate}&SnapshotEndDate=${payload.SnapshotEndDate}`;
  }

  if(payload.CompanyCode!=null )
  {
    if(filter!="")
    {
      filter=filter+"&"
    }
    filter=filter+`CompanyCode=${payload.CompanyCode}`;
  }
  return fetch({
    url:
     `v1/Gauge?${filter}`,
    method: "get",
  });
};


GaugesService.create = function (data) {
  return fetch({
    url: "v1/Gauge",
    method: "post",
    data,
  });
};

GaugesService.Upload = function (data) {
  return fetch({
    url: "v1/Gauge/upload",
    method: "post",
    data,
  });
};

GaugesService.update = function (id, data) {
  return fetch({
    url: `v1/Gauge/${id}`,
    method: "put",
    data,
  });
};

GaugesService.delete = function (id) {
  return fetch({
    url: `v1/Gauge/${id}`,
    method: "delete",
  });
};

GaugesService.getbyMachineId = function (machineid) {
  return fetch({
    url: `v1/Gauge/${machineid}`,
    method: "get",
  });
};
export default GaugesService;