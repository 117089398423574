import _ from "lodash";

export const addVoucher = (state, action) => {
 
  let list = state.list;
  const savedOrders = action.payload;
  return [savedOrders, ...list];

  //   const isFound = state.list.find((item) => item.Id ===  action.payload.Id)
  //   if(isFound)
  //   {
  //       isFound.Quantity= isFound.Quantity+1;
  //       isFound.Price += parseFloat(isFound.Price);
  //       isFound.subTotal += isFound.subTotal;
  //       isFound.Tax += isFound.Tax;
  //       isFound.GTotal += isFound.GTotal;
  //   }
  //   else{
  //   state.list.push(action.payload);
  //  }
  //  return state.list;
    //let list = state.list;
  //const savedLookups = action.payload;
  //return [savedLookups, ...list];
};

 

export const removeVoucher = (state, action) => {
  let list = state.list;
  const deletedVoucher = action.payload;
  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedVoucher.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];
  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });
  return errors;
};