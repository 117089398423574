import { 
  DashboardOutlined,UsergroupAddOutlined ,UserOutlined,DatabaseOutlined ,FolderOpenOutlined,InsertRowAboveOutlined,EnvironmentOutlined,
  SettingOutlined,MoneyCollectOutlined,FieldTimeOutlined ,CalendarOutlined,
  DesktopOutlined ,InsertRowLeftOutlined,TeamOutlined ,FileSearchOutlined,FormOutlined,LockOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

import { DoesThisUserHasAPermissionToThisComponent, DoesThisUserHasARoleToThisComponent } from "utils/PermissioncheCheking";
import { CURRENT_USER } from "redux/constants/Auth";
import {ADMIN,CUSTOMER,Default_NO_ROLE,INVENTORY,STOREkEEPER,TECHNICHIAN} from "./UserRoles";

const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
const role = currentUser?.roles;


const dashBoardNavTree = [
  ...(
    (
      DoesThisUserHasARoleToThisComponent(ADMIN)
  )
  ? ([{
  key: 'dashboard',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'Dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: []
}]) : []),
]

const usermanagmentNavTree=[
  ...(
    (DoesThisUserHasARoleToThisComponent(ADMIN)
  
 )
  ? ([{
  
            key: 'users',
            path: `${APP_PREFIX_PATH}/users`,
            title: 'Users',
            icon: UserOutlined ,
            breadcrumb: true,
            submenu: []
          }]) : [])
          ,
          ...(
            (
              DoesThisUserHasARoleToThisComponent(ADMIN)
          ||  DoesThisUserHasARoleToThisComponent(TECHNICHIAN)
          ||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
          ||DoesThisUserHasARoleToThisComponent(CUSTOMER)
          ||DoesThisUserHasARoleToThisComponent(Default_NO_ROLE)
         )
          ? ([{
                    key: 'changepassword',
                    path: `${APP_PREFIX_PATH}/change-password`,
                    title: 'Change Password',
                    icon: LockOutlined ,
                    breadcrumb: true,
                    submenu: []
                  }]) : [])
  
]



const masterDataNavtree=[
   
  ...(
    (
      DoesThisUserHasARoleToThisComponent(ADMIN)
  ||  DoesThisUserHasARoleToThisComponent(TECHNICHIAN)
  ||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
  ||DoesThisUserHasARoleToThisComponent(CUSTOMER)
 )
  ? ([{
    key: 'workArea',
    // path: `${APP_PREFIX_PATH}`,
    title: 'Work Area',
    icon: UsergroupAddOutlined,
    breadcrumb: true,
    type:'group',
    submenu: [
      ...(
        (
          DoesThisUserHasARoleToThisComponent(ADMIN)

      )
      ? ([{
        key: 'masterdata',
        // path: `${APP_PREFIX_PATH}`,
        title: 'Master Data',
        icon: DatabaseOutlined  ,
        breadcrumb: true,
        submenu: [
          // {
          //   key: 'employees',
          //   path: `${APP_PREFIX_PATH}/employees`,
          //   title: 'Employees',
          //   icon: TeamOutlined  ,
          //   breadcrumb: true,
          //   submenu: []
          // },
          {
            key: 'address',
            path: `${APP_PREFIX_PATH}/address`,
            title: 'Address',
            icon: InsertRowLeftOutlined  ,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'company',
            path: `${APP_PREFIX_PATH}/company`,
            title: 'Company',
            icon: InsertRowLeftOutlined  ,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'machines',
            path: `${APP_PREFIX_PATH}/machines`,
            title: 'Machines',
            icon: DesktopOutlined ,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'items',
            path: `${APP_PREFIX_PATH}/items`,
            title: 'Spare Items',
            icon: FolderOpenOutlined ,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'lookups',
            path: `${APP_PREFIX_PATH}/lookups`,
            title: 'Lookups',
            icon: FileSearchOutlined   ,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'feedbacks',
            path: `${APP_PREFIX_PATH}/feedbacks`,
            title: 'Feedbacks',
            icon: FormOutlined  ,
            breadcrumb: true,
            submenu: []
          },
        ],
      }]) : [])
      ,
      ...(
        (
          DoesThisUserHasARoleToThisComponent(ADMIN)
          ||DoesThisUserHasARoleToThisComponent(CUSTOMER)
          ||DoesThisUserHasARoleToThisComponent(TECHNICHIAN)
          ||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
      )
      ? ([{
        key: 'operational',
        // path: `${APP_PREFIX_PATH}/users`,
        title: 'Operational',
        icon: InsertRowAboveOutlined ,
        breadcrumb: true,
        submenu: [
          ...(
            (
              DoesThisUserHasARoleToThisComponent(ADMIN)
          )
          ? ([{
            key: 'location',
            path: `${APP_PREFIX_PATH}/employeelocation`,
            title: 'Employee Location',
            icon:EnvironmentOutlined  ,
            breadcrumb: true,
            submenu: []
          }]) : []),
          ...(
            (
              DoesThisUserHasARoleToThisComponent(ADMIN)
              ||DoesThisUserHasARoleToThisComponent(CUSTOMER)
              ||DoesThisUserHasARoleToThisComponent(TECHNICHIAN)
          )
          ? ([{
            key: 'service',
            path: `${APP_PREFIX_PATH}/servicerequest`,
            title: 'Service Request',
            icon: SettingOutlined  ,
            breadcrumb: true,
            submenu: []
          }]) : []),
          ...(
            (
              DoesThisUserHasARoleToThisComponent(ADMIN)
              ||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
          )
          ? ([{
            key: 'costbuildup',
            path: `${APP_PREFIX_PATH}/costbuildups`,
            title: 'Service Cost',
            icon: MoneyCollectOutlined ,
            breadcrumb: true,
            submenu: []
          }]) : []),
          ...(
            (
              DoesThisUserHasARoleToThisComponent(ADMIN)
              ||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
          )
          ? ([{
            key: 'gauge',
            path: `${APP_PREFIX_PATH}/gauges`,
            title: 'Counter',
            icon: FieldTimeOutlined  ,
            breadcrumb: true,
            submenu: []
          }]) : []),
          ...(
            (
              DoesThisUserHasARoleToThisComponent(ADMIN,) 
              // ||
              // DoesThisUserHasARoleToThisComponent(TECHNICHIAN)||
              // DoesThisUserHasARoleToThisComponent(STOREkEEPER)
          )
          ? ([{
            key: 'attendance',
            path: `${APP_PREFIX_PATH}/attendance`,
            title: 'Attendance',
            icon: CalendarOutlined ,
            breadcrumb: true,
            submenu: []
          }]) : []),
          ...(
            (
              DoesThisUserHasARoleToThisComponent(ADMIN,) ||
              DoesThisUserHasARoleToThisComponent(TECHNICHIAN)||
              DoesThisUserHasARoleToThisComponent(STOREkEEPER)
          )
          ? ([{
            key: 'clockin',
            path: `${APP_PREFIX_PATH}/clockin`,
            title: 'Clock In',
            icon: CalendarOutlined ,
            breadcrumb: true,
            submenu: []
          }]) : [])
          
        ]
      }]) : [])
,    ...(
  (
    DoesThisUserHasARoleToThisComponent(ADMIN)||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
)
? ([{
  key: 'inventory',
  // path: `${APP_PREFIX_PATH}`,
  title: 'Inventory',
  icon: DatabaseOutlined  ,
  breadcrumb: true,
  submenu: [
    ...(
      (
        DoesThisUserHasARoleToThisComponent(ADMIN)
        ||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
    )
    ? ([{
      key: 'storeIssueVoucher',
      path: `${APP_PREFIX_PATH}/storeissuevoucher`,
      title: 'StoreIssueVoucher',
      icon: InsertRowLeftOutlined  ,
      breadcrumb: true,
      submenu: []
    }]) : []),
    ...(
      (
        DoesThisUserHasARoleToThisComponent(ADMIN)||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
    )
    ? ([{
      key: 'goodsReceivingVoucher',
      path: `${APP_PREFIX_PATH}/goodsreceivingvoucher`,
      title: 'GoodsReceivingVoucher',
      icon: DesktopOutlined ,
      breadcrumb: true,
      submenu: []
    }]) : []),
  
    
    
    ...(
      (
        DoesThisUserHasARoleToThisComponent(ADMIN)||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
    )
    ? ([{
      key: 'beginningBalance',
      path: `${APP_PREFIX_PATH}/beginningbalance`,
      title: 'Beginning Balance',
      icon: FolderOpenOutlined ,
      breadcrumb: true,
      submenu: [] }]) : []),
    ...(
      (
        DoesThisUserHasARoleToThisComponent(ADMIN)||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
    )
    ? ([{
      key: 'stokebalance',
      path: `${APP_PREFIX_PATH}/stokebalance`,
      title: 'Stoke Balance',
      icon: InsertRowAboveOutlined ,
      breadcrumb: true,
      submenu: []
    }]) : []),
    ...(
      (
        DoesThisUserHasARoleToThisComponent(ADMIN)||DoesThisUserHasARoleToThisComponent(STOREkEEPER)
    )
    ? ([{
      key: 'viewdocument',
      path: `${APP_PREFIX_PATH}/viewdocument`,
      title: 'View Document',
      icon: MoneyCollectOutlined ,
      breadcrumb: true,
      submenu: []
    }]) : []),
  ],
  
}]) : [])

    ]
  }]) : []),
]



const navigationConfig = [
  ...dashBoardNavTree,
  ...usermanagmentNavTree,
  ...masterDataNavtree,
  // ...operationalNavTree
]

export default navigationConfig;
