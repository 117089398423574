import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_COSTBUILDUPS_REQUEST,
  DELETE_COSTBUILDUPS_REQUEST,
  CREATE_COSTBUILDUPS_REQUEST,
  UPDATE_COSTBUILDUPS_REQUEST,
} from "../constants/actions.js";

import { fetchCostbuildupsFailure, fetchCostbuildupsSuccess,createCostbuildupsFailure,
createCostbuildupsSuccess,deleteCostbuildupsFailure,deleteCostbuildupsSuccess,setCostbuildupsValidationErrors,updateCostbuildupsRequest
,updateCostbuildupsFailure,updateCostbuildupsSuccess, fetchCostbuildupsRequest } from "redux/actions/Costbuildups";
import CostbuildupsService from "services/CostbuildupsService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchCostbuildups() {
  yield takeLatest(FETCH_COSTBUILDUPS_REQUEST, function* () {
    try {
      const result = yield call(CostbuildupsService.index);
      if (result.succeeded) {
        yield put(fetchCostbuildupsSuccess(result.data));
      } else {
        yield put(fetchCostbuildupsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchCostbuildupsFailure(error.message));
    }
  });
}



export function* onUpdateCostbuildups() {
  yield takeLatest(UPDATE_COSTBUILDUPS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        CostbuildupsService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateCostbuildupsSuccess(result.data));
        yield put(fetchCostbuildupsRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setCostbuildupsValidationErrors(result.data.errors));
        yield put(updateCostbuildupsFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateCostbuildupsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateCostbuildupsFailure(error.message));
    }
  });
}

export function* onDeleteCostbuildups() {
  yield takeLatest(DELETE_COSTBUILDUPS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CostbuildupsService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteCostbuildupsSuccess(result.data));
        yield put(fetchCostbuildupsRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteCostbuildupsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteCostbuildupsFailure(error.message));
    }
  });
}

export function* onCreateCostbuildups() {
  yield takeLatest(CREATE_COSTBUILDUPS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CostbuildupsService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createCostbuildupsSuccess(result.data));
        yield put(fetchCostbuildupsRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setCostbuildupsValidationErrors(result.data.errors));
        yield put(createCostbuildupsFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createCostbuildupsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteCostbuildupsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchCostbuildups),
    fork(onUpdateCostbuildups),
    fork(onDeleteCostbuildups),
    fork(onCreateCostbuildups),
  ]);
}