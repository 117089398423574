import lookups from "redux/reducers/Lookups.js";
import {
    FETCH_LOOKUPS_FAILURE,
    FETCH_LOOKUPS_REQUEST,
    FETCH_LOOKUPS_SUCCESS,
    DELETE_LOOKUPS_FAILURE,
    DELETE_LOOKUPS_REQUEST,
    DELETE_LOOKUPS_SUCCESS,
    CREATE_LOOKUPS_FAILURE,
    CREATE_LOOKUPS_REQUEST,
    CREATE_LOOKUPS_SUCCESS,
    SHOW_LOOKUPS_EDIT_FORM,
    SHOW_LOOKUPS_NEW_FORM,
    UPDATE_LOOKUPS_FAILURE,
    UPDATE_LOOKUPS_REQUEST,
    UPDATE_LOOKUPS_SUCCESS,
    SET_LOOKUPS_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchLookupsRequest = (lookuptypes) => ({
    type: FETCH_LOOKUPS_REQUEST,
    payload:lookuptypes
  });
  
  export const fetchLookupsSuccess = (lookups) => ({
    type: FETCH_LOOKUPS_SUCCESS,
    payload: lookups,
  });
  
  export const fetchLookupsFailure = (error) => ({
    type: FETCH_LOOKUPS_FAILURE,
    payload: error,
  });

  export const setLookupsValidationErrors = (validationErrors) => ({
    type: SET_LOOKUPS_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleLookupsEditForm = () => ({
    type: SHOW_LOOKUPS_EDIT_FORM,
  });
  
  export const toggleLookupsNewForm = () => ({
    type: SHOW_LOOKUPS_NEW_FORM,
  });
  
  export const updateLookupsRequest = (lookups) => ({
    type: UPDATE_LOOKUPS_REQUEST,
    payload: lookups,
  });
  
  export const updateLookupsSuccess = (lookups) => ({
    type: UPDATE_LOOKUPS_SUCCESS,
    payload: lookups,
  });
  
  export const updateLookupsFailure = (error) => ({
    type: UPDATE_LOOKUPS_FAILURE,
    payload: error,
  });
  
  export const deleteLookupsRequest = (id) => ({
    type: DELETE_LOOKUPS_REQUEST,
    payload: id,
  });
  
  export const deleteLookupsSuccess = (lookups) => ({
    type: DELETE_LOOKUPS_SUCCESS,
    payload: lookups,
  });
  
  export const deleteLookupsFailure = (error) => ({
    type: DELETE_LOOKUPS_FAILURE,
    payload: error,
  });
  
  export const createLookupsRequest = (lookups) => ({
    type: CREATE_LOOKUPS_REQUEST,
    payload: lookups,
  });
  
  export const createLookupsSuccess = (lookups) => ({
    type: CREATE_LOOKUPS_SUCCESS,
    payload: lookups,
  });
  
  export const createLookupsFailure = (error) => ({
    type: CREATE_LOOKUPS_FAILURE,
    payload: error,
  });

  

