import fetch from "auth/FetchInterceptor";

const CompaniesService = {};

CompaniesService.index = function (companies) {
  let company="";

  if(companies.PageNumber!=null && companies.PageNumber!==null){
    if(company!=""){
      company=+"&";
    }
    company=+ `PageNumber=${companies.PageNumber}&PageSize=${companies.PageSize}` 
  }
  
  if(companies.ParentId==null){
    if(company!=""){
      company=+"&"; }
      // company= +"";
   
  }
  // if(companies.ParentId==0){
  //   if(company!=""){
  //     company=+"&"; }
  //   company=+ `ParentId=${0}` ;
  // }
  if(companies.ParentId!=null){
    if(company!=""){
      company=+"&"; }
    company=`ParentId=${companies.ParentId}` ;
  }

return fetch({
    url:
     `v1/Company?${company}` ,
    method: "get",
  });
};


CompaniesService.create = function (data) {
  return fetch({
    url: "v1/Company",
    method: "post",
    data,
  });
};
CompaniesService.getAllCompany = function () {
  return fetch({
    url:
     "v1/Company/GetAllCompany" ,
    method: "get",
  });
};


CompaniesService.update = function (id, data) {
  return fetch({
    url: `v1/Company/${id}`,
    method: "put",
    data,
  });
};

CompaniesService.delete = function (id) {
  return fetch({
    url: `v1/Company/${id}`,
    method: "delete",
  });
};

export default CompaniesService;