import _ from "lodash";

export const addCostbuildups = (state, action) => {
  let list = state.list;
  const savedCostbuildups = action.payload;

  return [savedCostbuildups, ...list];
};

export const updateCostbuildups = (state, action) => {
  let list = state.list;
  const updatedCostbuildups = action.payload;

  const index = _.findIndex(list, ["id", updatedCostbuildups.id]);
  list[index] = updatedCostbuildups;

  return [...list];
};

export const removeCostbuildups = (state, action) => {
  let list = state.list;
  const deletedCostbuildups = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedCostbuildups.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};