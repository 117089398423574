import React from "react";
import { Route } from "react-router-dom";
import { CURRENT_USER } from "redux/constants/Auth";
import { getCurrentRoleName } from "utils/PermissioncheCheking";
import UnAuthorizedPage from "views/unauthorised";

const GuardedRoute = ({ component: Component, role, redirect, ...rest }) => {
    const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
    const roles = currentUser?.roles;
    const isAuthorized = roles?.some(rt =>  role?.indexOf(rt) !==-1 )
    return <Route {...rest} exact render={(props) => (isAuthorized ? <Component {...props} /> : <UnAuthorizedPage />)} />;
};

export default GuardedRoute;
