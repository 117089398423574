import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_ORDERS_REQUEST,
  DELETE_ORDERS_REQUEST,
  CREATE_ORDERS_REQUEST,
  UPDATE_ORDERS_REQUEST,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_CLOSETASK_REQUEST,
} from "../constants/actions.js";

import { fetchOrdersFailure, fetchOrdersSuccess,createOrdersFailure,
createOrdersSuccess,deleteOrdersFailure,deleteOrdersSuccess,setOrdersValidationErrors,updateOrdersRequest
,updateOrdersFailure,updateOrdersSuccess, fetchOrdersRequest, createAssignmentSuccess, setAssignmentValidationErrors, createAssignmentFailure, createCloseTaskFailure, setCloseTaskValidationErrors } from "redux/actions/Orders";
import OrdersService from "services/OrdersService";
 
export function* onFetchOrders() {
  yield takeLatest(FETCH_ORDERS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(OrdersService.index,payload);
      if (result.succeeded) {
        yield put(fetchOrdersSuccess(result.data));
   
      } else {
        yield put(fetchOrdersFailure(result.message));
      }
    } catch (error) {
      yield put(fetchOrdersFailure(error.message));
    }
  });
}



export function* onUpdateOrders() {
  yield takeLatest(UPDATE_ORDERS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        OrdersService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateOrdersSuccess(result.data));
        yield put(fetchOrdersRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setOrdersValidationErrors(result.data.errors));
        yield put(updateOrdersFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateOrdersFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateOrdersFailure(error.message));
    }
  });
}

export function* onDeleteOrders() {
  yield takeLatest(DELETE_ORDERS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(OrdersService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteOrdersSuccess(result.data));
        yield put(fetchOrdersRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteOrdersFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteOrdersFailure(error.message));
    }
  });
}

export function* onCreateOrders() {
  yield takeLatest(CREATE_ORDERS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(OrdersService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createOrdersSuccess(result.data));
        yield put(fetchOrdersRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setOrdersValidationErrors(result.data.errors));
        yield put(createOrdersFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createOrdersFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteOrdersFailure(error.message));
    }
  });
}

export function* onAssignOrders() {
  yield takeLatest(CREATE_ASSIGNMENT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(OrdersService.assign, payload);
      if (result.succeeded) {
        message.success("Assigned");
        yield put(createAssignmentSuccess(result.data));
        yield put(fetchOrdersRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setAssignmentValidationErrors(result.data.errors));
        yield put(createAssignmentFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createAssignmentFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(createAssignmentFailure(error.message));
    }
  });
}




export function* onUpdateAssignedTask() {
  yield takeLatest(CREATE_CLOSETASK_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        OrdersService.updateTask,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Task Status Updated");
        yield put(fetchOrdersRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setCloseTaskValidationErrors(result.data.errors));
        yield put(updateOrdersFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createCloseTaskFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(createCloseTaskFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onAssignOrders),
    fork(onFetchOrders),
    fork(onUpdateOrders),
    fork(onDeleteOrders),
    fork(onCreateOrders),
    fork(onUpdateAssignedTask)
  ]);
}