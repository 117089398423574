import {
  FETCH_COSTBUILDUPS_FAILURE,
  FETCH_COSTBUILDUPS_REQUEST,
  FETCH_COSTBUILDUPS_SUCCESS,
  DELETE_COSTBUILDUPS_FAILURE,
  DELETE_COSTBUILDUPS_REQUEST,
  DELETE_COSTBUILDUPS_SUCCESS,
  CREATE_COSTBUILDUPS_FAILURE,
  CREATE_COSTBUILDUPS_REQUEST,
  CREATE_COSTBUILDUPS_SUCCESS,
  SHOW_COSTBUILDUPS_EDIT_FORM,
  SHOW_COSTBUILDUPS_NEW_FORM,
  UPDATE_COSTBUILDUPS_FAILURE,
  UPDATE_COSTBUILDUPS_REQUEST,
  UPDATE_COSTBUILDUPS_SUCCESS,
  SET_COSTBUILDUPS_VALIDATION_ERRORS
} from "../constants/actions.js";
import {
  addCostbuildups,
  formtValidationErrors,
  removeCostbuildups,
  updateCostbuildups,
} from "utils/Costbuildups";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const costbuildups = (state = initState, action) => {
  switch (action.type) {
    case FETCH_COSTBUILDUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COSTBUILDUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_COSTBUILDUPS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_COSTBUILDUPS_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_COSTBUILDUPS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_COSTBUILDUPS_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_COSTBUILDUPS_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_COSTBUILDUPS_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateCostbuildups(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_COSTBUILDUPS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_COSTBUILDUPS_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_COSTBUILDUPS_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeCostbuildups(state, action),
      };
    case DELETE_COSTBUILDUPS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_COSTBUILDUPS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_COSTBUILDUPS_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addCostbuildups(state, action),
      };
    case CREATE_COSTBUILDUPS_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default costbuildups;