import _ from "lodash";

export const addItems = (state, action) => {
  let list = state.list;
  const savedItems = action.payload;

  return [savedItems, ...list];
};

export const updateItems = (state, action) => {
  let list = state.list;
  const updatedItems = action.payload;

  const index = _.findIndex(list, ["id", updatedItems.id]);
  list[index] = updatedItems;

  return [...list];
};

export const removeItems = (state, action) => {
  let list = state.list;
  const deletedItems = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedItems.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};