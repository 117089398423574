import fetch from "auth/FetchInterceptor";

const FeedbacksService = {};

FeedbacksService.index = function () {
  return fetch({
    url:
     "v1/Feedback?PageNumber=1&PageSize=200" ,
    method: "get",
  });
};


FeedbacksService.create = function (data) {
  return fetch({
    url: "v1/Feedback",
    method: "post",
    data,
  });
};


FeedbacksService.update = function (id, data) {
  return fetch({
    url: `v1/Feedback/${id}`,
    method: "put",
    data,
  });
};

FeedbacksService.delete = function (id) {
  return fetch({
    url: `v1/Feedback/${id}`,
    method: "delete",
  });
};

export default FeedbacksService;