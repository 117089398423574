import _ from "lodash";

export const addGauges = (state, action) => {
  let list = state.list;
  const savedGauges = action.payload;
  return [savedGauges, ...list];
};

export const updateGauges = (state, action) => {
  let list = state.list;
  const updatedGauges = action.payload;

  const index = _.findIndex(list, ["id", updatedGauges.id]);
  list[index] = updatedGauges;

  return [...list];
};

export const removeGauges = (state, action) => {
  let list = state.list;
  const deletedGauges = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedGauges.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};