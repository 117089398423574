import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
  // ADMIN_PREFIX_PATH,
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  // QC_PREFIX_PATH,
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
// import { AdminLayout } from "layouts/admin-layout";
import { CURRENT_USER } from "redux/constants/Auth";
import ErrorOne from "./auth-views/errors/error-page-1";
import Home from "./app-views/home";
// import QualityControlViews from "./quality-control-views";
// import { QualityControlLayout } from "layouts/quality-control-layout";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  console.log(isAuthenticated);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  const currentUser = JSON.parse(localStorage.getItem(CURRENT_USER));
  const role = currentUser?.role;

  let NAV_PATH = APP_PREFIX_PATH;
  // role === "super-admin" ? ADMIN_PREFIX_PATH : APP_PREFIX_PATH;
  // switch (role) {
  //   case "super-admin":
  //     NAV_PATH = ADMIN_PREFIX_PATH;
  //     break;
  //   case "qc-admin":
  //     NAV_PATH = QC_PREFIX_PATH;
  //     break;
  //   default:
  //     NAV_PATH = APP_PREFIX_PATH;
  // }

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact  path="/">
            <Redirect to={`${APP_PREFIX_PATH}/home`} element={<Home />}/>
            {/* <Route path="*" component={ErrorOne} /> */}
              
          </Route>
          <Route  path={AUTH_PREFIX_PATH}>
            
            <AuthLayout direction={direction} />
            {/* <Route path="*" component={ErrorOne} /> */}
              
          </Route>
         
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location} />
          </RouteInterceptor>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout
              navType="SIDE"
              direction={direction}
              location={location}
            />
          </RouteInterceptor>
    
        </Switch>
     
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, direction, token };
};

export default withRouter(connect(mapStateToProps)(Views));
