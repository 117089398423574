import {
	all,
	takeEvery,
	put,
	fork,
	call,
	takeLatest,
  } from "redux-saga/effects";
  import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNOUT,
	SIGNUP,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK,
	SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
	SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST,
	CURRENT_USER,
  } from "../constants/Auth";
  import {
	showAuthMessage,
	authenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated,
	signUpWithEmailAndPasswordFailure,
  } from "../actions/Auth";
  
  import FirebaseService from "services/FirebaseService";
  import JwtAuthService from "services/JwtAuthService";
  
  export function* signInWithFBEmail() {
	yield takeEvery(SIGNIN, function* ({ payload }) {
	  const { email, password } = payload;
	  try {
		const user = yield call(
		  FirebaseService.signInEmailRequest,
		  email,
		  password
		);
		if (user.message) {
		  yield put(showAuthMessage(user.message));
		} else {
		  localStorage.setItem(AUTH_TOKEN, user.user.uid);
		  yield put(authenticated(user.user.uid));
		}
	  } catch (err) {
		yield put(showAuthMessage(err));
	  }
	});
  }
  
  export function* signOut() {
	yield takeEvery(SIGNOUT, function* () {
	  try {
		const signOutUser = yield call(FirebaseService.signOutRequest);
		if (signOutUser === undefined) {
		  localStorage.removeItem(AUTH_TOKEN);
		  localStorage.removeItem(CURRENT_USER);
		  yield put(signOutSuccess(signOutUser));
		} else {
		  yield put(showAuthMessage(signOutUser.message));
		}
	  } catch (err) {
		yield put(showAuthMessage(err));
	  }
	});
  }
  
  export function* signUpWithFBEmail() {
	yield takeEvery(SIGNUP, function* ({ payload }) {
	  const { email, password } = payload;
	  try {
		const user = yield call(
		  FirebaseService.signUpEmailRequest,
		  email,
		  password
		);
		if (user.message) {
		  yield put(showAuthMessage(user.message));
		} else {
		  localStorage.setItem(AUTH_TOKEN, user.user.uid);
		  yield put(signUpSuccess(user.user.uid));
		}
	  } catch (error) {
		yield put(showAuthMessage(error));
	  }
	});
  }
  
  export function* signInWithFBGoogle() {
	yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
	  try {
		const user = yield call(FirebaseService.signInGoogleRequest);
		if (user.message) {
		  yield put(showAuthMessage(user.message));
		} else {
		  localStorage.setItem(AUTH_TOKEN, user.user.uid);
		  yield put(signInWithGoogleAuthenticated(user.user.uid));
		}
	  } catch (error) {
		yield put(showAuthMessage(error));
	  }
	});
  }
  
  export function* signInWithFacebook() {
	yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
	  try {
		const user = yield call(FirebaseService.signInFacebookRequest);
		if (user.message) {
		  yield put(showAuthMessage(user.message));
		} else {
		  localStorage.setItem(AUTH_TOKEN, user.user.uid);
		  yield put(signInWithFacebookAuthenticated(user.user.uid));
		}
	  } catch (error) {
		yield put(showAuthMessage(error));
	  }
	});
  }
  
  export function* onSignInWithEmailAndPassword() {
	yield takeLatest(
	  SIGN_IN_WITH_EMAIL_AND_PASSWORD_REQUEST,
	  function* ({ payload }) {
		try {
		  const result = yield call(JwtAuthService.login, payload);
		  if (!result.succeeded) {
			yield put(showAuthMessage(result.message));
		  } else {
			localStorage.setItem(AUTH_TOKEN, result.data.jwToken);
			localStorage.setItem(CURRENT_USER, JSON.stringify(result.data));
			// yield put(authenticated(result.data));
			window.location.reload(true);
		  }
		} catch (error) {
		  // console.log(error.response.data);
		}
	  }
	);
  }
  
  export function* onSignUpWithEmailAndPassword() {
	yield takeLatest(
	  SIGN_UP_WITH_EMAIL_AND_PASSWORD_REQUEST,
	  function* ({ payload }) {
		try {
		  yield put(signUpWithEmailAndPasswordFailure(null))
		  const result = yield call(JwtAuthService.register, payload);
		  if (!result.succeeded) {
			yield put(showAuthMessage(result.message));
			if (result.code === 422) {
				yield put(signUpWithEmailAndPasswordFailure(result.data.errors))
			}
		  } else {
			localStorage.setItem(AUTH_TOKEN, result.data.jwToken);
			localStorage.setItem(CURRENT_USER, JSON.stringify(result.data));
			yield put(authenticated(result.data));
		  }
		} catch (error) {
		  yield console.log(error);
		}
	  }
	);
  }
  
  export default function* rootSaga() {
	yield all([
	  fork(signInWithFBEmail),
	  fork(signOut),
	  fork(signUpWithFBEmail),
	  fork(signInWithFBGoogle),
	  fork(signInWithFacebook),
	  fork(onSignInWithEmailAndPassword),
	  fork(onSignUpWithEmailAndPassword),
	]);
  }
  