// import { initializeApp,getFirestore } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD6D-HGTAREfWDhj757sWNY042fy4dV104",
  authDomain: "getnetemployeelocation.firebaseapp.com",
  databaseURL: "https://getnetemployeelocation-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "getnetemployeelocation",
  storageBucket: "getnetemployeelocation.appspot.com",
  messagingSenderId: "250435158619",
  appId: "1:250435158619:web:fc47a5309b28d4df4ab57e"
};


// firebase.initializeApp(firebaseConfig);

// // firebase utils
// export const db = firebase.firestore()
 export default firebaseConfig

// const app = initializeApp(firebaseConfig);

//  export const db = getFirestore(app);