import {
    FETCH_FEEDBACKS_FAILURE,
    FETCH_FEEDBACKS_REQUEST,
    FETCH_FEEDBACKS_SUCCESS,
    DELETE_FEEDBACKS_FAILURE,
    DELETE_FEEDBACKS_REQUEST,
    DELETE_FEEDBACKS_SUCCESS,
    CREATE_FEEDBACKS_FAILURE,
    CREATE_FEEDBACKS_REQUEST,
    CREATE_FEEDBACKS_SUCCESS,
    SHOW_FEEDBACKS_EDIT_FORM,
    SHOW_FEEDBACKS_NEW_FORM,
    UPDATE_FEEDBACKS_FAILURE,
    UPDATE_FEEDBACKS_REQUEST,
    UPDATE_FEEDBACKS_SUCCESS,
    SET_FEEDBACKS_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchFeedbacksRequest = (  
  ) => ({
    type: FETCH_FEEDBACKS_REQUEST
  });
  
  export const fetchFeedbacksSuccess = (feedbacks) => ({
    type: FETCH_FEEDBACKS_SUCCESS,
    payload: feedbacks,
  });
  
  export const fetchFeedbacksFailure = (error) => ({
    type: FETCH_FEEDBACKS_FAILURE,
    payload: error,
  });

  export const setFeedbacksValidationErrors = (validationErrors) => ({
    type: SET_FEEDBACKS_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleFeedbacksEditForm = () => ({
    type: SHOW_FEEDBACKS_EDIT_FORM,
  });
  
  export const toggleFeedbacksNewForm = () => ({
    type: SHOW_FEEDBACKS_NEW_FORM,
  });
  
  export const updateFeedbacksRequest = (feedbacks) => ({
    type: UPDATE_FEEDBACKS_REQUEST,
    payload: feedbacks,
  });
  
  export const updateFeedbacksSuccess = (feedbacks) => ({
    type: UPDATE_FEEDBACKS_SUCCESS,
    payload: feedbacks,
  });
  
  export const updateFeedbacksFailure = (error) => ({
    type: UPDATE_FEEDBACKS_FAILURE,
    payload: error,
  });
  
  export const deleteFeedbacksRequest = (id) => ({
    type: DELETE_FEEDBACKS_REQUEST,
    payload: id,
  });
  
  export const deleteFeedbacksSuccess = (feedbacks) => ({
    type: DELETE_FEEDBACKS_SUCCESS,
    payload: feedbacks,
  });
  
  export const deleteFeedbacksFailure = (error) => ({
    type: DELETE_FEEDBACKS_FAILURE,
    payload: error,
  });
  
  export const createFeedbacksRequest = (feedbacks) => ({
    type: CREATE_FEEDBACKS_REQUEST,
    payload: feedbacks,
  });
  
  export const createFeedbacksSuccess = (feedbacks) => ({
    type: CREATE_FEEDBACKS_SUCCESS,
    payload: feedbacks,
  });
  
  export const createFeedbacksFailure = (error) => ({
    type: CREATE_FEEDBACKS_FAILURE,
    payload: error,
  });

  

