import fetch from "auth/FetchInterceptor";

const GoodsreceivingvoucherService = {};

GoodsreceivingvoucherService.create = function (data) {
    return fetch({
      url: "v1/GoodsReceivingVoucher",
      method: "post",
      data,
    });
  };

  export default GoodsreceivingvoucherService;