import {
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_SUCCESS,
  DELETE_ORDERS_FAILURE,
  DELETE_ORDERS_REQUEST,
  DELETE_ORDERS_SUCCESS,
  CREATE_ORDERS_FAILURE,
  CREATE_ORDERS_REQUEST,
  CREATE_ORDERS_SUCCESS,
  SHOW_ORDERS_EDIT_FORM,
  SHOW_ORDERS_NEW_FORM,
  UPDATE_ORDERS_FAILURE,
  UPDATE_ORDERS_REQUEST,
  UPDATE_ORDERS_SUCCESS,
  SET_ORDERS_VALIDATION_ERRORS,

  CREATE_ASSIGNMENT_FAILURE,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_ASSIGNMENT_SUCCESS,

  SHOW_ASSIGNMENT_NEW_FORM,
  SET_ASSIGNMENT_VALIDATION_ERRORS,
  SHOW_CLOSETASK_NEW_FORM,
  
  SET_CLOSETASK_VALIDATION_ERRORS,
  CREATE_CLOSETASK_FAILURE,
  CREATE_CLOSETASK_SUCCESS,
  CREATE_CLOSETASK_REQUEST,
} from "../constants/actions.js";
import {
  addOrders,
  formtValidationErrors,
  removeOrders,
  updateOrders,
} from "utils/Orders";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  showNewAssignmentForm: false,
  deleting: false,
  showNewCloseTaskForm:false
};

const orders = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_ORDERS_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_ORDERS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_ORDERS_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_ORDERS_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_ORDERS_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateOrders(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_ORDERS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_ORDERS_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_ORDERS_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeOrders(state, action),
      };
    case DELETE_ORDERS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_ORDERS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_ORDERS_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addOrders(state, action),
      };
    case CREATE_ORDERS_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

      ///
      case CREATE_ASSIGNMENT_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case CREATE_ASSIGNMENT_SUCCESS:
        return {
          ...state,
          creating: false,
          showNewAssignmentForm: !state.showNewAssignmentForm,
          // list: addOrders(state, action),
        };
      case CREATE_ASSIGNMENT_FAILURE:
        return {
          ...state,
          creating: false,
          errorMessage: action.payload,
        };
        //
      case SET_ASSIGNMENT_VALIDATION_ERRORS:
        return {
          ...state,
          validationErrors: formtValidationErrors(action.payload),
        };
      case SHOW_ASSIGNMENT_NEW_FORM:
        return {
          ...state,
          showNewAssignmentForm: !state.showNewAssignmentForm,
        };

        ///


        case CREATE_CLOSETASK_REQUEST:
          return {
            ...state,
            creating: true,
          };
        case CREATE_CLOSETASK_SUCCESS:
          return {
            ...state,
            creating: false,
            showNewForm: !state.showNewForm
          };
        case CREATE_CLOSETASK_FAILURE:
          return {
            ...state,
            creating: false,
            errorMessage: action.payload,
          };
          case SET_CLOSETASK_VALIDATION_ERRORS:
            return {
              ...state,
              // validationErrors: formtValidationErrors(action.payload),
            };
          case SHOW_CLOSETASK_NEW_FORM:
            return {
              ...state,
              showNewCloseTaskForm: !state.showNewCloseTaskForm,
            };
    default:
      return state;
  }
};

export default orders;