import { CURRENT_USER } from "redux/constants/Auth";
export const getTheCurrentUserPermissions = () => {
  const currentUser = getParsedCurrentUser();
  return currentUser?.permissions;
};

export const DoesThisUserHasAPermissionToThisComponent = (component_permission) => {
  const user_permissions = getTheCurrentUserPermissions();
  const current_user_permission = user_permissions != undefined ? user_permissions.map( item =>{
     return item.permission_name;
  }) : [];
  return current_user_permission.length <= 0 ? false :  current_user_permission.includes(component_permission, 0);
};

export const DoesThisUserHasARoleToThisComponent = (role) => {
  const user_role = getCurrentRoleName();
  const current_user_role = user_role != undefined ? user_role : [];
  return current_user_role.length <= 0 ? false :  current_user_role.includes(role, 0);
};

export const getCurrentRoleName = () =>{
     return getParsedCurrentUser()?.roles;
}

export const getCurrentRole = () =>{
  return getParsedCurrentUser()?.roles;
}
function getParsedCurrentUser()
{
   return JSON.parse(localStorage.getItem(CURRENT_USER));
}

export const doesThisUserIsGetnetUser =() =>{
  return  (JSON.parse(localStorage.getItem(CURRENT_USER))['companyId']) === 0 ? 1 : 0;
}
export const doesThisUserNotGetnetUser =() =>{
  return  doesThisUserIsGetnetUser() ? 0 : 1;
}
