import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_COMPANIES_REQUEST,
  DELETE_COMPANIES_REQUEST,
  CREATE_COMPANIES_REQUEST,
  UPDATE_COMPANIES_REQUEST,
  FETCH_ALL_COMPANIES_REQUEST,
} from "../constants/actions.js";

import { fetchCompaniesFailure, fetchCompaniesSuccess,createCompaniesFailure,
createCompaniesSuccess,deleteCompaniesFailure,deleteCompaniesSuccess,setCompaniesValidationErrors,updateCompaniesRequest
,updateCompaniesFailure,updateCompaniesSuccess, fetchCompaniesRequest, fetchAllCompaniesSuccess, fetchAllCompaniesFailure } from "redux/actions/Companies";
import CompaniesService from "services/CompaniesService";
import Loading from "components/shared-components/Loading/index.js";

var req={
  ParentId:null
}

export function* onFetchCompanies() {
  yield takeLatest(FETCH_COMPANIES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CompaniesService.index,payload);
      if (result.succeeded) {
        yield put(fetchCompaniesSuccess(result.data));
      } else {
        yield put(fetchCompaniesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchCompaniesFailure(error.message));
    }
  });
}


export function* onFetchAllCompanies() {
  yield takeLatest(FETCH_ALL_COMPANIES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CompaniesService.getAllCompany,payload);
      if (result.succeeded) {
        yield put(fetchAllCompaniesSuccess(result.data));
      } else {
        yield put(fetchAllCompaniesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchAllCompaniesFailure(error.message));
    }
  });
}


export function* onUpdateCompanies() {
  yield takeLatest(UPDATE_COMPANIES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        CompaniesService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateCompaniesSuccess(result.data));
        yield put(fetchCompaniesRequest(req));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setCompaniesValidationErrors(result.data.errors));
        yield put(updateCompaniesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateCompaniesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateCompaniesFailure(error.message));
    }
  });
}

export function* onDeleteCompanies() {
  yield takeLatest(DELETE_COMPANIES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CompaniesService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteCompaniesSuccess(result.data));
        yield put(fetchCompaniesRequest(req));
      } else {
        message.error("Something went wrong");
        yield put(deleteCompaniesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteCompaniesFailure(error.message));
    }
  });
}

export function* onCreateCompanies() {
  yield takeLatest(CREATE_COMPANIES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(CompaniesService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createCompaniesSuccess(result.data));
        yield put(fetchCompaniesRequest(req));
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setCompaniesValidationErrors(result.data.errors));
        yield put(createCompaniesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createCompaniesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteCompaniesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchCompanies),
    fork(onUpdateCompanies),
    fork(onDeleteCompanies),
    fork(onCreateCompanies),
    fork(onFetchAllCompanies)
  ]);
}