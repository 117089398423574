import {
    FETCH_LOOKUPTYPES_FAILURE,
    FETCH_LOOKUPTYPES_REQUEST,
    FETCH_LOOKUPTYPES_SUCCESS,
    DELETE_LOOKUPTYPES_FAILURE,
    DELETE_LOOKUPTYPES_REQUEST,
    DELETE_LOOKUPTYPES_SUCCESS,
    CREATE_LOOKUPTYPES_FAILURE,
    CREATE_LOOKUPTYPES_REQUEST,
    CREATE_LOOKUPTYPES_SUCCESS,
    SHOW_LOOKUPTYPES_EDIT_FORM,
    SHOW_LOOKUPTYPES_NEW_FORM,
    UPDATE_LOOKUPTYPES_FAILURE,
    UPDATE_LOOKUPTYPES_REQUEST,
    UPDATE_LOOKUPTYPES_SUCCESS,
    SET_LOOKUPTYPES_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchLookuptypesRequest = (  
  ) => ({
    type: FETCH_LOOKUPTYPES_REQUEST
  });
  
  export const fetchLookuptypesSuccess = (lookuptypes) => ({
    type: FETCH_LOOKUPTYPES_SUCCESS,
    payload: lookuptypes,
  });
  
  export const fetchLookuptypesFailure = (error) => ({
    type: FETCH_LOOKUPTYPES_FAILURE,
    payload: error,
  });

  export const setLookuptypesValidationErrors = (validationErrors) => ({
    type: SET_LOOKUPTYPES_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleLookuptypesEditForm = () => ({
    type: SHOW_LOOKUPTYPES_EDIT_FORM,
  });
  
  export const toggleLookuptypesNewForm = () => ({
    type: SHOW_LOOKUPTYPES_NEW_FORM,
  });
  
  export const updateLookuptypesRequest = (lookuptypes) => ({
    type: UPDATE_LOOKUPTYPES_REQUEST,
    payload: lookuptypes,
  });
  
  export const updateLookuptypesSuccess = (lookuptypes) => ({
    type: UPDATE_LOOKUPTYPES_SUCCESS,
    payload: lookuptypes,
  });
  
  export const updateLookuptypesFailure = (error) => ({
    type: UPDATE_LOOKUPTYPES_FAILURE,
    payload: error,
  });
  
  export const deleteLookuptypesRequest = (id) => ({
    type: DELETE_LOOKUPTYPES_REQUEST,
    payload: id,
  });
  
  export const deleteLookuptypesSuccess = (lookuptypes) => ({
    type: DELETE_LOOKUPTYPES_SUCCESS,
    payload: lookuptypes,
  });
  
  export const deleteLookuptypesFailure = (error) => ({
    type: DELETE_LOOKUPTYPES_FAILURE,
    payload: error,
  });
  
  export const createLookuptypesRequest = (lookuptypes) => ({
    type: CREATE_LOOKUPTYPES_REQUEST,
    payload: lookuptypes,
  });
  
  export const createLookuptypesSuccess = (lookuptypes) => ({
    type: CREATE_LOOKUPTYPES_SUCCESS,
    payload: lookuptypes,
  });
  
  export const createLookuptypesFailure = (error) => ({
    type: CREATE_LOOKUPTYPES_FAILURE,
    payload: error,
  });

  

