import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_MACHINESLOCATION_REQUEST,
  DELETE_MACHINESLOCATION_REQUEST,
  CREATE_MACHINESLOCATION_REQUEST,
  UPDATE_MACHINESLOCATION_REQUEST,
} from "../constants/actions.js";

import { fetchMachinesLocationFailure, fetchMachinesLocationSuccess,createMachinesLocationFailure,
createMachinesLocationSuccess,deleteMachinesLocationFailure,deleteMachinesLocationSuccess,setMachinesLocationValidationErrors,updateMachinesLocationRequest
,updateMachinesLocationFailure,updateMachinesLocationSuccess, fetchMachinesLocationRequest } from "redux/actions/MachinesLocation";
import MachinesLocationService from "services/MachinesLocationService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchMachinesLocation() {
  yield takeLatest(FETCH_MACHINESLOCATION_REQUEST, function* () {
    try {
      const result = yield call(MachinesLocationService.index);
      if (result.succeeded) {
        yield put(fetchMachinesLocationSuccess(result.data));
      } else {
        yield put(fetchMachinesLocationFailure(result.message));
      }
    } catch (error) {
      yield put(fetchMachinesLocationFailure(error.message));
    }
  });
}



export function* onUpdateMachinesLocation() {
  yield takeLatest(UPDATE_MACHINESLOCATION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        MachinesLocationService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateMachinesLocationSuccess(result.data));
     
        yield put(fetchMachinesLocationRequest());
     
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setMachinesLocationValidationErrors(result.data.errors));
        yield put(updateMachinesLocationFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateMachinesLocationFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateMachinesLocationFailure(error.message));
    }
  });
}

export function* onDeleteMachinesLocation() {
  yield takeLatest(DELETE_MACHINESLOCATION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MachinesLocationService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteMachinesLocationSuccess(result.data));
        yield put(fetchMachinesLocationRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteMachinesLocationFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteMachinesLocationFailure(error.message));
    }
  });
}

export function* onCreateMachinesLocation() {
  yield takeLatest(CREATE_MACHINESLOCATION_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(MachinesLocationService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createMachinesLocationSuccess(result.data));
        yield put(fetchMachinesLocationRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setMachinesLocationValidationErrors(result.data.errors));
        yield put(createMachinesLocationFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createMachinesLocationFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteMachinesLocationFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchMachinesLocation),
    fork(onUpdateMachinesLocation),
    fork(onDeleteMachinesLocation),
    fork(onCreateMachinesLocation),
  ]);
}