import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_LOOKUPTYPES_REQUEST,
  DELETE_LOOKUPTYPES_REQUEST,
  CREATE_LOOKUPTYPES_REQUEST,
  UPDATE_LOOKUPTYPES_REQUEST,
} from "../constants/actions.js";

import { fetchLookuptypesFailure, fetchLookuptypesSuccess,createLookuptypesFailure,
createLookuptypesSuccess,deleteLookuptypesFailure,deleteLookuptypesSuccess,setLookuptypesValidationErrors,updateLookuptypesRequest
,updateLookuptypesFailure,updateLookuptypesSuccess, fetchLookuptypesRequest } from "redux/actions/Lookuptypes";
import LookuptypesService from "services/LookuptypesService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchLookuptypes() {
  yield takeLatest(FETCH_LOOKUPTYPES_REQUEST, function* () {
    try {
      const result = yield call(LookuptypesService.index);
      if (result.succeeded) {
        yield put(fetchLookuptypesSuccess(result.data));
      } else {
        yield put(fetchLookuptypesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchLookuptypesFailure(error.message));
    }
  });
}



export function* onUpdateLookuptypes() {
  yield takeLatest(UPDATE_LOOKUPTYPES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        LookuptypesService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateLookuptypesSuccess(result.data));
        yield put(fetchLookuptypesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setLookuptypesValidationErrors(result.data.errors));
        yield put(updateLookuptypesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateLookuptypesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateLookuptypesFailure(error.message));
    }
  });
}

export function* onDeleteLookuptypes() {
  yield takeLatest(DELETE_LOOKUPTYPES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(LookuptypesService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteLookuptypesSuccess(result.data));
        yield put(fetchLookuptypesRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteLookuptypesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteLookuptypesFailure(error.message));
    }
  });
}

export function* onCreateLookuptypes() {
  yield takeLatest(CREATE_LOOKUPTYPES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(LookuptypesService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createLookuptypesSuccess(result.data));
        yield put(fetchLookuptypesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setLookuptypesValidationErrors(result.data.errors));
        yield put(createLookuptypesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createLookuptypesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteLookuptypesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchLookuptypes),
    fork(onUpdateLookuptypes),
    fork(onDeleteLookuptypes),
    fork(onCreateLookuptypes),
  ]);
}