import {
    FETCH_MACHINESLOCATION_FAILURE,
    FETCH_MACHINESLOCATION_REQUEST,
    FETCH_MACHINESLOCATION_SUCCESS,
    DELETE_MACHINESLOCATION_FAILURE,
    DELETE_MACHINESLOCATION_REQUEST,
    DELETE_MACHINESLOCATION_SUCCESS,
    CREATE_MACHINESLOCATION_FAILURE,
    CREATE_MACHINESLOCATION_REQUEST,
    CREATE_MACHINESLOCATION_SUCCESS,
    SHOW_MACHINESLOCATION_EDIT_FORM,
    SHOW_MACHINESLOCATION_NEW_FORM,
    UPDATE_MACHINESLOCATION_FAILURE,
    
    UPDATE_MACHINESLOCATION_REQUEST,
    UPDATE_MACHINESLOCATION_SUCCESS,
    SET_MACHINESLOCATION_VALIDATION_ERRORS
  } from "../constants/actions.js";
  
  export const fetchMachinesLocationRequest = (  
  ) => ({
    type: FETCH_MACHINESLOCATION_REQUEST
  });
  
  export const fetchMachinesLocationSuccess = (machineslocation) => ({
    type: FETCH_MACHINESLOCATION_SUCCESS,
    payload: machineslocation,
  });
  
  export const fetchMachinesLocationFailure = (error) => ({
    type: FETCH_MACHINESLOCATION_FAILURE,
    payload: error,
  });

  export const setMachinesLocationValidationErrors = (validationErrors) => ({
    type: SET_MACHINESLOCATION_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleMachinesLocationEditForm = () => ({
    type: SHOW_MACHINESLOCATION_EDIT_FORM,
  });
  
  export const toggleMachinesLocationNewForm = () => ({
    type: SHOW_MACHINESLOCATION_NEW_FORM,
  });
  
  export const updateMachinesLocationRequest = (machineslocation) => ({
    type: UPDATE_MACHINESLOCATION_REQUEST,
    payload: machineslocation,
  });
  
  export const updateMachinesLocationSuccess = (machineslocation) => ({
    type: UPDATE_MACHINESLOCATION_SUCCESS,
    payload: machineslocation,
  });
  
  export const updateMachinesLocationFailure = (error) => ({
    type: UPDATE_MACHINESLOCATION_FAILURE,
    payload: error,
  });
  
  export const deleteMachinesLocationRequest = (id) => ({
    type: DELETE_MACHINESLOCATION_REQUEST,
    payload: id,
  });
  
  export const deleteMachinesLocationSuccess = (machineslocation) => ({
    type: DELETE_MACHINESLOCATION_SUCCESS,
    payload: machineslocation,
  });
  
  export const deleteMachinesLocationFailure = (error) => ({
    type: DELETE_MACHINESLOCATION_FAILURE,
    payload: error,
  });
  
  export const createMachinesLocationRequest = (machineslocation) => ({
    type: CREATE_MACHINESLOCATION_REQUEST,
    payload: machineslocation,
  });
  
  export const createMachinesLocationSuccess = (machineslocation) => ({
    type: CREATE_MACHINESLOCATION_SUCCESS,
    payload: machineslocation,
  });
  
  export const createMachinesLocationFailure = (error) => ({
    type: CREATE_MACHINESLOCATION_FAILURE,
    payload: error,
  });

  

