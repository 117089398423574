import {
    FETCH_GAUGES_FAILURE,
    FETCH_GAUGES_REQUEST,
    FETCH_GAUGES_SUCCESS,
    DELETE_GAUGES_FAILURE,
    DELETE_GAUGES_REQUEST,
    DELETE_GAUGES_SUCCESS,
    CREATE_GAUGES_FAILURE,
    CREATE_GAUGES_REQUEST,
    CREATE_GAUGES_SUCCESS,
    SHOW_GAUGES_EDIT_FORM,
    SHOW_GAUGES_NEW_FORM,
    UPDATE_GAUGES_FAILURE,
    UPDATE_GAUGES_REQUEST,
    UPDATE_GAUGES_SUCCESS,
    SET_GAUGES_VALIDATION_ERRORS,
    GET_GAGE_REQUEST,
    GET_GAGE_SUCCESS,
    GET_GAGE_FAILURE,
    Upload_GAUGES_FAILURE,
    Upload_GAUGES_SUCCESS,
    Upload_GAUGES_REQUEST
  } from "../constants/actions.js";
  
  export const fetchGaugesRequest = (req) => ({
    type: FETCH_GAUGES_REQUEST,
    payload:req
  });
  
  export const fetchGaugesSuccess = (gauges) => ({
    type: FETCH_GAUGES_SUCCESS,
    payload: gauges,
  });
  
  export const fetchGaugesFailure = (error) => ({
    type: FETCH_GAUGES_FAILURE,
    payload: error,
  });

  export const setGaugesValidationErrors = (validationErrors) => ({
    type: SET_GAUGES_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleGaugesEditForm = () => ({
    type: SHOW_GAUGES_EDIT_FORM,
  });
  
  export const toggleGaugesNewForm = () => ({
    type: SHOW_GAUGES_NEW_FORM,
  });
  
  export const updateGaugesRequest = (gauges) => ({
    type: UPDATE_GAUGES_REQUEST,
    payload: gauges,
  });
  
  export const updateGaugesSuccess = (gauges) => ({
    type: UPDATE_GAUGES_SUCCESS,
    payload: gauges,
  });
  
  export const updateGaugesFailure = (error) => ({
    type: UPDATE_GAUGES_FAILURE,
    payload: error,
  });
  
  export const deleteGaugesRequest = (id) => ({
    type: DELETE_GAUGES_REQUEST,
    payload: id,
  });
  
  export const deleteGaugesSuccess = (gauges) => ({
    type: DELETE_GAUGES_SUCCESS,
    payload: gauges,
  });
  
  export const deleteGaugesFailure = (error) => ({
    type: DELETE_GAUGES_FAILURE,
    payload: error,
  });
  
  export const createGaugesRequest = (gauges) => ({
    type: CREATE_GAUGES_REQUEST,
    payload: gauges,
  });
  
  export const createGaugesSuccess = (gauges) => ({
    type: CREATE_GAUGES_SUCCESS,
    payload: gauges,
  });
  
  export const createGaugesFailure = (error) => ({
    type: CREATE_GAUGES_FAILURE,
    payload: error,
  });


  export const uploadGaugesRequest = (gauges) => ({
    type: Upload_GAUGES_REQUEST,
    payload: gauges,
  });
  
  export const uploadGaugesSuccess = (gauges) => ({
    type: Upload_GAUGES_SUCCESS,
    payload: gauges,
  });
  
  export const uploadGaugesFailure = (error) => ({
    type: Upload_GAUGES_FAILURE,
    payload: error,
  });

  
  
  export const getgageRequest = (machineID) => ({
      type: GET_GAGE_REQUEST,
      payload:machineID
    });
    
    export const getgageSuccess = (gage) => ({
      type: GET_GAGE_SUCCESS,
      payload: gage,
      loading:false
    });
    
    export const getgageFailure = (error) => ({
      type: GET_GAGE_FAILURE,
      payload: error,
    });

