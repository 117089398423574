import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_LOOKUPS_REQUEST,
  DELETE_LOOKUPS_REQUEST,
  CREATE_LOOKUPS_REQUEST,
  UPDATE_LOOKUPS_REQUEST,
} from "../constants/actions.js";

import { fetchLookupsFailure, fetchLookupsSuccess,createLookupsFailure,
createLookupsSuccess,deleteLookupsFailure,deleteLookupsSuccess,setLookupsValidationErrors,updateLookupsRequest
,updateLookupsFailure,updateLookupsSuccess, fetchLookupsRequest } from "redux/actions/Lookups";
import LookupsService from "services/LookupsService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchLookups() {
  yield takeLatest(FETCH_LOOKUPS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(LookupsService.index,payload);
      if (result.succeeded) {
        yield put(fetchLookupsSuccess(result.data));
      } else {
        yield put(fetchLookupsFailure(result.message));
      }
    } catch (error) {
      yield put(fetchLookupsFailure(error.message));
    }
  });
}

export function* onUpdateLookups() {
  yield takeLatest(UPDATE_LOOKUPS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        LookupsService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateLookupsSuccess(result.data));
        yield put(fetchLookupsRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setLookupsValidationErrors(result.data.errors));
        yield put(updateLookupsFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateLookupsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateLookupsFailure(error.message));
    }
  });
}

export function* onDeleteLookups() {
  yield takeLatest(DELETE_LOOKUPS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(LookupsService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteLookupsSuccess(result.data));
        yield put(fetchLookupsRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteLookupsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteLookupsFailure(error.message));
    }
  });
}

export function* onCreateLookups() {
  yield takeLatest(CREATE_LOOKUPS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(LookupsService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createLookupsSuccess(result.data));
        yield put(fetchLookupsRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setLookupsValidationErrors(result.data.errors));
        yield put(createLookupsFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createLookupsFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteLookupsFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchLookups),
    fork(onUpdateLookups),
    fork(onDeleteLookups),
    fork(onCreateLookups),
  ]);
}