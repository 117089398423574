import { CREATE_BeginningBalance_FAILURE, CREATE_BeginningBalance_REQUEST, CREATE_BeginningBalance_SUCCESS, DELETE_BeginningBalance_FAILURE, DELETE_BeginningBalance_REQUEST, DELETE_BeginningBalance_SUCCESS, FETCH_BeginningBalance_FAILURE, FETCH_BeginningBalance_REQUEST, FETCH_BeginningBalance_SUCCESS, FETCH_StokeBalance_REQUEST, FETCH_StokeBalance_SUCCESS, SET_BeginningBalance_VALIDATION_ERRORS, SHOW_BeginningBalance_EDIT_FORM, SHOW_BeginningBalance_NEW_FORM, UPDATE_BeginningBalance_FAILURE, UPDATE_BeginningBalance_REQUEST, UPDATE_BeginningBalance_SUCCESS } from "../constants/actions.js";
  import {
    addBeginningBalance,
    formtValidationErrors,
    removeBeginningBalance,
    updateBeginningBalance,
  } from "utils/BeginningBalance";
  const initState = {
    loading: false,
    list: [],
    Balance: [],
    errorMessage: "",
    updating: false,
    creating: false,
    validationErrors: [],
    showEditForm: false,
    showNewForm: false,
    deleting: false,
  };
  
  const BeginningBalance = (state = initState, action) => {
    switch (action.type) {
      case FETCH_BeginningBalance_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_BeginningBalance_SUCCESS:
        return {
          ...state,
          loading: false,
          list: action.payload,
        };
        case FETCH_StokeBalance_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_StokeBalance_SUCCESS:
        return {
          ...state,
          loading: false,
          Balance: action.payload,
        };
      case FETCH_BeginningBalance_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
  
      case SET_BeginningBalance_VALIDATION_ERRORS:
        return {
          ...state,
          validationErrors: formtValidationErrors(action.payload),
        };
      case SHOW_BeginningBalance_EDIT_FORM:
        return {
          ...state,
          showEditForm: !state.showEditForm,
        };
      case SHOW_BeginningBalance_NEW_FORM:
        return {
          ...state,
          showNewForm: !state.showNewForm,
        };
      case UPDATE_BeginningBalance_REQUEST:
        return {
          ...state,
          updating: true,
          errorMessage: "",
          validationErrors: [],
        };
      case UPDATE_BeginningBalance_SUCCESS:
        return {
          ...state,
          updating: false,
          list: updateBeginningBalance(state, action),
          errorMessage: "",
          validationErrors: [],
          showEditForm: false,
        };
      case UPDATE_BeginningBalance_FAILURE:
        return {
          ...state,
          updating: false,
          errorMessage: action.payload,
          validationErrors: [],
        };
      case DELETE_BeginningBalance_REQUEST:
        return {
          ...state,
          deleting: true,
        };
      case DELETE_BeginningBalance_SUCCESS:
        return {
          ...state,
          deleting: false,
          
          list: removeBeginningBalance(state, action),
        };
      case DELETE_BeginningBalance_FAILURE:
        return {
          ...state,
          deleting: false,
          errorMessage: action.payload,
        };
  
      case CREATE_BeginningBalance_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case CREATE_BeginningBalance_SUCCESS:
        return {
          ...state,
          creating: false,
          showNewForm: !state.showNewForm,
          list: addBeginningBalance(state, action),
        };
      case CREATE_BeginningBalance_FAILURE:
        return {
          ...state,
          creating: false,
          errorMessage: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default BeginningBalance;