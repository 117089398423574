import {
    FETCH_USERS_FAILURE,
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    DELETE_USERS_FAILURE,
    DELETE_USERS_REQUEST,
    DELETE_USERS_SUCCESS,
    CREATE_USERS_FAILURE,
    CREATE_USERS_REQUEST,
    CREATE_USERS_SUCCESS,
    SHOW_USERS_EDIT_FORM,
    SHOW_USERS_NEW_FORM,
    UPDATE_USERS_FAILURE,
    UPDATE_USERS_REQUEST,
    UPDATE_USERS_SUCCESS,
    SET_USERS_VALIDATION_ERRORS,
    CREATE_USERROLE_REQUEST,
    CREATE_USERROLE_SUCCESS,
    CREATE_USERROLE_FAILURE,
    SHOW_USERROLE_NEW_FORM,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    SEND_RESET_EMAIL_REQUEST,
    SEND_RESET_EMAIL_SUCCESS,
    SEND_RESET_EMAIL_FAILURE,
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
  
  } from "../constants/actions.js";
  
  export const fetchUsersRequest = (  
  ) => ({
    type: FETCH_USERS_REQUEST
  });
  
  export const fetchUsersSuccess = (users) => ({
    type: FETCH_USERS_SUCCESS,
    payload: users,
  });
  
  export const fetchUsersFailure = (error) => ({
    type: FETCH_USERS_FAILURE,
    payload: error,
  });

  export const setUsersValidationErrors = (validationErrors) => ({
    type: SET_USERS_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleUsersEditForm = () => ({
    type: SHOW_USERS_EDIT_FORM,
  });
  
  export const toggleUsersNewForm = () => ({
    type: SHOW_USERS_NEW_FORM,
  });
  
  export const updateUsersRequest = (users) => ({
    type: UPDATE_USERS_REQUEST,
    payload: users,
  });
  
  export const updateUsersSuccess = (users) => ({
    type: UPDATE_USERS_SUCCESS,
    payload: users,
  });
  
  export const updateUsersFailure = (error) => ({
    type: UPDATE_USERS_FAILURE,
    payload: error,
  });
  
  export const deleteUsersRequest = (id) => ({
    type: DELETE_USERS_REQUEST,
    payload: id,
  });
  
  export const deleteUsersSuccess = (users) => ({
    type: DELETE_USERS_SUCCESS,
    payload: users,
  });
  
  export const deleteUsersFailure = (error) => ({
    type: DELETE_USERS_FAILURE,
    payload: error,
  });
  
  export const createUsersRequest = (users) => ({
    type: CREATE_USERS_REQUEST,
    payload: users,
  });
  
  export const createUsersSuccess = (users) => ({
    type: CREATE_USERS_SUCCESS,
    payload: users,
  });
  
  export const createUsersFailure = (error) => ({
    type: CREATE_USERS_FAILURE,
    payload: error,
  });


  export const createUserRoleRequest = (userrole) => ({
    type: CREATE_USERROLE_REQUEST,
    payload: userrole,
  });
  
  export const createUserRoleSuccess = (userrole) => ({
    type: CREATE_USERROLE_SUCCESS,
    payload: userrole,
  });
  
  export const createUserRoleFailure = (error) => ({
    type: CREATE_USERROLE_FAILURE,
    payload: error,
  });
  
  export const toggleUserRoleNewForm = () => ({
    type: SHOW_USERROLE_NEW_FORM,
  });


  export const ChangePasswordRequest = (payload) => ({
    type: CHANGE_PASSWORD_REQUEST,
    payload: payload,
  });
  
  export const ChangePasswordSuccess = (payload) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: payload,
  });
  
  export const ChangePasswordFailure = (error) => ({
    type: CHANGE_PASSWORD_FAILURE,
    payload: error,
  });
  
  export const SendresetemailRequest = (payload) => ({
    type: SEND_RESET_EMAIL_REQUEST,
    payload: payload,
  });
  
  export const SendresetemailSuccess = (payload) => ({
    type: SEND_RESET_EMAIL_SUCCESS,
    payload: payload,
  });
  
  export const SendresetemailFailure = (error) => ({
    type: SEND_RESET_EMAIL_FAILURE,
    payload: error,
  });
  

  export const ResetPasswordRequest = (payload) => ({
    type: RESET_PASSWORD_REQUEST,
    payload: payload,
  });
  
  export const ResetPasswordSuccess = (payload) => ({
    type: RESET_PASSWORD_SUCCESS,
    payload: payload,
  });
  
  export const ResetPasswordFailure = (error) => ({
    type: RESET_PASSWORD_FAILURE,
    payload: error,
  });
  