import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_GAUGES_REQUEST,
  DELETE_GAUGES_REQUEST,
  CREATE_GAUGES_REQUEST,
  UPDATE_GAUGES_REQUEST,
  GET_GAGE_REQUEST,
  GET_GAGE_SUCCESS,
  GET_GAGE_FAILURE,
  Upload_GAUGES_REQUEST
} from "../constants/actions.js";

import { fetchGaugesFailure, fetchGaugesSuccess,createGaugesFailure,
createGaugesSuccess,deleteGaugesFailure,deleteGaugesSuccess,setGaugesValidationErrors,updateGaugesRequest
,updateGaugesFailure,updateGaugesSuccess, fetchGaugesRequest, getgageSuccess, getgageFailure, UploadGaugesSuccess, UploadGaugesFailure, uploadGaugesSuccess, uploadGaugesFailure } from "redux/actions/Gauges";
import GaugesService from "services/GaugesService";
import Loading from "components/shared-components/Loading/index.js";




export function* ongetGaugeById() {
  yield takeLatest(GET_GAGE_REQUEST, function* ({payload}) {
    try {
      const result = yield call(GaugesService.getbyMachineId,payload);
      if (result.succeeded) {
        yield put(getgageSuccess(result.data));
      } else {
        yield put(getgageFailure(result.message));
      }
    } catch (error) {
      yield put(getgageFailure(error.message));
    }
  });
}

export function* onFetchGauges() {
  yield takeLatest(FETCH_GAUGES_REQUEST, function* ({payload}) {
    try {
      const result = yield call(GaugesService.index,payload);
      if (result.succeeded) {
        yield put(fetchGaugesSuccess(result.data));
      } else {
        yield put(fetchGaugesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchGaugesFailure(error.message));
    }
  });
}



export function* onUpdateGauges() {
  yield takeLatest(UPDATE_GAUGES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        GaugesService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateGaugesSuccess(result.data));
        yield put(fetchGaugesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setGaugesValidationErrors(result.data.errors));
        yield put(updateGaugesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateGaugesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateGaugesFailure(error.message));
    }
  });
}

export function* onDeleteGauges() {
  yield takeLatest(DELETE_GAUGES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(GaugesService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteGaugesSuccess(result.data));
        yield put(fetchGaugesRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteGaugesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteGaugesFailure(error.message));
    }
  });
}

export function* onCreateGauges() {
  yield takeLatest(CREATE_GAUGES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(GaugesService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createGaugesSuccess(result.data));
        yield put(fetchGaugesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setGaugesValidationErrors(result.data.errors));
        yield put(createGaugesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createGaugesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteGaugesFailure(error.message));
    }
  });
}
export function* onUploadGauges() {
  yield takeLatest(Upload_GAUGES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(GaugesService.Upload, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(uploadGaugesSuccess(result.data));
        yield put(fetchGaugesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setGaugesValidationErrors(result.data.errors));
        yield put(uploadGaugesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(uploadGaugesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteGaugesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchGauges),
    fork(onUpdateGauges),
    fork(onDeleteGauges),
    fork(onCreateGauges),
    fork(ongetGaugeById),
    fork(onUploadGauges)
  ]);
}