
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Row, Col, Radio, Form, Card, DatePicker, Select } from 'antd';
import DataDisplayWidget from 'components/shared-components/DataDisplayWidget';
import Flex from 'components/shared-components/Flex'
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from "apexcharts";
import {
	SnippetsOutlined,
	IssuesCloseOutlined,
	UserSwitchOutlined,
	FileDoneOutlined,
	SyncOutlined,
	BarChartOutlined,
	CarryOutOutlined,
	DesktopOutlined,
	PrinterOutlined,
	CheckCircleOutlined,
	CopyOutlined,
	HistoryOutlined,
	FieldTimeOutlined,
	WarningOutlined, HomeOutlined,
	QuestionCircleOutlined
} from '@ant-design/icons';
import Chart from "react-apexcharts";
import { COLORS } from 'constants/ChartConstant';
import { useSelector } from 'react-redux';
import {
	fetchItemsRequest,
	fetchCompaniesRequest,
	fetchMachinesRequest,
	fetchOrdersResults,
	fetchOrdersRequest,
	fetchDashboardsRequest,
	fetchBeginningBalanceRequest,

} from "redux/actions";
import ChartWidget from "components/shared-components/ChartWidget";
import Loading from "components/shared-components/Loading";
import moment from 'moment';
import { handleInput } from "concurrently/src/defaults";
const { Option } = Select;
const { RangePicker } = DatePicker;
const { Meta } = Card;
const Home = (
	{
		companies = [],
		fetchCompaniesResults,
		machines = [],
		fetchMachinesResults,
		items = [],
		fetchItemsResults,
		orders = [],
		loading,
		fetchOrdersResults,
		servicerequests = [],
		fetchservicerequestResults,
		loaded = false,
		beginningbalance = [],
		fetchBeginningBalanceResults,
	}
) => {


	useEffect(() => {
		fetchBeginningBalanceResults();
		//setLineOfBeginningBalance(beginningbalance)
	  }, [fetchBeginningBalanceRequest]); 
	
	  const category = [];
	  const results = [];
	  beginningbalance.forEach(employee => {
	   category.push(employee.itemName)
	   results.push(employee.currentBalance)
	})
	
	  console.log(category)
	  console.log(results)
 
	
	  const series = [ //data on the y-axis
    {
      
	  data: results
    }
  ];
  const options = { //data on the x-axis
    chart: { id: 'bar-chart'},
    xaxis: {
		categories: category,
    }
  };

	//   const  series = [{
	// 	name: 'All Tasks',
	// 	data: [1, 2, 3, 10, 51, 60]
	//   }, {
	// 	// name: 'My Tasks',
	// 	// data: [11, 32, 45, 32, 34, 52, 41]
	//   }]


	useEffect(() => {
		document.title = "GETNET SYSTEMS HOME";
		var req = {
			ParentId: null
		}

		fetchCompaniesResults(req);
		fetchMachinesResults();
		fetchItemsResults();
		//   fetchOrdersResults();
		var req2 = {
			startDate: null,
			endDate: null
		}
		fetchservicerequestResults(req2);
	}, [fetchOrdersResults, fetchMachinesResults, fetchItemsResults]);
	const dateFormat = 'DD/MM/YYYY';
	const [dateRange, setDateRange] = useState([moment().subtract(6, "days"), moment()])
	const { direction } = useSelector(state => state.theme)

	const [servicerequest, setUniqueRequest] = useState(servicerequests)

	const handleChange = (value) => {
		setDateRange(value)
		console.log(dateRange)
		var req2 = {
			startDate: dateRange[0].toISOString(),
			endDate: dateRange[1].toISOString()
		}
		fetchservicerequestResults(req2);
	}






	return (
		<>

			{/* <Card  title="Dashboard">


 </Card > */}
 <Card   bordered={false}>
 <Row gutter={16}>
 <Col  xs={24} sm={24} md={24} lg={18} xl={24}>
	
		<Row gutter={16}>
		<Col  xs={24} sm={24} md={12} lg={6} xl={12}>
			<DataDisplayWidget 
				icon={<HomeOutlined />} 
				value={companies.length}
				title= {<a href="/app/company">Companies</a>}
				color="green"
				vertical={false}
				avatarSize={70}
			/>
		</Col>
<Col  xs={24} sm={24} md={12} lg={6} xl={12}>
			<DataDisplayWidget 
				icon={<PrinterOutlined />} 
				value={machines.length}
				title={<a href="/app/machines">Machines</a>}	
				color="green"
				vertical={false}
				avatarSize={70}
			/>
		</Col>
		<Col  xs={24} sm={24} md={12} lg={8} xl={12}>
			<DataDisplayWidget 
				icon={<SnippetsOutlined />} 
				value={beginningbalance.length}
				title={<a href="/app/stokebalance">Stoke Balance</a>}	
				color="green"
				vertical={false}
				avatarSize={70}
			/>
		</Col>
			</Row>
			{/* <Row gutter={16}>
            <Col span={24}>
                <ChartWidget 
                  title="Service Requests" 
				
                  series={visitorChartData.series} 
                  xAxis={visitorChartData.categories} 
                  height={'400px'}
                  direction={direction}
                />
            </Col>
          </Row> */}
{/* <Col  xs={24} sm={24} md={12} lg={6} xl={6}>
			<DataDisplayWidget 
				icon={<QuestionCircleOutlined />} 
				value={orders.length}
				title="Total Requests"	
				color="green"
				vertical={false}
				avatarSize={70}
			/>
		</Col>
	</Row>
 
  <Row gutter={12}>
		<Col  xs={24} sm={24} md={8} lg={8} xl={8}>
			<DataDisplayWidget 
				icon={<HistoryOutlined />} 
				value={orders.filter(o=>o.status==0).length}
				title="Active Requests"	
				color="red"
				vertical={false}
				avatarSize={70}
			/>
		</Col>
<Col  xs={24} sm={24} md={8} lg={8} xl={8}>
			<DataDisplayWidget 
				icon={<IssuesCloseOutlined />} 
				value={orders.filter(o=>o.status==1).length}
				title="Assigned Requests"	
				color="yellow"
				vertical={false}
				avatarSize={70}
			/>
		</Col>
    <Col  xs={24} sm={24} md={8} lg={8} xl={8}>
			<DataDisplayWidget 
				icon={<CheckCircleOutlined />} 
				value={orders.filter(o=>o.status==2).length}
				title="Closed Requests"	
				color="green"
				vertical={false}
				avatarSize={70}
			/>
		</Col> */}
</Col>
	</Row>
<Row>
<Col style={{paddingRight:"20px"}}  xs={24} sm={24} md={24} lg={24} xxl={24} xl={24}>
{ !loading ?(
          <ChartWidget 
		    
            series={servicerequests.dashboards} 
			xAxis={servicerequests.dates
			
			} 

								title="Service Requests"
								height={410}
								type="line"
								direction={direction}
								customOptions={
									{
										colors: [COLORS[0], COLORS[1], COLORS[3]]
									}
								}
								extra={
									<span>
										<br></br><br></br>
										<RangePicker onOk={(x) => handleChange()} value={dateRange} />
									</span>

								}
							// extra={


							//   <Select defaultValue="week" size="small" style={{ width: 120 }} onChange={handleVisitorsChartChange}>
							//     <Option value="day">Day</Option>
							//     <Option value="week">Week</Option>
							//     <Option value="month">Month</Option>
							//   </Select>


							// }
							/>
						) :
							<Card style={{ height: "100%" }}>
								<Loading align="center"></Loading>
							</Card>


						}
					</Col>
					<Col xs={24} sm={24} md={24} lg={24} xxl={12} xl={24}>
						<ReactApexChart options={options} series={series} type="line" height={350} />
					{/* <div>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="450"
      />
    </div> */}
		</Col>
						
		{/* <Col   xs={24} sm={24} md={24} lg={24} xxl={24} xl={12}>
{ !loading ?(
          <ChartWidget 
		    
            series={servicerequests.dashboards} 
			xAxis={servicerequests.dates
			
			} 

            title="Stock Balance"
            height={410}
            type="line"
            direction={direction}
            customOptions={
              {
                colors: [COLORS[0], COLORS[1],COLORS[3]]
              }
            }
			extra={
				<span>
					<br></br><br></br>
<RangePicker onChange={(x) => handleChange()} value={dateRange} />
				</span>
				
			}
            // extra={
			
		
            //   <Select defaultValue="week" size="small" style={{ width: 120 }} onChange={handleVisitorsChartChange}>
            //     <Option value="day">Day</Option>
            //     <Option value="week">Week</Option>
            //     <Option value="month">Month</Option>
            //   </Select>
		

            // }
          />
):
<Card style={{height:"100%"}}>
<Loading align="center"></Loading>
</Card>


}
        </Col> */}
</Row>


			</Card>
		</>
	)
}

const mapStateToProps = (state) => ({
	companies: state.companies.list,
	machines: state.machines.list,
	items: state.items.list,
	orders: state.orders.list,
	servicerequests: state.dashboards.list,
	beginningbalance : state.beginningbalance.list,
	loading: state.dashboards.loading
	
	//loading:state.dashboards.loading
,
	beginningbalance: state.beginningbalance.list,
});

const mapDispatchToProps = (dispatch) => ({
	fetchBeginningBalanceResults: () =>
		dispatch(fetchBeginningBalanceRequest()),


	fetchOrdersResults: () =>
		dispatch(fetchOrdersRequest()),
	fetchItemsResults: () =>
		dispatch(fetchItemsRequest()),


	fetchCompaniesResults: (req) =>
		dispatch(fetchCompaniesRequest(req)),

	fetchMachinesResults: () =>
		dispatch(fetchMachinesRequest()),

	fetchservicerequestResults: (req) =>
		dispatch(fetchDashboardsRequest(req)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);