import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_EMPLOYEES_REQUEST,
  DELETE_EMPLOYEES_REQUEST,
  CREATE_EMPLOYEES_REQUEST,
  UPDATE_EMPLOYEES_REQUEST,
  FETCH_EMPLOYEES_ATTENDANCE_FAILURE,
  FETCH_EMPLOYEES_ATTENDANCE_REQUEST,
  FETCH_EMPLOYEES_ATTENDANCE_SUCCESS,
  FETCH_EMP_REQUEST,
  CREATE_EMPATT_REQUEST,
} from "../constants/actions.js";

import { fetchEmployeesFailure, fetchEmployeesSuccess,createEmployeesFailure,
createEmployeesSuccess,deleteEmployeesFailure,deleteEmployeesSuccess,setEmployeesValidationErrors,updateEmployeesRequest
,updateEmployeesFailure,updateEmployeesSuccess, fetchEmployeesRequest,
fetchEmployeesAttendanceFailure, fetchEmployeesAttendanceSuccess,fetchEmployeesAttendanceRequest, fetchEmpAttendanceSuccess, fetchEmpAttendanceFailure, createEmpAttFailure, createEmpAttSuccess
} from "redux/actions/Employees";
import EmployeesService from "services/EmployeesService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchEmployees() {
  yield takeLatest(FETCH_EMPLOYEES_REQUEST, function* () {
    try {
      const result = yield call(EmployeesService.index);
      if (result.succeeded) {
        yield put(fetchEmployeesSuccess(result.data));
      } else {
        yield put(fetchEmployeesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchEmployeesFailure(error.message));
    }
  });
}

export function* onFetchEmployeesAttendance() {
  yield takeLatest(FETCH_EMPLOYEES_ATTENDANCE_REQUEST, function* ({payload}) {
    try {
      const result = yield call(EmployeesService.getattendance,payload);
      if (result.succeeded) {

        yield put(fetchEmployeesAttendanceSuccess(formater(result.data)));
      } else {
        yield put(fetchEmployeesAttendanceFailure(result.message));
      }
    } catch (error) {
      yield put(fetchEmployeesAttendanceFailure(error.message));
    }
  });
}

export function* onFetchEmpAttendance() {
  yield takeLatest(FETCH_EMP_REQUEST, function* ({payload}) {
    try {
      const result = yield call(EmployeesService.Empattendance,payload);
      if (result.succeeded) {

        yield put(fetchEmpAttendanceSuccess((result.data)));
      } else {
        yield put(fetchEmpAttendanceFailure(result.message));
      }
    } catch (error) {
      yield put(fetchEmpAttendanceFailure(error.message));
    }
  });
}


const formater=(data)=>{
  let tempObj={}
  const Arr=[]
  data?.map((employee) => {
    if(employee.employeeIdentityID !=0){
    tempObj['employeeName'] = employee.employeeName

    tempObj['employeeId'] = employee.employeeIdentityID

    employee.attendances?.map((attendance) => {

        tempObj[attendance?.day] = attendance?.status

    })}
    if(tempObj['employeeId']!=null ){
    Arr.push(tempObj);
    }
    tempObj = {}

})
return Arr
}



export function* onUpdateEmployees() {
  yield takeLatest(UPDATE_EMPLOYEES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        EmployeesService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateEmployeesSuccess(result.data));
        yield put(fetchEmployeesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setEmployeesValidationErrors(result.data.errors));
        yield put(updateEmployeesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateEmployeesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateEmployeesFailure(error.message));
    }
  });
}

export function* onDeleteEmployees() {
  yield takeLatest(DELETE_EMPLOYEES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(EmployeesService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteEmployeesSuccess(result.data));
        yield put(fetchEmployeesRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteEmployeesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteEmployeesFailure(error.message));
    }
  });
}

export function* onCreateEmployees() {
  yield takeLatest(CREATE_EMPLOYEES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(EmployeesService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createEmployeesSuccess(result.data));
        yield put(fetchEmployeesRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setEmployeesValidationErrors(result.data.errors));
        yield put(createEmployeesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createEmployeesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteEmployeesFailure(error.message));
    }
  });
}


export function* onCreateEmpAtt() {
  yield takeLatest(CREATE_EMPATT_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(EmployeesService.attendance, payload);
      if (result.succeeded) {
        message.success("Attendance Submitted Successfully");
        yield put(createEmpAttSuccess(result.data));
    
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(createEmpAttFailure(result.message));
      } else {
        message.error(result.message);
        yield put(createEmpAttFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
     }
  });
}
export default function* rootSaga() {
  yield all([
    fork(onFetchEmployees),
    fork(onFetchEmployeesAttendance),
    fork(onUpdateEmployees),
    fork(onDeleteEmployees),
    fork(onCreateEmployees),
    fork(onFetchEmpAttendance),
    fork(onCreateEmpAtt)
  ]);
}