import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_FEEDBACKS_REQUEST,
  DELETE_FEEDBACKS_REQUEST,
  CREATE_FEEDBACKS_REQUEST,
  UPDATE_FEEDBACKS_REQUEST,
} from "../constants/actions.js";

import { fetchFeedbacksFailure, fetchFeedbacksSuccess,createFeedbacksFailure,
createFeedbacksSuccess,deleteFeedbacksFailure,deleteFeedbacksSuccess,setFeedbacksValidationErrors,updateFeedbacksRequest
,updateFeedbacksFailure,updateFeedbacksSuccess, fetchFeedbacksRequest } from "redux/actions/Feedbacks";
import FeedbacksService from "services/FeedbacksService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchFeedbacks() {
  yield takeLatest(FETCH_FEEDBACKS_REQUEST, function* () {
    try {
      const result = yield call(FeedbacksService.index);
      if (result.succeeded) {
        yield put(fetchFeedbacksSuccess(result.data));
      } else {
        yield put(fetchFeedbacksFailure(result.message));
      }
    } catch (error) {
      yield put(fetchFeedbacksFailure(error.message));
    }
  });
}



export function* onUpdateFeedbacks() {
  yield takeLatest(UPDATE_FEEDBACKS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        FeedbacksService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
        message.success("Updated");
        yield put(updateFeedbacksSuccess(result.data));
        yield put(fetchFeedbacksRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setFeedbacksValidationErrors(result.data.errors));
        yield put(updateFeedbacksFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateFeedbacksFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateFeedbacksFailure(error.message));
    }
  });
}

export function* onDeleteFeedbacks() {
  yield takeLatest(DELETE_FEEDBACKS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(FeedbacksService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        yield put(deleteFeedbacksSuccess(result.data));
        yield put(fetchFeedbacksRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteFeedbacksFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteFeedbacksFailure(error.message));
    }
  });
}

export function* onCreateFeedbacks() {
  yield takeLatest(CREATE_FEEDBACKS_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(FeedbacksService.create, payload);
      if (result.succeeded) {
        message.success("Thank you for your feedback");
        yield put(createFeedbacksSuccess(result.data));
        // yield put(fetchFeedbacksRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setFeedbacksValidationErrors(result.data.errors));
        yield put(createFeedbacksFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createFeedbacksFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteFeedbacksFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchFeedbacks),
    fork(onUpdateFeedbacks),
    fork(onDeleteFeedbacks),
    fork(onCreateFeedbacks),
  ]);
}