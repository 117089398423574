 import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
 import { message } from "antd";
 import { createGoodsreceivingvoucherFailure, createGoodsreceivingvoucherSuccess, deleteGoodsreceivingvoucherFailure, setGoodsreceivingvoucherValidationErrors } from "redux/actions/Goodsreceivingvoucher";
 import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { CREATE_Goodsreceivingvoucher_REQUEST } from "redux/constants/actions";
import GoodsreceivingvoucherService from "services/Goodsreceivingvoucher";

 
export function* onCreateGoodsreceivingvoucher() {
    yield takeLatest(CREATE_Goodsreceivingvoucher_REQUEST, function* ({ payload }) {
      try {
        const result = yield call(GoodsreceivingvoucherService.create, payload);
        if (result.succeeded) {
          message.success("Created");
          yield put(createGoodsreceivingvoucherSuccess(result.data));
          //yield put(fetchLookupsRequest());
        } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
          message.error("Check your inputs please");
          yield put(setGoodsreceivingvoucherValidationErrors(result.data.errors));
          yield put(createGoodsreceivingvoucherFailure(result.message));
        } else {
          message.error("Something went wrong");
          yield put(createGoodsreceivingvoucherFailure(result.message));
        }
      } catch (error) {
        message.error("Something went wrong");
        yield put(deleteGoodsreceivingvoucherFailure(error.message));
      }
    });
}
    export default function* rootSaga() {
      yield all([
        fork(onCreateGoodsreceivingvoucher),
      ]);
    }