import fetch from "auth/FetchInterceptor";

const UsersService = {};

UsersService.index = function () {
  return fetch({
    url:
     "Account" ,
    method: "get",
  });
};


UsersService.create = function (data) {
  return fetch({
    url: "Account/register",
    method: "post",
    data,
  });
};

UsersService.assignRole = function (payload) {
  var data=payload.roles;
  console.log(data);
  return fetch({
    url: `Account/AssignRole?userId=${payload.id}`,
    method: "post",
    data,
  });
};

UsersService.updateuser = function (id, data) {
  return fetch({
    url: `Account/update?userid=${id}`,
    method: "post",
    data,
  });
};

UsersService.changepassword = function (data) {
  return fetch({
    url: `Account/change-password`,
    method: "post",
    data,
  });
};




UsersService.sendresetemail = function (data) {
  return fetch({
    url: `Account/forgot-password`,
    method: "post",
    data,
  });
};

UsersService.resetpassword = function (data) {
  return fetch({
    url: `Account/reset-password`,
    method: "post",
    data,
  });
};

UsersService.update = function (id, data) {
  return fetch({
    url: `employeecreate/${id}`,
    method: "put",
    data,
  });
};

UsersService.delete = function (id) {
  return fetch({
    url: `Account/DeleteUserAsync?userid=${id}`,
    method: "delete",
  });
};

export default UsersService;