import {
  FETCH_ADDRESSES_FAILURE,
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
  DELETE_ADDRESSES_FAILURE,
  DELETE_ADDRESSES_REQUEST,
  DELETE_ADDRESSES_SUCCESS,
  CREATE_ADDRESSES_FAILURE,
  CREATE_ADDRESSES_REQUEST,
  CREATE_ADDRESSES_SUCCESS,
  SHOW_ADDRESSES_EDIT_FORM,
  SHOW_ADDRESSES_NEW_FORM,
  UPDATE_ADDRESSES_FAILURE,
  UPDATE_ADDRESSES_REQUEST,
  UPDATE_ADDRESSES_SUCCESS,
  SET_ADDRESSES_VALIDATION_ERRORS,
  FETCH_ADDRESSESLIST_FAILURE,
  FETCH_ADDRESSESLIST_REQUEST,
  FETCH_ADDRESSESLIST_SUCCESS
} from "../constants/actions.js";

const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
  addressloading:false,
  addresslist: [],
};

const addresses = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ADDRESSES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_ADDRESSES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
//
case FETCH_ADDRESSESLIST_REQUEST:
  return {
    ...state,
    addressloading: true,
  };
case FETCH_ADDRESSESLIST_SUCCESS:
  return {
    ...state,
    addressloading: false,
    addresslist: action.payload,
  };
case FETCH_ADDRESSESLIST_FAILURE:
  return {
    ...state,
    addressloading: false,
    errorMessage: action.payload,
  };
//
    case SET_ADDRESSES_VALIDATION_ERRORS:
      return {
        ...state,
        // validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_ADDRESSES_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_ADDRESSES_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_ADDRESSES_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_ADDRESSES_SUCCESS:
      return {
        ...state,
        updating: false,
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_ADDRESSES_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_ADDRESSES_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_ADDRESSES_SUCCESS:
      return {
        ...state,
        deleting: false
      };
    case DELETE_ADDRESSES_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_ADDRESSES_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_ADDRESSES_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm
      };
    case CREATE_ADDRESSES_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default addresses;