import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import {
  FETCH_ADDRESSES_REQUEST,
  DELETE_ADDRESSES_REQUEST,
  CREATE_ADDRESSES_REQUEST,
  UPDATE_ADDRESSES_REQUEST,
  FETCH_ADDRESSESLIST_REQUEST,
} from "../constants/actions.js";

import { fetchAddressesFailure, fetchAddressesSuccess,createAddressesFailure,
createAddressesSuccess,deleteAddressesFailure,deleteAddressesSuccess,setAddressesValidationErrors,updateAddressesRequest
,updateAddressesFailure,updateAddressesSuccess, fetchAddressesRequest, fetchAddressesListSuccess, fetchAddressesListFailure, fetchAddressesListRequest } from "redux/actions/Addresses";
import AddressesService from "services/AddressesService";
import Loading from "components/shared-components/Loading/index.js";

export function* onFetchAddresses() {
  yield takeLatest(FETCH_ADDRESSES_REQUEST, function* () {
    try {
      const result = yield call(AddressesService.index);
      if (result.succeeded) {
        yield put(fetchAddressesSuccess(result.data));
      } else {
        yield put(fetchAddressesFailure(result.message));
      }
    } catch (error) {
      yield put(fetchAddressesFailure(error.message));
    }
  });
}

export function* onFetchAddressesList() {
  yield takeLatest(FETCH_ADDRESSESLIST_REQUEST, function* () {
    try {
      const result = yield call(AddressesService.addresslist);
      if (result.succeeded) {
        yield put(fetchAddressesListSuccess(result.data));
      } else {
        yield put(fetchAddressesListFailure(result.message));
      }
    } catch (error) {
      yield put(fetchAddressesListFailure(error.message));
    }
  });
}


export function* onUpdateAddresses() {
  yield takeLatest(UPDATE_ADDRESSES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(
        AddressesService.update,
        payload.id,
        payload
      );
      if (result.succeeded) {
       
        message.success("Updated");
        yield put(updateAddressesSuccess(result.data));
        yield put(fetchAddressesListRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setAddressesValidationErrors(result.data.errors));
        yield put(updateAddressesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(updateAddressesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(updateAddressesFailure(error.message));
    }
  });
}

export function* onDeleteAddresses() {
  yield takeLatest(DELETE_ADDRESSES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(AddressesService.delete, payload);
      if (result.succeeded) {
        message.success("Deleted");
        // yield put(deleteAddressesSuccess(result.data));
        yield put(fetchAddressesListRequest());
      } else {
        message.error("Something went wrong");
        yield put(deleteAddressesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteAddressesFailure(error.message));
    }
  });
}

export function* onCreateAddresses() {
  yield takeLatest(CREATE_ADDRESSES_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(AddressesService.create, payload);
      if (result.succeeded) {
        message.success("Created");
        yield put(createAddressesSuccess(result.data));
        yield put(fetchAddressesListRequest());
      } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
        message.error("Check your inputs please");
        yield put(setAddressesValidationErrors(result.data.errors));
        yield put(createAddressesFailure(result.message));
      } else {
        message.error("Something went wrong");
        yield put(createAddressesFailure(result.message));
      }
    } catch (error) {
      message.error("Something went wrong");
      yield put(deleteAddressesFailure(error.message));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(onFetchAddresses),
    fork(onUpdateAddresses),
    fork(onDeleteAddresses),
    fork(onCreateAddresses),
    fork(onFetchAddressesList)
  ]);
}