import { CREATE_Goodsreceivingvoucher_FAILURE, CREATE_Goodsreceivingvoucher_REQUEST, CREATE_Goodsreceivingvoucher_SUCCESS, FETCH_LOOKUPS_FAILURE, FETCH_LOOKUPS_REQUEST, FETCH_LOOKUPS_SUCCESS, SET_Goodsreceivingvoucher_VALIDATION_ERRORS } 
from "../constants/actions.js";
  import {
    formtValidationErrors,
    addGoodsreceivingvoucher
  } from "utils/Goodsreceivingvoucher";
  
  const initState = {
    loading: false,
    list: [],
    errorMessage: "",
    updating: false,
    creating: false,
    validationErrors: [],
    showEditForm: false,
    showNewForm: false,
    deleting: false,
  };
  const Goodsreceivingvoucher = (state = initState, action) => {
    switch (action.type) {
        case SET_Goodsreceivingvoucher_VALIDATION_ERRORS:
        return {
          ...state,
          validationErrors: formtValidationErrors(action.payload),
        };
      case CREATE_Goodsreceivingvoucher_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case CREATE_Goodsreceivingvoucher_SUCCESS:
        return {
          ...state,
          creating: false,
          showNewForm: !state.showNewForm,
          list: addGoodsreceivingvoucher(state, action),
        };
      case CREATE_Goodsreceivingvoucher_FAILURE:
        return {
          ...state,
          creating: false,
          errorMessage: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default Goodsreceivingvoucher;