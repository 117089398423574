import fetch from "auth/FetchInterceptor";

const VoucherService = {};

VoucherService.create = function (data) {
    return fetch({
      url: "v1/StoreIssueVoucher",
      method: "post",
      data,
    });
  };

  VoucherService.type = function (Filter) {
    return fetch({
      url:
      `v1/StoreIssueVoucher/GetVoucherDocument/${Filter}`,
      method: "get",
    });
  };
  export default VoucherService;