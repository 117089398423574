import {
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  DELETE_COMPANIES_FAILURE,
  DELETE_COMPANIES_REQUEST,
  DELETE_COMPANIES_SUCCESS,
  CREATE_COMPANIES_FAILURE,
  CREATE_COMPANIES_REQUEST,
  CREATE_COMPANIES_SUCCESS,
  SHOW_COMPANIES_EDIT_FORM,
  SHOW_COMPANIES_NEW_FORM,
  UPDATE_COMPANIES_FAILURE,
  UPDATE_COMPANIES_REQUEST,
  UPDATE_COMPANIES_SUCCESS,
  SET_COMPANIES_VALIDATION_ERRORS,
  FETCH_ALL_COMPANIES_REQUEST,
  FETCH_ALL_COMPANIES_SUCCESS,
  FETCH_ALL_COMPANIES_FAILURE,
  // RESET_INITIAL_STATE,
  RESET_COMPANY_INITIAL_STATE
} from "../constants/actions.js";
import {
  addCompanies,
  formtValidationErrors,
  removeCompanies,
  updateCompanies,
} from "utils/Companies";
const initState = {
  loading: false,
  list: [],
  parentlist: [],
  allList: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
  blocker:1,
};

const companies = (state = initState, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        list : action.payload
    
      };
    case FETCH_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

      case FETCH_ALL_COMPANIES_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case FETCH_ALL_COMPANIES_SUCCESS:
        return {
          ...state,
          loading: false,
          allList : action.payload
      
        };
      case FETCH_ALL_COMPANIES_FAILURE:
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
  
    case SET_COMPANIES_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_COMPANIES_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_COMPANIES_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_COMPANIES_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_COMPANIES_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateCompanies(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_COMPANIES_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_COMPANIES_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_COMPANIES_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeCompanies(state, action),
      };
    case DELETE_COMPANIES_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_COMPANIES_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_COMPANIES_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
     
      };
    case CREATE_COMPANIES_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

      case RESET_COMPANY_INITIAL_STATE:
        return {
          ...initState
        };

    default:
      return state;
  }
};

export default companies;