import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { message } from "antd";
import { UNPROCESSABLE_ENTITY_CODE } from "configs/AppConfig";
import { createVoucherFailure, createVoucherSuccess, deleteVoucherFailure, fetchVoucherByTypeFailure, fetchVoucherByTypeSuccess, setVoucherValidationErrors } from "redux/actions/Voucher";
import { CREATE_Voucher_REQUEST, FETCH_Voucher_ByType_REQUEST } from "redux/constants/actions";
import VoucherService from "services/VoucherService";

export function* onCreateVoucher() {
    yield takeLatest(CREATE_Voucher_REQUEST, function* ({ payload }) {
      try {
        const result = yield call(VoucherService.create, payload);
        if (result.succeeded) {
          message.success("Created");
          yield put(createVoucherSuccess(result.data));
          //yield put(fetchLookupsRequest());
        } else if (result.code === UNPROCESSABLE_ENTITY_CODE) {
          message.error("Check your inputs please");
          yield put(setVoucherValidationErrors(result.data.errors));
          yield put(createVoucherFailure(result.message));
        } else {
          message.error("Something went wrong");
          yield put(createVoucherFailure(result.message));
        }
      } catch (error) {
        message.error("Something went wrong");
        yield put(deleteVoucherFailure(error.message));
      }
    });
    
}

export function* onFetchVoucherByType() {
  yield takeLatest(FETCH_Voucher_ByType_REQUEST, function* ({ payload }) {
    try {
      const result = yield call(VoucherService.type,payload);
      if (result.succeeded) {
        yield put(fetchVoucherByTypeSuccess(result.data));
      } else {
        yield put(fetchVoucherByTypeFailure(result.message));
      }
    } catch (error) {
      yield put(fetchVoucherByTypeFailure(error.message));
    }
  });
}
    export default function* rootSaga() {
      yield all([
        fork(onCreateVoucher),
        fork(onFetchVoucherByType)
      ]);
    }
  