import _ from "lodash";

export const addFeedbacks = (state, action) => {
  let list = state.list;
  const savedFeedbacks = action.payload;

  return [savedFeedbacks, ...list];
};

export const updateFeedbacks = (state, action) => {
  let list = state.list;
  const updatedFeedbacks = action.payload;

  const index = _.findIndex(list, ["id", updatedFeedbacks.id]);
  list[index] = updatedFeedbacks;

  return [...list];
};

export const removeFeedbacks = (state, action) => {
  let list = state.list;
  const deletedFeedbacks = action.payload;

  const filteredList = _.filter(list, function (td) {
    return td.id !== deletedFeedbacks.id;
  });

  return [...filteredList];
};
export const formtValidationErrors = (validationErrors) => {
  const keys = Object.keys(validationErrors);
  const errors = [];

  keys.forEach((key) => {
    errors.push({
      name: key,
      errors: validationErrors[key],
    });
  });

  return errors;
};