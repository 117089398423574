import {
  FETCH_MACHINESLOCATION_FAILURE,
  FETCH_MACHINESLOCATION_REQUEST,
  FETCH_MACHINESLOCATION_SUCCESS,
  DELETE_MACHINESLOCATION_FAILURE,
  DELETE_MACHINESLOCATION_REQUEST,
  DELETE_MACHINESLOCATION_SUCCESS,
  CREATE_MACHINESLOCATION_FAILURE,
  CREATE_MACHINESLOCATION_REQUEST,
  CREATE_MACHINESLOCATION_SUCCESS,
  SHOW_MACHINESLOCATION_EDIT_FORM,
  SHOW_MACHINESLOCATION_NEW_FORM,
  UPDATE_MACHINESLOCATION_FAILURE,
  UPDATE_MACHINESLOCATION_REQUEST,
  UPDATE_MACHINESLOCATION_SUCCESS,
  SET_MACHINESLOCATION_VALIDATION_ERRORS
} from "../constants/actions.js";
import {
  addMachinesLocation,
  formtValidationErrors,
  removeMachinesLocation,
  updateMachinesLocation,
} from "utils/MachinesLocation";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewFormmachineslocation: false,
  deleting: false,
};

const machineslocation = (state = initState, action) => {
  switch (action.type) {
    case FETCH_MACHINESLOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_MACHINESLOCATION_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_MACHINESLOCATION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_MACHINESLOCATION_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_MACHINESLOCATION_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_MACHINESLOCATION_NEW_FORM:
      return {
        ...state,
        showNewFormmachineslocation: !state.showNewFormmachineslocation,
      };
    case UPDATE_MACHINESLOCATION_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_MACHINESLOCATION_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateMachinesLocation(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_MACHINESLOCATION_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_MACHINESLOCATION_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_MACHINESLOCATION_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeMachinesLocation(state, action),
      };
    case DELETE_MACHINESLOCATION_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_MACHINESLOCATION_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_MACHINESLOCATION_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewFormmachineslocation: !state.showNewFormmachineslocation,
        list: addMachinesLocation(state, action),
      };
    case CREATE_MACHINESLOCATION_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default machineslocation;