import {
  FETCH_FEEDBACKS_FAILURE,
  FETCH_FEEDBACKS_REQUEST,
  FETCH_FEEDBACKS_SUCCESS,
  DELETE_FEEDBACKS_FAILURE,
  DELETE_FEEDBACKS_REQUEST,
  DELETE_FEEDBACKS_SUCCESS,
  CREATE_FEEDBACKS_FAILURE,
  CREATE_FEEDBACKS_REQUEST,
  CREATE_FEEDBACKS_SUCCESS,
  SHOW_FEEDBACKS_EDIT_FORM,
  SHOW_FEEDBACKS_NEW_FORM,
  UPDATE_FEEDBACKS_FAILURE,
  UPDATE_FEEDBACKS_REQUEST,
  UPDATE_FEEDBACKS_SUCCESS,
  SET_FEEDBACKS_VALIDATION_ERRORS
} from "../constants/actions.js";
import {
  addFeedbacks,
  formtValidationErrors,
  removeFeedbacks,
  updateFeedbacks,
} from "utils/Feedbacks";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const feedbacks = (state = initState, action) => {
  switch (action.type) {
    case FETCH_FEEDBACKS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_FEEDBACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_FEEDBACKS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_FEEDBACKS_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_FEEDBACKS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_FEEDBACKS_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_FEEDBACKS_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_FEEDBACKS_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateFeedbacks(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_FEEDBACKS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_FEEDBACKS_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_FEEDBACKS_SUCCESS:
      return {
        ...state,
        deleting: false,
        
        list: removeFeedbacks(state, action),
      };
    case DELETE_FEEDBACKS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_FEEDBACKS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_FEEDBACKS_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        // list: addFeedbacks(state, action),
      };
    case CREATE_FEEDBACKS_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default feedbacks;