import {
    FETCH_MACHINES_FAILURE,
    FETCH_MACHINES_REQUEST,
    FETCH_MACHINES_SUCCESS,
    DELETE_MACHINES_FAILURE,
    DELETE_MACHINES_REQUEST,
    DELETE_MACHINES_SUCCESS,
    CREATE_MACHINES_FAILURE,
    CREATE_MACHINES_REQUEST,
    CREATE_MACHINES_SUCCESS,
    SHOW_MACHINES_EDIT_FORM,
    SHOW_MACHINES_NEW_FORM,
    UPDATE_MACHINES_FAILURE,
    UPDATE_MACHINES_REQUEST,
    UPDATE_MACHINES_SUCCESS,
    SET_MACHINES_VALIDATION_ERRORS,
    FETCH_MACHINESHISTORY_REQUEST,
    FETCH_MACHINESHISTORY_SUCCESS ,
    FETCH_MACHINESHISTORY_FAILURE,
    RESET_INITIAL_STATE
  } from "../constants/actions.js";
  
  export const fetchMachinesRequest = (  
  ) => ({
    type: FETCH_MACHINES_REQUEST
  });
  
  export const fetchMachinesSuccess = (machines) => ({
    type: FETCH_MACHINES_SUCCESS,
    payload: machines,
  });
  
  export const fetchMachinesFailure = (error) => ({
    type: FETCH_MACHINES_FAILURE,
    payload: error,
  });

  export const setMachinesValidationErrors = (validationErrors) => ({
    type: SET_MACHINES_VALIDATION_ERRORS,
    payload: validationErrors,
  });
  
  export const toggleMachinesEditForm = () => ({
    type: SHOW_MACHINES_EDIT_FORM,
  });
  
  export const toggleMachinesNewForm = () => ({
    type: SHOW_MACHINES_NEW_FORM,
  });
  
  export const updateMachinesRequest = (machines) => ({
    type: UPDATE_MACHINES_REQUEST,
    payload: machines,
  });
  
  export const updateMachinesSuccess = (machines) => ({
    type: UPDATE_MACHINES_SUCCESS,
    payload: machines,
  });
  
  export const updateMachinesFailure = (error) => ({
    type: UPDATE_MACHINES_FAILURE,
    payload: error,
  });
  
  export const deleteMachinesRequest = (id) => ({
    type: DELETE_MACHINES_REQUEST,
    payload: id,
  });
  
  export const deleteMachinesSuccess = (machines) => ({
    type: DELETE_MACHINES_SUCCESS,
    payload: machines,
  });
  
  export const deleteMachinesFailure = (error) => ({
    type: DELETE_MACHINES_FAILURE,
    payload: error,
  });
  
  export const createMachinesRequest = (machines) => ({
    type: CREATE_MACHINES_REQUEST,
    payload: machines,
  });
  
  export const createMachinesSuccess = (machines) => ({
    type: CREATE_MACHINES_SUCCESS,
    payload: machines,
  });
  
  export const createMachinesFailure = (error) => ({
    type: CREATE_MACHINES_FAILURE,
    payload: error,
  });


  export const fetchMachinesHistoryRequest = (id) => ({
      type:  FETCH_MACHINESHISTORY_REQUEST,
      payload: id,
    });
    
    export const fetchMachinesHistorySuccess = (machines) => ({
      type:  FETCH_MACHINESHISTORY_SUCCESS ,
      payload: machines,
    });
    
    export const fetchMachinesHistoryFailure = (error) => ({
      type:FETCH_MACHINESHISTORY_FAILURE,
      payload: error,
    });

    export const resetState = () => ({
      type:RESET_INITIAL_STATE
    });

