import {
  FETCH_ITEMS_FAILURE,
  FETCH_ITEMS_REQUEST,
  FETCH_ITEMS_SUCCESS,
  DELETE_ITEMS_FAILURE,
  DELETE_ITEMS_REQUEST,
  DELETE_ITEMS_SUCCESS,
  CREATE_ITEMS_FAILURE,
  CREATE_ITEMS_REQUEST,
  CREATE_ITEMS_SUCCESS,
  SHOW_ITEMS_EDIT_FORM,
  SHOW_ITEMS_NEW_FORM,
  UPDATE_ITEMS_FAILURE,
  UPDATE_ITEMS_REQUEST,
  UPDATE_ITEMS_SUCCESS,
  SET_ITEMS_VALIDATION_ERRORS
} from "../constants/actions.js";
import {
  addItems,
  formtValidationErrors,
  removeItems,
  updateItems,
} from "utils/Items";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
};

const items = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_ITEMS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_ITEMS_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_ITEMS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_ITEMS_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_ITEMS_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_ITEMS_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateItems(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_ITEMS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_ITEMS_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_ITEMS_SUCCESS:
      return {
        ...state,
        deleting: false,
        
        list: removeItems(state, action),
      };
    case DELETE_ITEMS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_ITEMS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_ITEMS_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        list: addItems(state, action),
      };
    case CREATE_ITEMS_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default items;