import {
  FETCH_USERS_FAILURE,
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  DELETE_USERS_FAILURE,
  DELETE_USERS_REQUEST,
  DELETE_USERS_SUCCESS,
  CREATE_USERS_FAILURE,
  CREATE_USERS_REQUEST,
  CREATE_USERS_SUCCESS,
  SHOW_USERS_EDIT_FORM,
  SHOW_USERS_NEW_FORM,
  UPDATE_USERS_FAILURE,
  UPDATE_USERS_REQUEST,
  UPDATE_USERS_SUCCESS,
  SET_USERS_VALIDATION_ERRORS,
  CREATE_USERROLE_SUCCESS,
  CREATE_USERROLE_REQUEST,
  CREATE_USERROLE_FAILURE,
  SHOW_USERROLE_NEW_FORM,
  SET_USERROLE_VALIDATION_ERRORS,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SEND_RESET_EMAIL_REQUEST,
  SEND_RESET_EMAIL_SUCCESS,
  SEND_RESET_EMAIL_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE
} from "../constants/actions.js";
import {
  addUsers,
  formtValidationErrors,
  removeUsers,
  updateUsers,
} from "utils/Users";
const initState = {
  loading: false,
  list: [],
  errorMessage: "",
  updating: false,
  creating: false,
  validationErrors: [],
  showEditForm: false,
  showNewForm: false,
  deleting: false,
  showAssignForm:false,
  regsuccess:false,
  sendemailSuccess:false,
  resetSuccess:false
};

const users = (state = initState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    case SET_USERS_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: formtValidationErrors(action.payload),
      };
    case SHOW_USERS_EDIT_FORM:
      return {
        ...state,
        showEditForm: !state.showEditForm,
      };
    case SHOW_USERS_NEW_FORM:
      return {
        ...state,
        showNewForm: !state.showNewForm,
      };
    case UPDATE_USERS_REQUEST:
      return {
        ...state,
        updating: true,
        errorMessage: "",
        validationErrors: [],
      };
    case UPDATE_USERS_SUCCESS:
      return {
        ...state,
        updating: false,
        list: updateUsers(state, action),
        errorMessage: "",
        validationErrors: [],
        showEditForm: false,
      };
    case UPDATE_USERS_FAILURE:
      return {
        ...state,
        updating: false,
        errorMessage: action.payload,
        validationErrors: [],
      };
    case DELETE_USERS_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case DELETE_USERS_SUCCESS:
      return {
        ...state,
        deleting: false,
        list: removeUsers(state, action),
      };
    case DELETE_USERS_FAILURE:
      return {
        ...state,
        deleting: false,
        errorMessage: action.payload,
      };

    case CREATE_USERS_REQUEST:
      return {
        ...state,
        creating: true,
      };
    case CREATE_USERS_SUCCESS:
      return {
        ...state,
        creating: false,
        showNewForm: !state.showNewForm,
        regsuccess:true
        // list: addUsers(state, action),
      };
    case CREATE_USERS_FAILURE:
      return {
        ...state,
        creating: false,
        errorMessage: action.payload,
      };
      case CREATE_USERROLE_REQUEST:
        return {
          ...state,
          creating: true,
        };
      case CREATE_USERROLE_SUCCESS:
        return {
          ...state,
          creating: false,
          showAssignForm: !state.showAssignForm
        };
      case CREATE_USERROLE_FAILURE:
        return {
          ...state,
          creating: false,
          errorMessage: action.payload,
        };

        case SET_USERROLE_VALIDATION_ERRORS:
          return {
            ...state,
            validationErrors: formtValidationErrors(action.payload),
          };
        case SHOW_USERROLE_NEW_FORM:
          return {
            ...state,
            showAssignForm: !state.showAssignForm,
          };


          case CHANGE_PASSWORD_REQUEST:
            return {
              ...state,
              updating: true,
              errorMessage: "",
              validationErrors: [],
            };
          case CHANGE_PASSWORD_SUCCESS:
            return {
              ...state,
              updating: false,
              errorMessage: "",
              validationErrors: []
            };
          case CHANGE_PASSWORD_FAILURE:
            return {
              ...state,
              updating: false,
              errorMessage: action.payload,
              validationErrors: [],
            };

            case SEND_RESET_EMAIL_REQUEST:
              return {
                ...state,
                creating: true,
                sendemailSuccess:false,
              };
            case SEND_RESET_EMAIL_SUCCESS:
              return {
                ...state,
                creating: false,
                sendemailSuccess:true
              };
            case SEND_RESET_EMAIL_FAILURE:
              return {
                ...state,
                creating: false,
                sendemailSuccess:false,
                errorMessage: action.payload,
              };

              case RESET_PASSWORD_REQUEST:
                return {
                  ...state,
                  creating: true,
                  resetSuccess:false
                };
              case RESET_PASSWORD_SUCCESS:
                return {
                  ...state,
                  creating: false,
                  resetSuccess:true
                };
              case RESET_PASSWORD_FAILURE:
                return {
                  ...state,
                  creating: false,
                  resetSuccess:false,
                  errorMessage: action.payload,
                };
    default:
      return state;
  }
};

export default users;